<template>
  <v-card class="loginForm">
    <v-card-title
      class="text-h3 primary--text"
      style="margin-left: -16px"
    >
      <span v-if="orgName">
        {{ orgName + " " + $t('login.log_in') }}
      </span>
      <span v-else>
        {{ $t('login.log_in') }}
      </span>
    </v-card-title>
    <div
      v-for="item in loginMethods"
      :key="item.provider_name ? `${item.method}_${item.provider_name}` : item.method"
    >
      <div
        v-if="item.method === 'email/passwd'"
        class="section1"
      >
        <v-form
          id="form-login"
          ref="form"
          v-model="valid"
          class="mt-8"
          @submit.prevent="validate()"
        >
          <div class="label text-field-label">
            Email
          </div>
          <v-text-field
            id="email"
            v-model="emailField"
            class="mt-0"
            variant="outlined"
            color="primary"
            density="compact"
            placeholder="ie «johndoe@email.com»"
            :rules="emailRules"
            @keyup.enter="onEnter"
            required
          />
          <div class="label text-field-label">
            {{ $t('forms.password') }}
          </div>
          <v-text-field
            id="password"
            v-model="password"
            class="mt-0"
            placeholder="••••••••"
            variant="outlined"
            color="primary"
            density="compact"
            :rules="passwordRules"
            :type="visible ? 'text' : 'password'"
            :append-icon="visible ? 'fas fa-eye' : 'fas fa-eye-slash'"
            @click:append="() => (visible = !visible)"
            @keyup.enter="onEnter"
            required
          />
        </v-form>
        <div
          v-if="expired"
          class="d-flex warning-box mt-6 px-4 py-2"
        >
          <div>
            <i class="fas fa-info mr-3" />
          </div>
          {{ $t('login.expired_password') }} {{ $t('login.we_will_email') }}
        </div>
        <v-btn
          id="submit-login"
          class="mt-5"
          color="primary"
          type="submit"
          style="padding: 14px !important"
          @click="validate()"
          rounded
          block
        >
          <v-icon
            v-if="loginStarted"
            style="top: -2px; margin-right: 5px;"
            size="16"
            color="white"
          >
            fas fa-spinner fa-pulse
          </v-icon>
          {{ $t('login.log_in') }}
        </v-btn>
        <v-btn
          id="forgot"
          class="mt-3 bottom-gap"
          color="primary"
          variant="text"
          @click="lostPassword()"
        >
          {{ $t('login.forgot_password') }}
        </v-btn>
      </div>
      <div
        v-else
        class="section2"
      >
        <v-btn
          class="mt-2 justify-space-between bottom-gap-sm"
          style="padding: 14px !important"
          color="grey-lighten1"
          :href="`${OpenIdBasePath()}${item.provider_name.toLowerCase()}/redirect${
            orgId ? `?org_id=${orgId}` : ''
          }`"
          block
          rounded
        >
          <v-icon start>
            fab
            {{
              !['google', 'microsoft'].includes(item.provider_name.toLowerCase())
                ? 'fa fa-user-shield'
                : `fa-${item.provider_name.toLowerCase()}`
            }}
          </v-icon>
          {{ $t('login.continue_with') }} {{ item.provider_name }}
        </v-btn>
      </div>
    </div>
    <HelperLogin class="mt-10" />
  </v-card>
</template>

<script>
import { AuthenticationUtils } from "@/utils/AuthenticationUtils";

import HelperLogin from '@/components/common/elements/Login/HelperLogin.vue';
import FormRules from '@/utils/classes/FormRules/FormRules';
import { AuthAPI } from '@/API/authenticator/AuthAPI';
import { UserAPI } from '@/API/authenticator/UserAPI';
import { OrgsAPI } from '@/API/authenticator/OrgsAPI';
import doc_type_mixin from '@/mixins/document_type.js';

export default {
  name: 'LoginForm',

  mixins: [doc_type_mixin],

  components: {
    HelperLogin,
  },

  data: () => ({
    valid: true,
    error: '',
    password: '',
    visible: false,
    emailField: '',
    returnUrl: '/',
    expired: false,
    loginStarted: false,
    orgSlug: null,
    loginMethods: null,
    orgId: null,
    orgName: null,
  }),

  computed: {
    passwordRules() {
      const error = this.$t('forms.password_required');
      return [v => !!v || error]
    },

    emailRules() {
      const required_error = this.$t('forms.email_required');
      const valid_error = this.$t('forms.email_must_valid');
      return [
        v => !!v || required_error,
        v => FormRules.emailLocal(v) || valid_error,
      ]
    }
  },

  async created() {
    this.handleAlreadyLoggedIn();
    const product = this.getProduct();
    const language = this.validateLanguage(navigator.language.substring(0, 2));
    localStorage.setItem('language', language || 'en');
    this.returnUrl = this.$route.query.returnUrl || '/';
    const { org_slug: orgSlug, org_id: orgId } = this.$route.params
    this.orgSlug = orgSlug;
    this.orgId = Number(orgId);
    const orgInfo = this.orgSlug || this.orgId || null;
    try {
      const data = await AuthAPI.getLoginMethods(orgInfo);
      if (Array.isArray(data)) {
        this.$router.push(this.$route.path);
        this.loginMethods = data;
      } else {
        this.loginMethods = data.login_methods;
        this.orgId = data.id;
        this.orgName = data.name;
      }
    } catch (error) {
      console.log(error);
      this.$router.push(this.$route.path);
      AuthAPI.getLoginMethods().then(data => this.loginMethods = data);
    }
    // Currently Search and Classify only work with password login
    if (['search', 'classify'].includes(product)) {
      this.loginMethods = [this.loginMethods[0]];
    }
  },

  methods: {
    async handleAlreadyLoggedIn() {
      const token = AuthenticationUtils.getToken();
      if (token) {
        const user = await this.setLoggedInUser();
        if (user) {
          this.routeProduct(user);
        }
      }
    },

    async setLoggedInUser() {
      try {
        const user = await UserAPI.getCurrentUser();
        if (user.org_id) {
          const org = await OrgsAPI.getOneOrg(user.org_id);
          user.org_name = org.name;
          user.products = org.products;
        }
        this.$store.commit('login', user);
        return user;
      } catch(error) {
        console.log(error);
        return null;
      }
    },

    async loginMethod() {
      this.$store.commit('setLoadingScreen', true);
      this.loginStarted = true;
      try {
        const response = await AuthAPI.login({
            username: this.emailField,
            password: this.password,
        });
        if (response.access_token) {
          localStorage.setItem('token', response.access_token);
          localStorage.setItem('refresh_token', response.refresh_token);
          const user = await this.setLoggedInUser();
          if (user) {
            this.routeProduct(user);
          }
        }
        if (response.user && response.user.status === 'expired') {
          this.expired = true;
        }
      } catch (error) {
        // pass
      } finally {
        this.loginStarted = false;
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async validate() {
      const validated = await this.$refs.form[0].validate();
      if (validated.valid) {
        this.loginMethod();
      }
    },

    onEnter() {
      this.validate();
    },

    lostPassword() {
      this.$router.push({ name: "LostPassword" });
    },

    validateLanguage(language) {
      const acceptedLanguages = ["en", "fr"];
      if (acceptedLanguages.includes(language)) {
        return language
      }
      return
    },

    OpenIdBasePath() {
       return this.$store.getters.config.backends.auth + "auth/api/v1/openid/";
    },

    routeSearch() {
      this.$store.commit('setLoadingScreen', false);
      this.$router.push('/search/home');
    },

    routeClassify() {
      this.$store.commit('setLoadingScreen', false);
      this.$router.push('/classify/home');
    },

    async routeSuite() {
      this.$store.commit('setLoadingScreen', false);
      this.$router.push({ name: 'SuiteHome' });
    },

    getProduct() {
      if (this.$route.path.startsWith('/search')) {
        return 'search';
      } else if (this.$route.path.startsWith('/suite')) {
        return 'suite';
      } else if (this.$route.path.startsWith('/classify')) {
        return 'classify';
      }
      return '';
    },

    async routeProduct(user) {
      const product = this.getProduct();
      if (product === 'classify') {
        this.routeClassify();
      } else if (product === 'search') {
        this.routeSearch();
      } else if (product === 'suite') {
        this.routeSuite(user);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loginForm {
  padding: 30px;

  &__field {
    &:not(:first-child) {
      margin-top: rem(15);
    }
  }

  &__submit {
    margin-top: rem(30);
  }

  &__forgot {
    margin-top: rem(30);
    margin-bottom: 1000px;
  }

  & .warning-box {
    color: rgb(var(--v-theme-primary));
    background-color: rgb(var(--v-theme-primary-lighten2));
    border: 1px solid var(--v-primary--base);
    border-radius: 10px;
  }

  .text-field-label {
    margin-bottom: 3px;
  }
}
</style>
