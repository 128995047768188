<template>
  <div class="notifications py-7 page-padding">
    <PageTabs
      v-model="statusValue"
      class="mt-0"
      :tabs="status"
    />
    <div class="mt-10 pb-11">
      <div
        v-if="Object.keys(filteredNotifications).length === 0"
        style="font-style: italic"
      >
        <span
          v-if="statusValue === 'unseen'"
          class="page-padding"
        >
          {{ $t('notifications.noNew') }}
        </span>
        <span
          v-if="statusValue === 'seen'"
          class="page-padding"
        >
          {{ $t('notifications.noNewSeen') }}
        </span>
      </div>
      <div
        v-for="(day, date) in filteredNotifications"
        :key="date"
        class="notifications__day"
      >
        <p class="text-body-2 font-weight-semi-bold">
          {{ formatDate(date, userLanguage === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY') }}
        </p>
        <div class="mt-5">
          <div
            v-for="notification in day"
            :key="notification.id"
            class="notifications__item"
          >
            <div class="d-flex align-center flex-1">
              <div class="notifications__itemIcon">
                <v-icon :color="getNotificationColor(notification.code)">
                  {{ getNotificationIcon(notification.code) }}
                </v-icon>
              </div>
              <div class="ml-3">
                <span class="text-caption mr-3">
                  {{ formatTime(notification.created_on) }}
                </span>
                <NotificationLink
                  v-if="notification.args.type !== 'inform'"
                  class="mr-3"
                  :notification="notification"
                />
                {{ $t(`notifications.messages.${notification.message}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="paginateDiv" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import NotificationLink from '@/components/common/elements/Notifications/NotificationLink';
import PageTabs from '@/components/common/elements/General/PageTabs';
import { NotificationsAPI } from '@/API/authenticator/NotificationsAPI';
import { getNotificationColor, getNotificationIcon } from "@/utils/NotificationUtils";

export default {
  name: 'NotificationsView',

  components: {
    NotificationLink,
    PageTabs,
  },

  data() {
    return {
      status: [
        { title: this.$t('notifications.unseen'), name: 'unseen' },
        { title: this.$t('notifications.seen'), name: 'seen' },
      ],
      statusValue: 'unseen',
      filteredNotifications: {},
      offset: 0,
      actualPage: 1,
    };
  },

  computed: {
    ...mapState(['notifications']),
    userLanguage() {
      if (this.$store.getters.user) {
        return this.$store.getters.user.language;
      }
      return '';
    },

    notificationsPerPage() {
      const notificationHeight = 52;
      const { body, documentElement: html } = document;
      const height = Math.max(
        body.offsetHeight,
        body.clientHeight,
        html.offsetHeight,
        html.clientHeight
      );

      return Math.floor(height / notificationHeight);
    },

  },

  watch: {
    async statusValue(newStatus, oldStatus) {
      if (newStatus == "seen") {
        this.markNotificationsSeen();
      }
      if (newStatus !== oldStatus) {
        this.actualPage = 1;
        await this.getNotifications();
      }
    },

    async notifications() {
      if (this.statusValue === 'unseen') {
        await this.getNotifications();
      }
    },
  },

  beforeUnmount() {
    this.markNotificationsSeen();
  },

  async mounted() {
    this.$emit('setFullContent', false);
    this.$emit('setFullContainer', false);

    await this.getNotifications();

    const paginate = document.getElementById('paginateDiv');
    const observer = new IntersectionObserver(entries => {
      entries.forEach(async entry => {
        if (entry.intersectionRatio > 0) {
          await this.paginate();
        }
      });
    });
    observer.observe(paginate);
  },

  methods: {
    getNotificationColor,
    getNotificationIcon,
    formatDate(date, format) {
      const today = moment().startOf('day');
      const targetDate = moment(date);
      if (targetDate.isSame(today, 'day')) {
        return this.$t('notifications.today');
      } else if (targetDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
        return this.$t('notifications.yesterday');
      }

      return moment(date)
        .tz(this.$store.getters.loggedInUser.timezone)
        .format(format);
    },

    formatTime(notificationDate) {
      const date = new Date(notificationDate);
      date.setTime(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
      return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    },

    async paginate() {
      if (this.statusValue === 'seen') {
        await this.getNotifications();
      }
    },

    async getNotifications() {
      try {
        this.filteredNotifications = await NotificationsAPI.get(
          "extract",
          this.statusValue !== 'seen',
          true,
          this.notificationsPerPage,
          this.filteredNotifications.length
        );
      } catch (error) {
        return [];
      }
    },

    async markNotificationsSeen() {
      try {
        await NotificationsAPI.markAllNotificationSeen();
        await this.getNotifications();
        this.$store.commit('setNotifications', []);
      } catch (error) {
        // pass
      }
    }
  },

  emits: ['setFullContent', 'setFullContainer'],
};
</script>

<style lang="scss" scoped>
.notifications {
  height: 100%;
  &__day {
    @include desktop {
      padding: 0 36px;
    }

    &:not(:first-child) {
      border-top: 1px solid #e6e8eb;
      margin-top: 24px;
      padding-top: 24px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 0.8rem;
    border-radius: 1.3rem;
    width: 45%;

    &:not(:first-child) {
      margin-top: 20px;
    }

    &Icon {
      color: rgb(var(--v-theme-primary));
    }
  }

  &__right {
    width: rem(32);
    height: rem(32);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    border: solid 1px black;

    .v-icon {
      font-size: rem(12) !important;
    }
  }

  &__percents {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - #{rem(3)});
      left: calc(50% - #{rem(3)});
      width: rem(6);
      height: rem(6);
      background-color: rgb(var(--v-theme-primary));
    }

    ::v-deep {
      svg {
        border-radius: 100%;
        border: 1px solid rgb(var(--v-theme-primary));
      }
    }
    ::v-deep circle:nth-child(1) {
      stroke: none;
    }
  }
}
</style>
