<template>
  <div class="checkbox-ellipsis d-flex align-center">
    <v-icon
      v-if="modelValue"
      color="primary"
      class="mr-2"
      :class="{ clickable: !disabled }"
      :style="{ opacity: disabled ? 0.5 : 1 }"
      @click="handleClick"
    >
      fas fa-check-square
    </v-icon>
    <div
      v-else
      color="grey-lighten-1"
      class="checkbox-empty mr-2"
      :class="{ clickable: !disabled }"
      :style="{ opacity: disabled ? 0.5 : 1 }"
      @click="handleClick"
    />
    <ItemName
      class="noselect"
      style="max-width: 150px"
      :max-width="150"
      :style="{ opacity: disabled ? 0.5 : 1 }"
      :item="{ name: label }"
      :clickable="!disabled"
      :show-id="false"
      :fade-in-slow="false"
      @click="handleClick"
    />
  </div>
</template>

<script>
import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'CheckboxEllipsis',

  components: {
    ItemName,
  },

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('update:modelValue', !this.modelValue);
      }
    }
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: [ 'update:modelValue' ]
}
</script>

<style lang="scss" scoped>
.checkbox-ellipsis {
  .checkbox-empty {
    width: 20px;
    height: 20px;
    border: 2px lightgrey solid;
    border-radius: 4px;
    flex-shrink: 0;
  }
}
</style>
