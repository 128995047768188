import _axios from 'axios';
import { i18n } from '@/plugins/i18n/i18n';
import { store } from '@/plugins/store';
import _ from 'lodash';
import { AuthenticationUtils } from '../utils/AuthenticationUtils';

const locale = i18n.global;

const tmpHttp = _axios.create({
    headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

/**
 * Response interceptors
 */
tmpHttp.interceptors.response.use(
  /**
   * Hide the loader and return the response
   * @param response
   * @return {AxiosResponse<any>}
   */
  function(response) {
    if (
      response.headers['content-type'] === 'text/html' &&
      !response.config.globalErrorHandlingOptOut
    ) {
      // If we get HTML response, we raise error
      return Promise.reject(response);
    }
    return response
  },
  /**
   * Hide the loader and display the error
   * @param error
   * @return {Promise}
   */
  function(error) {
    if (!error.config.globalErrorHandlingOptOut) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.detail &&
        (typeof error.response.data.detail === 'string' || error.response.data.detail instanceof String) &&
        locale.te(`errorMessage.${error.response.data.detail.replace(/\.$/, '')}`)
      ) {
        // If the backend error message is in our translations, we display it
        store.commit("setErrorMessage", locale.t(`errorMessage.${error.response.data.detail.replace(/\.$/, '')}`));
      } else if (error.response && error.response.status && locale.te(`errorStatus.${error.response.status}`)) {
        // If the status code is in our translations, we display it
        store.commit("setErrorMessage", locale.t(`errorStatus.${error.response.status}`));
      }
      // We disply snackbar with default message if no specific was set
      store.commit("setSnackbar", true);
      setTimeout(() => store.commit('setErrorMessage', locale.t('default_error')), 2500);
      return
    }
    return Promise.reject(error);
  }
);

const ADAPTER_PATH = 'adapter/api/v1/';
const AUTH_PATH = 'auth/api/v1/';
const CLASSIFY_PATH = 'classify/api/v1/';
const SEARCH_PATH = 'search/api/v1/';
const WORKFLOWS_PATH = 'workflows/api/v1/';
const AUTH_ROUTES = [
  `${AUTH_PATH}login/`,
  `${AUTH_PATH}refresh-token/`,
  `${AUTH_PATH}revoke/`,
];

/**
 * Request interceptor
 */
tmpHttp.interceptors.request.use(
  async function(config) {
    if (config.url.startsWith(AUTH_PATH)) {
      config.baseURL = store.getters.config.backends.auth;
    } else if (config.url.startsWith(CLASSIFY_PATH)) {
      config.baseURL = store.getters.config.backends.classify;
    } else if (config.url.startsWith(SEARCH_PATH)) {
      config.baseURL = store.getters.config.backends.search;
    } else if (config.url.startsWith(WORKFLOWS_PATH)) {
      config.baseURL = store.getters.config.backends.workflows;
    } else if (config.url.startsWith(ADAPTER_PATH)) {
      config.baseURL = store.getters.config.backends.adapter;
    } else {
      config.baseURL = `${store.getters.config.backends.extract}extract/api/v1/`;
    }

    if (AUTH_ROUTES.includes(config.url)) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    if (
      !_.has(config.params, 'noAuth') ||
      !(_.has(config.params, 'noAuth') && config.params.noAuth === true)
    ) {
      if (config.url !== `${AUTH_PATH}verify/`) {
        const local = true;
        await AuthenticationUtils.refreshTokenIfNeeded(local);
      }
      config.headers.Authorization = `Bearer ${AuthenticationUtils.getToken()}`;
    }
    if (
      (_.has(config.params, 'noAuth') && config.params.noAuth) &&
      (_.has(config.params, 'external') && config.params.external)
    ) {
      config.headers.Authorization = `Bearer ${config.params.token}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

/**
 * Init axios
 * @type {AxiosInstance}
 */
export const http = tmpHttp;
