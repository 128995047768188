<template>
  <div class="workflow-editor">
    <div
      v-if="workflow.status === 'draft'"
      class="button-container"
    >
      <v-btn
        color="primary"
        class="inline-middle right-gap-sm"
        variant="outlined"
        :disabled="nodeOptions.length === 0"
        @click="addNodeDialog = true"
        rounded
      >
        <v-icon
          v-if="nodeOptions.length === 0"
          size="17"
          start
        >
          fas fa-spinner fa-pulse
        </v-icon>
        <v-icon
          v-else
          size="17"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t('workflows.add_node') }}
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!workflow.valid"
        @click="publishWorkflow"
        rounded
      >
        <v-icon
          size="17"
          start
        >
          fas fa-check
        </v-icon>
        {{ $t('workflows.publish') }}
      </v-btn>
    </div>
    
    <div
      v-if="workflow.status === 'active'"
      class="button-container d-flex"
    >
      <v-btn
        v-if="!existingDraft"
        color="primary"
        @click="forkWorkflow"
        rounded
      >
        <v-icon
          size="17"
          start
        >
          fas fa-code-branch
        </v-icon>
        {{ $t('workflows.fork') }}
      </v-btn>
      <v-btn
        v-else
        color="primary"
        @click="editDraft"
        rounded
      >
        <v-icon
          size="17"
          start
        >
          fas fa-pen
        </v-icon>
        {{ $t('workflows.edit_draft') }}
      </v-btn>
      <ButtonWithIcon
        v-if="workflow.status === 'active'"
        class="left-gap-sm"
        :icon="'fas fa-flask'"
        :message="$t('workflows.test.title')"
        :disabled="false"
        :rounded="true"
        @click="testWorkflow = true"
      />
    </div>
    <div
      v-if="errorList && errorList.length > 0"
      class="info-box error-list"
    >
      <small>
        <ul class="bottom-gap-sm top-gap-sm">
          <li
            v-for="error in errorList.slice(0, 10)"
            :key="error"
          >
            {{ $te(`workflows.errors.${error.message}`) ? $t(`workflows.errors.${error.message}`) : error.message }}
            <span v-if="error.object">
              {{ getObjectErrorMessage(error.object) }}
            </span>
          </li>
        </ul>
      </small>
    </div>
    <div
      class="toolbar-container"
      :style="{ right: selected ? '430px' : '10px' }"
    >
      <v-tooltip
        v-if="needsCleanup"
        location="top"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="primary"
            class="small-button clickable right-gap-sm"
            @click="handleLayout"
          >
            <v-icon size="16">
              fas fa-sitemap
            </v-icon>
          </v-btn>
        </template>
        {{ $t('workflows.clean_layout') }}
      </v-tooltip>
      <v-btn
        v-else
        color="primary"
        class="small-button clickable right-gap-sm"
        style="opacity: 0.5"
        @click="handleLayout"
        disabled
      >
        <v-icon size="16">
          fas fa-sitemap
        </v-icon>
      </v-btn>
      <v-tooltip
        v-if="direction === 'UD'"
        location="top"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="primary"
            class="small-button clickable right-gap-sm"
            @click="handleDirectionChange"
          >
            <v-icon size="16">
              fas fa-arrow-right
            </v-icon>
          </v-btn>
        </template>
        {{ $t('workflows.change_layout_direction') }}
      </v-tooltip>
      <v-btn
        v-else
        color="primary"
        class="small-button right-gap-sm"
        style="opacity: 0.5"
        disabled
      >
        <v-icon size="16">
          fas fa-arrow-right
        </v-icon>
      </v-btn>
      <v-tooltip
        v-if="direction === 'LR'"
        location="top"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="primary"
            class="small-button clickable right-gap-sm"
            @click="handleDirectionChange"
          >
            <v-icon size="16">
              fas fa-arrow-down
            </v-icon>
          </v-btn>
        </template>
        {{ $t('workflows.change_layout_direction') }}
      </v-tooltip>
      <v-btn
        v-else
        color="primary"
        class="small-button right-gap-sm"
        style="opacity: 0.5"
        disabled
      >
        <v-icon size="16">
          fas fa-arrow-down
        </v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="small-button clickable right-gap-sm"
        @click="handleZoomIn"
      >
        <v-icon size="16">
          fas fa-search-plus
        </v-icon>
      </v-btn>
      <v-btn
        color="primary"
        class="small-button clickable"
        @click="handleZoomOut"
      >
        <v-icon size="16">
          fas fa-search-minus
        </v-icon>
      </v-btn>
    </div>
    <VueFlow
      ref="vueFlow"
      default-marker-color="rgb(var(--v-theme-primary))"
      :nodes="nodes"
      :edges="edges"
      :delete-key-code="null"
      :zoom-on-scroll="false"
      :zoom-on-pinch="false"
      @node-drag-stop="handleNodeDrag"
      @node-click="handleNodeClick"
      @node-mouse-enter="hovered = $event.node.id"
      @node-mouse-leave="hovered = null"
      @edge-click="handleEdgeClick"
      @connect="handleConnect"
    >
      <template #node-custom="props">
        <CustomNode
          v-bind="props"
          :name="props.data.name || ''"
          :selected="selected && selectedType === 'node' && selected.id === props.id"
          :status="workflow.status"
          :hovered="hovered === props.id"
          :handles="props.data.handles || 'both'"
          :direction="direction"
          :type="props.data.action_type"
          :is-initial="props.data.initial"
          :is-final="props.data.final"
          @delete="handleDeleteNode(props)"
        />
      </template>
      <template #edge-custom="customEdgeProps">
        <CustomEdge
          v-bind="customEdgeProps"
          :selected="selected && selectedType === 'edge' && selected.id === customEdgeProps.id"
        />
      </template>
    </VueFlow>
    <div
      class="config-panel"
      :style="{ width: selected ? '420px' : '0px' }"
    >
      <v-icon
        v-if="selected"
        class="clickable"
        style="position: absolute; right: 10px; top: 10px;"
        size="16px"
        @click="() => handleCloseConfig(selectedType)"
      >
        fas fa-times
      </v-icon>
      <div
        v-if="selected"
        style="margin-top: 30px"
      >
        <div
          v-if="selectedType === 'edge'"
          class="config-inner"
        >
          <h3 class="text-h3">
            Transition
          </h3>
          <div class="label top-gap-sm">
            {{ $t('forms.name') }}
          </div>
          <v-text-field
            v-model="transitionConfig.name"
            style="margin-top: 5px"
            variant="outlined"
            color="primary"
            density="compact"
            :disabled="!isDraft"
            @blur="saveTransitionConfig()"
            @keydown.enter="saveTransitionConfig()"
          />
          <PythonEditor
            ref="transitionPythonEditor"
            dialog-height="400px"
            title="Transition Predicate"
            :code="selected.data.predicate"
            :disabled="!isDraft"
            @save-config="saveTransitionConfig()"
            @update:code="handlePredicateUpdate"
          />
          <v-checkbox
            v-model="selected.data.predicate_type"
            class="radio-box"
            style="width: fit-content"
            color="primary"
            density="compact"
            :label="$t('workflows.transitions.use_code')"
            :disabled="!isDraft"
            :value="'expr'"
            @change="saveTransitionConfig()"
          />
          <v-btn
            v-if="isDraft"
            color="primary"
            class="top-gap-sm"
            @click="deleteTransitionDialog = true"
            rounded
          >
            <v-icon
              class="right-gap-sm"
              size="16"
            >
              fas fa-trash
            </v-icon>
            {{ $t('delete') }}
          </v-btn>
        </div>

        <div
          v-if="selectedType === 'node'"
          class="config-inner"
        >
          <h3 class="text-h3">
            {{ selected.label }}
          </h3>
          <i v-if="selected.data.initial">
            {{ $t(`workflows.steps.${selected.data.action_type}_initial`) }}
          </i>
          <i v-else-if="selected.data.final">
            {{ $t(`workflows.steps.${selected.data.action_type}_final`) }}
          </i>
          <i v-else>
            {{ $t(`workflows.steps.${selected.data.action_type}`) }}
          </i>
          <div class="label top-gap">
            {{ $t('forms.name') }}
          </div>
          <v-text-field
            v-model="stepConfig.name"
            style="margin-top: 5px; margin-bottom: -25px"
            variant="outlined"
            color="primary"
            density="compact"
            :disabled="!isDraft"
            @blur="saveConfig()"
            @keydown.enter="saveConfig()"
          />
          <PythonEditor
            v-if="selected.data.action_type === 'python'"
            ref="stepPythonEditor"
            class="mt-4"
            :code="selected.data.action"
            :disabled="!isDraft"
            :title="$t(`workflows.steps.${selected.data.action_name}`)"
            @save-config="saveConfig()"
            @update:code="code => selected.data.action = code"
          />
          <div v-if="stepConfigSchema && stepConfig.config">
            <div
              v-for="(property, propName) in basicProperties"
              :key="propName"
            >
              <div v-if="property.type === 'string'">
                <div class="label top-gap">
                  {{ $t(`workflows.steps.config.${propName}`) }}
                </div>
                <v-text-field
                  v-if="!property.agent_type"
                  v-model="stepConfig.config[propName]"
                  style="margin-top: 5px; margin-bottom: -25px"
                  variant="outlined"
                  color="primary"
                  density="compact"
                  :disabled="!isDraft"
                  @blur="saveConfig()"
                  @keydown.enter="saveConfig()"
                />
                <template v-else>
                  <CustomSelect
                    v-if="property.agent_type === 'document_classifier'"
                    :items="classifyDocModels"
                    :selected="selectedDocumentModel"
                    :disabled="!isDraft"
                    @selected-changed="handleDocModelSelect"
                  />
                  <CustomSelect
                    v-else-if="property.agent_type === 'email_classifier'"
                    :items="classifyMailModels"
                    :selected="selectedMailModel"
                    :disabled="!isDraft"
                    @selected-changed="handleMailModelSelect"
                  />
                  <DocTypeSelect
                    v-else-if="property.agent_type === 'extraction_agent'"
                    :disabled="!isDraft"
                    :initial="stepConfig.config[propName]"
                    :select-first="false"
                    @selected-changed="handleDocTypeSelect"
                    filter-with-data-points
                    filter-with-groups
                  />
                  <WorkflowSelect
                    v-else-if="property.agent_type === 'workflow'"
                    return-type="uuid"
                    :disabled="!isDraft"
                    :workflows="workflows"
                    :selected="stepConfig.config[propName]"
                    @selected-changed="handleWorkflowSelect"
                    @get-more="(filter, reset) => getMoreWorkflows(filter, reset)"
                  />
                </template>
              </div>
            </div>
            <div style="margin-top: 26px">
              <div
                v-for="(property, propName) in basicProperties"
                :key="propName"
              >
                <div
                  v-if="property.type === 'boolean'"
                  class="radio-box top-gap"
                  style="width: fit-content"
                >
                  <v-checkbox
                    v-model="stepConfig.config[propName]"
                    style="margin-top: -9px"
                    color="primary"
                    :label="$te(`workflows.steps.config.${propName}`) ? $t(`workflows.steps.config.${propName}`) : property.title"
                    :disabled="!isDraft"
                    @change="saveConfig()"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="advancedProperties"
              class="clickable top-gap"
              @click="showAdvanced = !showAdvanced"
            >
              <h1 class="text-h4 text-color--primary inline-middle">
                {{ $t('workflows.steps.config.show_advanced') }}
              </h1>
              <v-icon
                v-if="!showAdvanced"
                color="primary"
              >
                fas fa-angle-right
              </v-icon>
              <v-icon
                v-else
                color="primary"
              >
                fas fa-angle-down
              </v-icon>
            </div>
            <div v-if="showAdvanced">
              <div
                v-for="(property, propName) in advancedProperties"
                :key="propName"
              >
                <div
                  v-if="property.type === 'string'"
                  class="label top-gap-sm"
                >
                  {{ $te(`workflows.steps.config.${propName}`) ? $t(`workflows.steps.config.${propName}`) : property.title }}
                </div>
                <v-text-field
                  v-if="property.type === 'string'"
                  v-model="stepConfig.config[propName]"
                  style="margin-top: 5px; margin-bottom: -25px"
                  variant="outlined"
                  color="primary"
                  density="compact"
                  :disabled="!isDraft"
                  @blur="saveConfig()"
                  @keydown.enter="saveConfig()"
                />
              </div>
              <div style="margin-top: 26px">
                <div
                  v-for="(property, propName) in advancedProperties"
                  :key="propName"
                >
                  <div
                    v-if="property.type === 'boolean'"
                    class="radio-box top-gap"
                    style="width: fit-content"
                  >
                    <v-checkbox
                      v-model="stepConfig.config[propName]"
                      style="margin-top: -9px"
                      color="primary"
                      :label="$te(`workflows.steps.config.${propName}`) ? $t(`workflows.steps.config.${propName}`) : property.title"
                      :disabled="!isDraft"
                      @change="saveConfig()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('workflows.steps.delete')"
      :message="$t('workflows.steps.delete_confirmation')"
      @confirm="deleteStep"
      @close="deleteDialog = false"
    />
    <DeleteDialog
      v-model="deleteTransitionDialog"
      :title="$t('workflows.transitions.delete')"
      :message="$t('workflows.transitions.delete_confirmation')"
      @confirm="deleteTransition"
      @close="deleteTransitionDialog = false"
    />
    <v-dialog
      v-model="customStateDialog"
      max-width="400"
    >
      <v-card class="dialog-card">
        <h2 class="dialog-title mb-8">
          {{ $t('workflows.steps.custom_state') }}
        </h2>
        <div class="label">
          {{ $t('forms.name') }}
        </div>
        <v-text-field
          v-model="customStateName"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          @keydown.enter="addCustomNode"
        />
        <div
          class="radio-box"
          style="width: fit-content"
        >
          <v-checkbox
            v-model="customStateFinal"
            style="margin-top: -9px"
            color="primary"
            :label="$t('final')"
          />
        </div>
        <div class="mt-8 d-flex">
          <div class="dialog-button mr-2">
            <v-btn
              variant="outlined"
              @click="customStateDialog = false"
              block
              rounded
            >
              {{ $t('cancel') }}
            </v-btn>
          </div>
          <div class="dialog-button ml-2">
            <v-btn
              color="primary"
              :disabled="customStateName.trim() === '' || customStateName.trim() === 'custom-name'"
              @click="addCustomNode"
              block
              rounded
            >
              {{ $t('confirm') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <NodeDialog
      v-model="addNodeDialog"
      :node-options="nodeOptions"
      :is-draft="isDraft"
      @add-node="addNode"
    />
    <WorkflowTestDialog
      v-model="testWorkflow"
      :workflow="workflow"
      @close="handleCloseTestDialog"
      @submit="createTestInstance"
    />
  </div>
</template>

<script>
import dagre from '@dagrejs/dagre';
import { ref } from 'vue';
import { VueFlow, Position } from '@vue-flow/core';
import { MarkerType } from '@vue-flow/core';
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import { STATE_OPTIONS, EXTRA_ACTION_OPTIONS, createStepOption} from '@/utils/WorkflowsUtils';
import CustomNode from '@/components/extract/elements/Workflows/CustomNode';
import CustomEdge from '@/components/extract/elements/Workflows/CustomEdge';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import DocTypeSelect from '@/components/extract/elements/DocTypes/DocTypeSelect.vue';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect';
import WorkflowSelect from "@/components/extract/elements/Workflows/WorkflowSelect";
import PythonEditor from '@/components/extract/elements/Workflows/PythonEditor.vue';
import NodeDialog from '@/components/extract/elements/Workflows/NodeDialog.vue';
import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon.vue';
import WorkflowTestDialog from '@/components/extract/views/Workflows/WorkflowTestDialog.vue';

import doctype_mixin from '@/mixins/document_type.js';

const pythonCodeTemplate = `def execute_action(job):
    return StepActionType.done, {
        # Add data here          
    }`;

export default {
  name: 'WorkflowEditor',

  mixins: [
    doctype_mixin,
  ],
  
  components: {
    VueFlow,
    CustomNode,
    CustomEdge,
    DeleteDialog,
    DocTypeSelect,
    CustomSelect,
    PythonEditor,
    WorkflowSelect,
    ButtonWithIcon,
    WorkflowTestDialog,
    NodeDialog,
  },

  data() {
    return({
      addNodeDialog: false,
      errorList: [],
      existingDraft: null,
      needsCleanup: true,
      deleteDialog: false,
      deleteTransitionDialog: false,
      customStateDialog: false,
      customStateName: '',
      customStateFinal: false,
      selectedType: '',
      selected: null,
      stepConfig: null,
      stepConfigSchema: null,
      transitionConfig: null,
      selectedNode: null,
      nodeOptions: [],
      nodes: [],
      edges: [],
      steps: [],
      transitions: [],
      direction: 'LR',
      codeDialog: false,
      showAdvanced: false,
      testWorkflow: false,
      classifyDocModels: [],
      classifyMailModels: [],
      extractionAgents: [],
      totalExtractionAgents: 0,
      gettingExtractionAgents: false,
      workflows: [],
      hovered: null,
      files: [
        {type: 'Email', file: null}
      ],
    });
  },

  computed: {
    orgName() {
      return this.$store.getters.loggedInUser.org_name;
    },

    basicProperties() {
      if (!this.stepConfigSchema) {
        return null;
      }
      return Object.keys(this.stepConfigSchema.properties)
        .filter(p => !this.stepConfigSchema.properties[p].advanced)
        .reduce((obj, key) => {
          obj[key] = this.stepConfigSchema.properties[key];
          return obj;
        }, {});
    },

    advancedProperties() {
      if (!this.stepConfigSchema) {
        return null;
      }
      return Object.keys(this.stepConfigSchema.properties)
        .filter(p => this.stepConfigSchema.properties[p].advanced)
        .reduce((obj, key) => {
          obj[key] = this.stepConfigSchema.properties[key];
          return obj;
        }, {});
    },

    isDraft() {
      return this.workflow.status === 'draft';
    },

    selectedDocumentModel() {
      const model = this.classifyDocModels.find(m => m.name === this.stepConfig.config.model);
      return model ? model.id : 0;
    },

    selectedMailModel() {
      const model = this.classifyMailModels.find(m => m.name === this.stepConfig.config.model);
      return model ? model.id : 0;
    },

    selectedWorkflow() {
      const model = this.workflows.find(m => m.uuid === this.stepConfig.config.workflow);
      return model ? model.name : '';
    },
  },

  watch: {
    stepConfigSchema(schema) {
      this.showAdvanced = false;
      if (schema && schema.properties.model) {
        if (
          ['document_classifier', 'email_classifier'].includes(schema.properties.model.agent_type)
          && (
            this.classifyDocModels.length === 0 || this.classifyMailModels.length === 0
          )
        ) {
          this.getClassifiers();
        }
      }
    },
  },

  created() {
    this.getStepOptions();
  },

  mounted() {
    this.$store.commit('setMenu', false);
    if (this.workflow.status === 'draft') {
      this.checkIfValid();
    } else {
      this.checkIfDraftExists();
    }
    this.initGraph();
    this.handleKeydown();
    this.getMoreWorkflows();
  },

  methods: {
    shortPredicate(predicate) {
      const noPlaceholder = predicate && predicate.replace(
        this.$t('workflows.transitions.code_placeholder'), ''
      ).trim() || '';
      return `${noPlaceholder.slice(0, 20)}${noPlaceholder.length > 20 ? '...' : ''}` || '';
    },

    addCustomNode() {
      if (this.customStateName.trim() !== '' && this.customStateName.trim() !== 'custom-name') {
        this.addNode(
          {
            action: this.customStateName.trim(),
            action_type: 'state',
            final: this.customStateFinal,
          }
        );
      }
    },
    async initGraph() {
      this.steps = await this.getSteps();
      this.displaySteps();
      this.transitions = await this.getTransitions();
      this.displayEdges();
      this.handleLayout();
    },

    editDraft() {
      this.$router.push({
        name: 'ConfigureWorkflow',
        params: { id: this.existingDraft },
      });
    },

    getObjectErrorMessage(object) {
      if (object.type === 'steps') {
        const node = this.nodes.find(s => s.id === String(object.id));
        if (node) {
          return `(Step: ${node.data.real_name})`;
        }
      }
      if (object.type === 'transitions') {
        const edge = this.edges.find(e => e.id === `e${object.id}`);
        if (edge) {
          return `(Transition: ${edge.data.name})`;
        }
      }
      return '';
    },

    handleNodeDrag(evt) {
      this.needsCleanup = true;
      const node = this.nodes.find(n => n.id === evt.node.id);
      if (node) {
        node.position = evt.node.position;
      }
    },

    handleKeydown() {
      document.addEventListener("keydown", event => {
        if (['INPUT', 'TEXTAREA'].includes(document.activeElement.nodeName)) {
          return;
        }
        switch (event.key) {
          case "Delete":
            if (this.selectedType === 'node' && !this.selected.data.initial) {
              this.deleteDialog = true;
            } else if (this.selectedType === 'edge') {
              this.deleteTransitionDialog = true;
            }
            break;
        }
      });
    },

    createConfigObject(configJson) {
      const properties = configJson.properties;
      const outputObject = {};
      for (let prop in properties) {
        if (Object.prototype.hasOwnProperty.call(properties, prop)) {
          const defaultValue = properties[prop].default;
          switch (properties[prop].type) {
            case 'string':
              outputObject[prop] = defaultValue || '';
              break;
            case 'boolean':
              outputObject[prop] = defaultValue || false;
              break;
            default:
              outputObject[prop] = defaultValue || null;
          }
        }
      }
      return outputObject;
    },

    getConfigSchema(action, action_type) {
      const node = this.nodeOptions.find(
        (n) => {
          if (n.value.action_type != action_type) {
            return false;
          }

          if (action_type == 'bindings') {
            if (n.value.action != action) {
              return false;
            }
          }

          return true;
        }
      );

      if (node && node.config && node.config.json_schema) {
        return node.config.json_schema;
      }

      return null;
    },

    async forkWorkflow() {
      try {
        const forked = await WorkflowAPI.forkWorkflow(this.workflow.id);
        this.$store.commit(
          'setSuccessMessage',
          this.$t('workflows.forked_success')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$router.push({
          name: 'ConfigureWorkflow',
          params: { id: forked.id },
        });
      } catch (error) {
        // pass
      }
    },

    async checkIfDraftExists() {
      try {
        const workflows = await WorkflowAPI.getByUUID(this.workflow.uuid);
        this.existingDraft = workflows[0].draft_id;
      } catch (error) {
        console.log(error);
      }
    },

    async publishWorkflow() {
      try {
        await WorkflowAPI.publishWorkflow(this.workflow.id);
        await WorkflowAPI.activateWorkflow(this.workflow.id);
        this.checkIfDraftExists();
        this.workflow.status = 'active';
        this.$store.commit(
          'setSuccessMessage',
          this.$t('workflows.published_success')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        // pass
      }
    },

    handleDirectionChange() {
      this.direction = this.direction === 'UD' ? 'LR' : 'UD';
      this.handleLayout();
    },

    handleLayout() {
      const graph = ref(new dagre.graphlib.Graph());
      const dagreGraph = new dagre.graphlib.Graph();
      graph.value = dagreGraph;
      dagreGraph.setDefaultEdgeLabel(() => ({}));
      const isHorizontal = this.direction === 'LR';
      dagreGraph.setGraph({
        rankdir: this.direction,
        nodesep: 100,
        ranksep: 100,
      });
      for (const node of this.nodes) {
        const graphNode = this.$refs.vueFlow.findNode(node.id);
        dagreGraph.setNode(
          node.id,
          {
            width: graphNode.dimensions.width,
            height: graphNode.dimensions.height,
          }
        )
      }
      for (const edge of this.edges) {
        dagreGraph.setEdge(edge.source, edge.target);
      }
      dagre.layout(dagreGraph);
      this.nodes = this.nodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        return {
            ...node,
            targetPosition: isHorizontal ? Position.Left : Position.Top,
            sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
            position: {
              x: nodeWithPosition.x - nodeWithPosition.width / 2 + 30,
              y: nodeWithPosition.y - nodeWithPosition.height / 2 + 30,
            },

          }
        }
      );
      this.needsCleanup = false;
    },

    handleZoomOut() {
      this.$refs.vueFlow.zoomOut();
    },

    handleZoomIn() {
      this.$refs.vueFlow.zoomIn();
    },

    async handleConnect(evt) {
      if (this.workflow.status === 'draft') {
        const sourceNode = this.nodes.find(node => node.id === evt.source);
        const targetNode = this.nodes.find(node => node.id === evt.target);
        const transition = await this.createTransition(
          parseInt(evt.source),
          parseInt(evt.target),
          sourceNode.data.real_name,
          targetNode.data.real_name,
        );
        if (transition) {
          const edge = this.createEdge(transition);
          this.edges.push(edge);
          this.needsCleanup = true;
          this.checkIfValid();
        }
      }
    },

    async checkIfValid() {
      try {
        this.errorList = await WorkflowAPI.checkIfValid(this.workflow.id);
        this.workflow.valid = this.errorList.length === 0;
      } catch (error) {
        console.log(error);
      }
    },

    async createTransition(source, target, sourceName, targetName) {
      try {
        return await WorkflowAPI.createTransition(this.workflow.id, {
          step_to_id: target,
          step_from_id: source,
          name: `${sourceName}-${targetName}`,
        });
      } catch (error) {
        console.log(error);
      }
    },

    handlePredicateUpdate(code) {
      this.selected.data.predicate = code;
      if (!this.selected.data.predicate_type) {
        this.selected.data.predicate_type = 'expr';
      }
      if (!code) {
        this.selected.data.predicate_type = null;
      }
    },

    async handleCloseConfig(type = 'node') {
      switch (type) {
        case 'node':
          await this.saveConfig();
          break;
        case 'edge':
          await this.saveTransitionConfig();
          break;
        default:
          break;
      }
      this.selected = null;
    },

    async saveConfig(showSuccess = false) {
      if (!this.selected) {
        return;
      }
      const data = {};
      if (this.stepConfig.name.trim() != '') {
        data.name = this.stepConfig.name.trim();
        data.config = this.stepConfig.config;

        if (this.selected.data.action_type === 'python') {
          // remove trainling spaces from python code
          this.selected.data.action = this.selected.data.action.replaceAll(/\s+$/gm, '');
          data.action = this.selected.data.action;
          this.$refs.stepPythonEditor.highlightCode();
        }
      }
      await this.updateStep(parseInt(this.selected.id, 10), data, showSuccess);
    },

    async saveTransitionConfig(showSuccess = false) {
      const data = {};
      if (this.transitionConfig.name) {
        data.name = this.transitionConfig.name.trim();
      } else {
        data.name = null;
      }
      data.predicate_type = this.selected.data.predicate_type || null;
      data.predicate = this.selected.data.predicate;
      await this.updateTransition(parseInt(this.selected.id.slice(1), 10), data, showSuccess);
    },

    findHighestNumber(strings, action) {
      let highestNumber = 0;
      for (const str of strings) {
        if (str.startsWith(action)) {
          const numberStr = str.slice(action.length);
          const number = parseInt(numberStr, 10);
          if (!isNaN(number) && number > highestNumber) {
              highestNumber = number;
          }
        }
      }
      return highestNumber;
    },

    handleDocModelSelect(id) {
      const model = this.classifyDocModels.find(m => m.id === id);
      if (model) {
        this.stepConfig.config['model'] = model.name;
        this.saveConfig();
      }
    },

    handleMailModelSelect(id) {
      const model = this.classifyMailModels.find(m => m.id === id);
      if (model) {
        this.stepConfig.config['model'] = model.name;
        this.saveConfig();
      }
    },

    handleDocTypeSelect(id) {
      if (this.stepConfig.config['doctype'] !== id) {
        this.stepConfig.config['doctype'] = id;
        this.saveConfig();
      }
    },

    async addNode(value) {
      if (value.action === 'custom-state') {
        this.customStateDialog = true;
        this.selectedNode = null;
        return
      }
      const isPythonAction = value.action_type === 'python';
      const steps = await this.getSteps(!isPythonAction && value.action || null, isPythonAction);
      let name = value.action_type === 'python' ? 'code' : value.action;
      if (value.name) {
        name = value.name;
      }
      const autoNameNumber = this.findHighestNumber(
        steps.map(step => step.name), name,
      ) + 1;
      const configSchema = this.getConfigSchema(
        value.action,
        value.action_type,
      );
      let config = null;
      if (configSchema) {
        config = this.createConfigObject(configSchema);
      }
      if (value.config) {
        Object.keys(value.config).forEach(setting => {
          config[setting] = value.config[setting];
        });
      }
      let final = false;
      if (
        value.final ||
        (value.action === 'done' && value.action_type === 'state')
      ) {
        final = true;
      }
      name = `${name}${autoNameNumber}`
      const step = await this.createStep({
        action: value.action,
        config,
        final,
        action_type: value.action_type,
        action_name: value.action_name,
        name,
      });
      this.checkIfValid();
      const node = this.createNode(step);
      this.nodes.push(node);
      this.selectedNode = null;
      this.customStateDialog = false;
      this.customStateName = '';
      this.customStateFinal = false;
      this.needsCleanup = true;
      if (this.addNodeDialog) {
        this.addNodeDialog = false;
      }
    },

    createEdge(transition) {
      return {
        id: `e${transition.id}`,
        type: 'default',
        source: String(transition.step_from_id),
        target: String(transition.step_to_id),
        label: String(transition.name),
        animated: false,
        curvature: 10,
        markerEnd: MarkerType.ArrowClosed,
        data: {
          name: transition.name,
          predicate: transition.predicate || '',
          predicate_type: transition.predicate_type,
          hover: false,
        }
      }
    },

    createNode(step) {
      let handles = 'both';
      if (step.initial) {
        handles = 'right';
      } else if (step.final) {
        handles = 'left';
      }
      return {
        id: String(step.id),
        type: 'custom',
        label: this.getDisplayLabel(step.action_type === 'python' && step.action_name || step.action),
        data: {
          handles,
          name: this.getDisplayName(step.action, step.name),
          real_name: step.name,
          action_name: step.action_name,
          action_type: step.action_type,
          action: step.action,
          config: step.config,
          final: step.final,
          initial: step.initial,
        },
        position: {
          x: 10,
          y: 10,
        },
      };
    },

    removeNode(id) {
      const index = this.nodes.findIndex(node => node.id === id);
      this.nodes.splice(index, 1);
    },

    removeEdge(id) {
      const index = this.edges.findIndex(edge => edge.id === id);
      this.edges.splice(index, 1);
    },

    getDisplayLabel(action) {
      if (this.$te(`workflows.steps.${action}`)) {
        return this.$t(`workflows.steps.${action}`)
      }
      return action;
    },

    getDisplayName(action, name) {
      if (
        action === 'started' && name === 'start' ||
        action === 'error' && name === 'error' ||
        action === 'done' && name === 'done'
      ) {
        return '';
      }
      return name;
    },

    async deleteTransition() {
      try {
        const id = parseInt(this.selected.id.slice(1), 10);
        await WorkflowAPI.deleteTransition(this.workflow.id, id);
        this.checkIfValid();
        this.removeEdge(this.selected.id);
        this.needsCleanup = true;
        this.selected = null;
        this.selectedType = '';
        this.deleteTransitionDialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    handleDeleteNode(node) {
      this.selectedType = 'node';
      this.stepConfigSchema = this.getConfigSchema(
        node.data.action,
        node.data.action_type,
      );
      let config = node.data.config;
      if (!config &&
        this.stepConfigSchema &&
        node.data.action_type !== 'state') {
        config = this.createConfigObject(this.stepConfigSchema);
      }
      this.stepConfig = {
        name: node.data.real_name,
        config,
      }
      this.selected = node;
      this.deleteDialog = true;
    },

    async deleteStep() {
      try {
        await WorkflowAPI.deleteStep(this.workflow.id, this.selected.id);
        this.checkIfValid();
        this.removeNode(this.selected.id);
        this.needsCleanup = true;
        this.selected = null;
        this.selectedType = '';
        this.deleteDialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    async createStep(data) {
      try {
        return await WorkflowAPI.createStep(this.workflow.id, data);
      } catch (error) {
        console.log(error);
      }
    },

    getRandom(to, from) {
      return Math.floor(Math.random() * (to - to / 100 + from));
    },

    async displayEdges() {
      const edges = [];
      this.transitions.forEach(transition => {
        const edge = this.createEdge(transition);
        edges.push(edge);
      });
      this.edges = edges;
    },

    displaySteps() {
      const nodes = [];
      this.steps.forEach(step => {
        const node = this.createNode(step);
        nodes.push(node);
      });
      this.nodes = nodes;
    },

    async updateTransition(id, data, showSnackbar=false) {
      try {
        this.steps = await WorkflowAPI.patchTransition(
          this.workflow.id, id, data,
        );
        this.checkIfValid();
        this.transitions = await this.getTransitions();
        this.displayEdges();
        if (showSnackbar) {
          this.$store.commit(
            'setSuccessMessage',
            this.$t('workflows.transitions.updated')
          );
          this.$store.commit('setSuccessSnackbar', true);
        }
      } catch (error) {
        if (showSnackbar) {
          this.$store.commit('setSnackbar', true);
        }
      }
    },

    async updateStep(stepId, data, showSnackbar=false) {
      try {
        await WorkflowAPI.patchStep(this.workflow.id, stepId, data);
        this.checkIfValid();
        this.steps = await this.getSteps();
        this.displaySteps();
        this.handleLayout();
        if (showSnackbar) {
          this.$store.commit(
            'setSuccessMessage',
            this.$t('workflows.steps.updated')
          );
          this.$store.commit('setSuccessSnackbar', true);
        }
      } catch (error) {
        if (showSnackbar) {
          this.$store.commit('setSnackbar', true);
        }
      }
    },

    async getTransitions() {
      try {
        return await WorkflowAPI.getTransitions(this.workflow.id);
      } catch (error) {
        // pass
      }
    },

    addDynamicClassifyOptions(models, nodeOptions, value, action) {
      models.forEach(model => {
        const newValue = {...value};
        const name = `Classify ${model.name}`;
        newValue.config = { model: model.name };
        newValue.name = name;
        nodeOptions.push(
          createStepOption(name, action, newValue, this.orgName)
        );
      });
    },

    async getActions() {
      try {
        return await WorkflowAPI.getActions();
      } catch (error) {
        // pass
      } 
    },

    async getStepOptions() {
      const [classifiers, actions] = await Promise.all([
          this.getClassifiers(),
          this.getActions(),
      ]);
      let actionOptions = [];
      actions.forEach(action => {
        if (
          action.action_type !== 'state' &&
          (!action.action || !action.action.startsWith('dummy'))
        ) {
          let name = this.$te(`workflows.steps.${action.action}`) ? this.$t(`workflows.steps.${action.action}`) : action.action;
          let value = {
            action: action.action,
            action_type: action.action_type,
          }
          if (!value.action && action.action_type === 'python') {
            value = {
              action_type: 'python',
              action: pythonCodeTemplate,
              action_name: 'custom-code',
            };
            name = this.$t(`workflows.steps.${value.action_name}`);
          }
          actionOptions.push(createStepOption(name, action, value));
          if (classifiers && value.action === 'classify-doc') {
            this.addDynamicClassifyOptions(
              classifiers[0], actionOptions, value, action,
            );
          } else if (classifiers && value.action === 'classify-email') {
            this.addDynamicClassifyOptions(
              classifiers[1], actionOptions, value, action,
            );
          }
        }
      });
      this.nodeOptions = [
        ...actionOptions,
        ...EXTRA_ACTION_OPTIONS,
        ...STATE_OPTIONS,
      ];
    },

    async getSteps(action = null, python = false) {
      try {
        return await WorkflowAPI.getSteps(this.workflow.id, action, python);
      } catch (error) {
        // pass
      }
    },

    handleNodeClick(evt) {
      this.selectedType = 'node';
      this.stepConfigSchema = this.getConfigSchema(
        evt.node.data.action,
        evt.node.data.action_type,
      );
      let config = evt.node.data.config;
      if (!config &&
        this.stepConfigSchema &&
        evt.node.data.action_type !== 'state') {
        config = this.createConfigObject(this.stepConfigSchema);
      }
      this.stepConfig = {
        name: evt.node.data.real_name,
        config,
      }
      this.selected = evt.node;
    },

    handleEdgeClick(evt) {
      this.selectedType = 'edge';
      this.transitionConfig = {
        name: evt.edge.data.name,
        predicate_type: evt.edge.data.predicate_type,
      }
      this.selected = evt.edge;
    },

    async getClassifiers() {
      try {
        const response = await ClassifyModelAPI.getAllModels();
        this.classifyDocModels = response.data.filter(c => c.model_type === 'document_models' && c.trained != null);
        this.classifyMailModels = response.data.filter(c => c.model_type === 'email_models' && c.trained != null);
        return [this.classifyDocModels, this.classifyMailModels]
      } catch (error) {
        // pass
      }
    },

    handleWorkflowSelect(uuid) {
      this.stepConfig.config['workflow'] = uuid;
    },

    async getMoreWorkflows(name) {
      this.workflows = await WorkflowAPI.getByUUID(null, name);
    },
    
    handleCloseTestDialog(){
      this.workflow.data = null;
      this.workflow.custom_metadata = null;
      this.files = [{
        type: 'Email',
        file: null,
      }],
      this.testWorkflow = false; 
    },

    async createTestInstance(dialogForm) {
      const formData = new FormData();

      const jobQueryParams = {
        start: true,
        is_test: true
      };

      if (dialogForm.custom_metadata){
        jobQueryParams.custom_metadata = dialogForm.custom_metadata;
      }

      if (dialogForm.uuid) {
        jobQueryParams.workflow_uuid = dialogForm.uuid;
      } else {
        jobQueryParams.workflow_id = dialogForm.id;
      }
      if (dialogForm.data){
        try {
          const initialDataBlob = new Blob([dialogForm.data], {
            type: 'application/json'
          });
          formData.append('data', initialDataBlob, 'data.json');
        } catch (error) {
          this.$store.commit('setSnackbar', true);
        }
      }
      dialogForm.files.forEach(fileObj => {
        if (fileObj.type && fileObj.file) {
          formData.append(fileObj.type, fileObj.file);
        }
      });

      try {
        const job = await WorkflowAPI.createJob(formData, jobQueryParams);
        this.$router.push(
          {
            name: 'JobHistory',
            params: {
              id: job.id,
            }
          }
        )
      } catch (error) {
        // pass
      }
    },
  },

  props: {
    workflow: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';

.workflow-editor {
  width: 100%;
  height: 77vh;
  background-color: rgb(var(--v-theme-grey-lighten2));
  border: 1px solid rgb(var(--v-theme-grey-darken2));
  border-radius: 4px;
  position: relative;
}

.config-panel {
  background-color: rgb(var(--v-theme-grey-darken2));
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 300px;
  z-index: 10;
}


.dialog-button {
  width: 100%;
}

.button-container {
  position: absolute;
  right: 0px;
  top: -50px;
}

.toolbar-container {
  position: absolute;
  bottom: 10px;
  z-index: 10;
}

.error-list {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9;
  width: 300px;
}

.config-inner {
  height: calc(77vh - 110px);
  overflow: auto;
  padding: 30px;
  padding-top: 0px;
  box-sizing: content-box;

  .v-text-field, .model-select, .doctype-select,
  .v-input.v-input--horizontal.v-input--center-affix.v-input--density-compact.v-locale--is-ltr.v-input--dirty.v-checkbox {
    box-sizing: border-box !important;
  }
}

.info-box {
  background-color: rgb(var(--v-theme-primary-lighten2));
  border-radius: 6px;
  padding-top: 7px;
  padding-left: 30px;
  padding-right: 17px;
  padding-bottom: 7px;
  width: 300px;

  .info-icon {
    margin-right: 2px;
    top: -1px;
  }
}
</style>
