<template>
  <v-app>
    <v-main>
      <router-view
        :key="$route.fullPath"
        class="router-view"
        :class="{
          'open-router': showSidebar && !menuOpen,
          'closed-router': showSidebar && menuOpen,
          'full-router': !showSidebar,
        }"
      />
      <div
        v-if="showSidebar"
        class="sidebar-container"
        :style="{width: menuOpen ? '210px' : '60px'}"
      >
        <div
          v-if="loggedInUser"
          class="fade-in stretch"
          style="right: unset"
        >
          <Sidebar
            :key="`${$route.name}sidebar`"
            class="sidebarView__sidebar sidebarView__sidebar--fullContainer"
            :class="{ 'sidebarView__sidebar--open': menuOpen }"
            :open="menuOpen"
            :product="product"
            @open-menu="$store.commit('setMenu', true)"
          />
        </div>
        <div
          class="sidebar-button clickable"
          @click="$store.commit('setMenu', !menuOpen)"
        >
          <v-icon
            v-if="menuOpen"
            style="margin-top: -10%; margin-left: -2px"
            size="16"
          >
            fas fa-chevron-left
          </v-icon>
          <v-icon
            v-else
            style="margin-top: -10%; margin-left: 0px"
            size="16"
          >
            fas fa-chevron-right
          </v-icon>
        </div>
      </div>

      <div
        v-if="showNotifications"
        class="notifications-container position-absolute d-flex flex-column"
      >
        <div
          v-for="(notification, index) in newNotifications"
          :key="index"
          class="notification-item position-relative mt-3"
        >
          <v-snackbar
            v-model="showNotifications"
            activator="parent"
            target="parent"
            class="w-100 d-flex align-items-center"
            transition="slide-y-transition"
            location="top"
            max-height="90"
            :timeout="notificationToastTimeout"
            :color="getNotificationColor(notification.code)"
            :attach="true"
            :contained="true"
            multi-line
          >
            <div class="d-flex align-items-center">
              <v-icon>
                {{ getNotificationIcon(notification.code) }}
              </v-icon>
              <div class="ml-3">
                <NotificationLink
                  v-if="notification.args.type !== 'inform'"
                  class="mr-1"
                  :notification="notification"
                />
                {{ $t(`notifications.messages.${notification.message}`) }}
              </div>
            </div>
            <template #actions>
              <v-btn
                color="white"
                @click="closeNotificationToast(index)"
                text
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </div>

      <v-snackbar
        v-model="$store.state.snackbar"
        color="#F11A34"
        :timeout="toastTimeout"
      >
        {{ errorText }}
        <template #action="{ attrs }">
          <v-btn
            color="blue"
            v-bind="attrs"
            @click="$store.commit('setSnackbar', false)"
            text
          >
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="$store.state.successSnackbar"
        color="green"
        :timeout="toastTimeout"
      >
        {{ $store.getters.getSuccessMessage }}
        <template #action="{ attrs }">
          <v-btn
            color="blue"
            v-bind="attrs"
            @click="$store.commit('setSuccessSnackbar', false)"
            text
          >
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <div
        v-if="$store.getters.loadingScreen"
        class="loading-screen"
      />
    </v-main>
  </v-app>
</template>

<script>
import { NotificationsAPI } from '@/API/authenticator/NotificationsAPI';
import NotificationLink from '@/components/common/elements/Notifications/NotificationLink';
import { getNotificationColor, getNotificationIcon } from "@/utils/NotificationUtils";

import Sidebar from '@/components/common/elements/Navigation/SideBar';

export default {
  name: 'App',

  components: {
    Sidebar,
    NotificationLink,
  },

  data: () => ({
    showNotifications: false,
    lastNotificationHeights: [],
    product: '',
    pollTimer: null,
    newNotifications: [],
  }),

  computed: {    
    notifications() {
      return this.$store.getters.notifications;
    },

    errorText() {
      return this.$store.getters.errorMessage;
    },

    menuOpen() {
      return this.$store.getters.menuOpen;
    },

    showSidebar() {
      return this.$route.meta.menu;
    },

    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },

    isExternalUser() {
      return !!this.$route.params.token;
    },
  },

  watch: {
    newNotifications(newNotifications) {
      this.showNotifications = newNotifications.length > 0;
    },

    product(product) {
      if (['search', 'suite', 'classify'].includes(product)) {
        // We switched products
        this.$store.commit('setProduct', product);
        this.stopPolling();
        this.$store.commit('setNotifications', []);
        this.checkNotifications();
      }
    },
  
    loggedInUser(loggedInUser) {
      if (loggedInUser) {
        this.getProduct();
        this.checkNotifications();
      } else {
        // When we log out we stop polling, and reset notifications
        this.stopPolling();
        this.$store.commit('setNotifications', []);
      }
    }
  },

  created() {
    this.toastTimeout = 2000;
    this.notificationToastTimeout = 20000;
    this.productTimeout = 100;
  },

  methods: {
    getNotificationColor,
    getNotificationIcon,
    async checkNotifications() {
      // We need to change this if we want notifications for other products
      if (this.product === 'suite' && !this.isExternalUser && this.loggedInUser.role !== 'basic') {
        // We get existing notifications, but we don't show toast
        await this.getNotifications(false);
        // We show toast for incoming new notifications
        this.pollNotifications();
      }
    },

    getProduct() {
      if (this.$route.path.startsWith('/suite')) {
        this.product = 'suite';
      } else if (this.$route.path.startsWith('/search')) {
        this.product = 'search';
      } else if (this.$route.path.startsWith('/classify')) {
        this.product = 'classify';
      } else {
        // If we can't get the product from route, we try again.
        setTimeout(() => {
          this.getProduct();
        }, this.productTimeout);
      }
    },

    closeNotificationToast(index) {
      this.newNotifications.splice(index, 1);
    },

    async getNotifications(showToast = true) {
      try {
        let service = this.product === 'suite' ? 'extract' : this.product;
        const notifications = await NotificationsAPI.get(service);
        if (showToast) {
          this.newNotifications = notifications.filter((notification) => {
            return !this.notifications.find((n) => n.id === notification.id);
          });
        }
        this.$store.commit('setNotifications', notifications);
        return true;
      } catch (error) {
        console.log('Error fetching notifications:', error);
        return false;
      }
    },

    stopPolling() {
      clearInterval(this.pollTimer);
      this.pollTimer = null;
    },

    async pollNotifications() {
      let tryCount = 0;
      const pollInterval = 10000;
      const maxTries = 10;

      const checkStopCondition = () => {
        if (tryCount >= maxTries) {
          this.stopPolling();
        }
      };

      if (!this.pollTimer) {
        this.pollTimer = setInterval(async () => {
          const success = await this.getNotifications();
          tryCount = success ? 0 : tryCount + 1;
          checkStopCondition();
        }, pollInterval);
      }
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/main';

html {
  font-size: 1rem;
  overflow: auto;
  min-height: 100%;
}

body {
  font-family: 'Inter', serif;
  min-height: 100%;

  .v-application {
    font-family: 'Inter', serif;
  }
}

h1 {
  color: rgb(var(--v-theme-primary))
}

.label {
  font-weight: bold;
  font-size: 0.9rem;
}

.notifications-container {
    top: 0px;
    right: 40px;
    width: 300px;
    height: 100%;
}

.notification-item {
    width: 100%;
    height: 90px;
}

.table-row {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  position: relative;

  &:not(&:last-of-type) {
    border-bottom: 1px solid #eee;
  }

  &__header {
    border-bottom: 1px solid rgb(var(--v-theme-primary));
  }

  .v-col {
    height: 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
  }
}

.table-row-height {
  height: 55px;
}

.table-row-fit-height {
  height: fit-content !important;
}

.bg-primary {
  opacity: 1 !important;
}

.v-breadcrumbs li:first-of-type {
  margin-left: 0px !important;
}

.v-breadcrumbs div {
  color: rgb(var(--v-theme-dark)) !important;
}

.router-view {
  height: fit-content;
  transition: width 300ms, margin-left 300ms;
}

.open-router {
  width: calc(100vw - 60px);
  margin-left: 60px !important;
}

.closed-router {
  width: calc(100vw - 210px);
  margin-left: 210px;
}

.full-router {
  width: 100vw;
  margin-left: 0;
}

.page-view {
  width: 160vh;
  margin-top: 60px;
  position: relative;
  margin-left: auto;
  margin-right: auto;display: inline-flex;
  align-self: flex-start;
  height: 100%
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;
}

.selectable {
  cursor: crosshair;
}

.loading-screen {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  opacity: 0;
  z-index: 999;
  cursor: wait;
}

body .v-application .v-select.v-input--is-disabled .v-input__control::after {
  opacity: 0.5;
}

body .v-application .v-select.page-type-chip .v-field .v-field__input {
  padding: 7px; 
  font-size: small;
}

body .v-application .v-select.page-type-chip .v-field--variant-filled .v-field__outline:before,
body .v-application .v-select.page-type-chip .v-field--variant-filled .v-field__outline:after {
  border-color: transparent;
}

body .v-application .v-select.page-type-chip .v-input__control::after {
  position: unset;
  display: inline-block;
  margin: auto 10px auto 0;
}

body .v-application .mailboxes-table .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px !important;
}

body .v-application .v-input.v-text-field .v-label + input#datasetName {
  margin-top: 5px;
}

.v-skeleton-loader {
  background: none !important;
}

.v-skeleton-loader .v-skeleton-loader__text {
  margin-bottom: 0px !important;
}

.small-button {
  vertical-align: top;
  color: white !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  box-shadow: none;
  margin-top: -2px;
}

.vertical-centered {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.horizontal-centered {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.bottom-gap {
  margin-bottom: 20px !important;
}

.bottom-gap-lg {
  margin-bottom: 30px !important;
}

.bottom-gap-sm {
  margin-bottom: 10px !important;
}

.top-gap {
  margin-top: 22px !important;
}

.top-gap-lg {
  margin-top: 30px !important;
}

.top-gap-sm {
  margin-top: 10px !important;
}

.right-gap {
  margin-right: 20px !important;
}

.right-gap-lg {
  margin-right: 30px !important;
}

.right-gap-sm {
  margin-right: 10px !important;
}

.left-gap {
  margin-left: 20px !important;
}

.left-gap-sm {
  margin-left: 10px !important;
}

.left-gap-lg {
  margin-left: 30px !important;
}

.custom-textarea {
  width: 100% !important;
  max-height: none !important;
  border-radius: 4px !important;
  border: 1px solid rgb(var(--v-theme-grey-darken2)) !important;
  background-color: white !important;
  font-family: 'Inter' !important;
  font-size: rem(14) !important;
  font-weight: 400 !important;
  line-height: rem(20) !important;
  letter-spacing: normal !important;
  padding: 9px !important;
  height: rem(150) !important;
  resize: none !important;
}

textarea:focus {
  border: 1px solid rgb(var(--v-theme-primary)) !important;
}

.radio-box {
  border: 1px solid rgb(var(--v-theme-grey-darken2));
  background-color: white;
  border-radius: 4px;
  padding-right: 20px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 40px;

  &.v-radio--is-disabled {
    color: #888 !important;
    cursor: not-allowed !important;
  }
}

.v-btn {
  box-shadow: none !important;
}

.radio-label {
  font-size: 0.9rem;
}

.table-input {
  width: 350px !important;
  display: inline-block !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.inline-middle {
  display: inline-block !important;
  vertical-align: middle;
}

.inline-top {
  display: inline-block !important;
  vertical-align: top;
}

.divider-line {
  border-top: 1px solid rgb(var(--v-theme-grey-darken2));
}

.sidebarView {
  height: 100%;
  display: flex;
  flex-direction: column;
  // padding: 0 30px;

  @include desktop {
    flex-direction: row-reverse;
    align-items: flex-start;
  }

  &__container {
    display: flex;
    flex-direction: column-reverse;

    @include desktop {
      display: block;
    }
  }

  &__sidebar {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    // align-items: center;

    @include desktop {
      // float: left;
      max-width: rem(300);
      margin-top: 8px;

      &--fullContainer {
        float: none;
      }

      &--open {
        // align-items: flex-start;
        padding-left: 20px;
      }
    }
    &--nofull {
      padding: 0 30px;
    }
  }

  &__content {
    width: 100%;
    height: 100%;

    @include desktop {
      &--fullContainer {
        padding-left: 80px;
      }
    }
  }

  &--full {
    padding: 0;

    .sidebarView__content {
      box-shadow: 0 2px 4px 3px rgba(#000, 0.03);
    }

    @include desktop {
      display: flex;
    }

    .sidebarView__sidebar {
      position: static;
      padding: 0 30px;
    }

    .sidebarView__content {
      margin-top: 0;
      padding-top: 40px;
      flex: 1;
    }
  }
}

.sidebar-container {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background-color: rgb(var(--v-theme-primary-darken3));
  z-index: 999;
  transition: width 300ms;
}

.sidebar-button {
  text-align: center;
  position: absolute;
  right: -12px;
  color: rgb(var(--v-theme-primary-darken3)) !important;
  top: 65px;
  background-color: rgb(var(--v-theme-primary-lighten2));
  width: 24px;
  height: 24px;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
}

.sidebar-container:hover .sidebar-button {
  visibility: visible;
  opacity: 1,
}

.table-footer-hide {
  position: absolute;
  right: 0px;
  top: 10px;
  background-color: white;
  height: 50px;
  width: 500px;
  z-index: 900;
}

.stretch {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.doc-type-nav.v-card.v-card--link.v-sheet {
  box-shadow: none !important;
  border-radius: 10px 10px 0 0;
}

.v-item--active > .doc-type-nav.v-card.v-sheet {
  background-color: rgb(var(--v-theme-grey-darken1)) !important;
  border: 1px solid rgb(var(--v-theme-primary));
  border-bottom: none;
}

.doc-type-nav.v-card--link:before {
  background: white;
}

.v-card.v-card--flat.transparent-card {
  box-shadow: none !important;
  border-radius: 0px;
}

.dialog-card {
  padding: 28px !important;
}

.warning-message-card {
  padding: 10px;
  margin: 10px 0 0 0;
  border: 1px solid rgb(var(--v-theme-primary));
  border-radius: 5px;
  background-color: #502BFF33;
}

.radio-container .v-input__control .v-input__slot .v-input--radio-group--column .v-input--radio-group__input {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.v-input--radio-group--column .v-radio.radio-btn {
  width: 20%;
  display: flex;
  justify-content: center;
  padding-right: 16px;
  padding-left: 16px;
}

.v-input--radio-group--column .v-radio.radio-btn label {
  max-width: 30%;
}

.v-application .v-input.callback .v-input__icon--clear {
  padding: 0;
  color: rgb(var(--v-theme-grey-darken2));
}

.v-application .v-input.callback:not(.v-select) .v-input__append-inner {
  bottom: 50%;
}

.v-application .callback.v-input.error--text .v-text-field__details {
  margin: 0;
}

.v-snack__action {
  flex-shrink: 0;
}

.v-application .v-dialog.v-dialog--active {
  display: block !important;

  &.show-overflow {
    overflow-y: visible !important;
  }
}

.lock-card .v-input.v-input--selection-controls.v-input--switch .v-input__control .v-input__slot .v-input--selection-controls__input {
  margin-right: 0px !important;
}

.v-btn.v-btn--disabled.v-btn--has-bg {
  box-shadow: none !important;
}

body .v-application .mailboxes-table .v-data-table > .v-data-table__wrapper > table > tbody > tr.v-data-table__empty-wrapper > td {
  padding: 16px !important;
}

body .v-selection-control--dirty .v-selection-control__input>.v-icon {
  color: rgb(var(--v-theme-primary)) !important;
}

body .v-selection-control--inline .v-label {
  opacity: 1 !important;
}

body .v-application .v-input.search-config .v-label.v-slider__label {
  font-size: 16px;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

body .v-application .v-input.filter-field input,
body .v-application .v-input.filter-field .v-input__control {
  height: 40px !important;
}

.dialog-title {
  color: rgb(var(--v-theme-primary)) !important;
  font-weight: 500 !important;
  font-family: 'Telegraf' !important;
}

.callback input[type="url"] {
  padding-left: 0.75rem;
}

.divided-config-page-left {
  height: 100vh;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  overflow: auto;
  position: relative;
  padding: 20px;
  padding-top: 13px;
  width: 41%;
}

.page-padding {
  padding: 0 60px 25px 60px;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
}

.fade-in-slow {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
  animation-delay: 0.2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-out {
	opacity: 1;
	animation-name: fadeOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
}

.v-switch.v-input--density-compact.primary-label-switch .v-input__control .v-selection-control .v-selection-control__wrapper .v-selection-control__input .v-switch__thumb.bg-primary {
    background-color: #ffffff !important;
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
