<template>
  <div class="extraction-group-config">
    <div
      class="top-gap bottom-gap-sm"
      style="max-width: 83%;"
    >
      <div class="label mb-2">
        {{ $t('dataPoints.description') }}
      </div>
      <TextEditor
        ref="descriptionEditor"
        class="w-100"
        rows="5"
        :text="extractorDescription"
        @save="(description) => $emit('updateDescription', description)"
      />
    </div>
    <div class="label">
      {{ $t('dataPoints.extract_method') }}
    </div>
    <v-radio-group
      v-model="extractionType"
      class="top-gap-sm"
      hide-details
      inline
    >
      <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
        <v-radio
          value="model"
          :label="$t('dataPoints.model_option')"
        />
      </div>
      <div class="inline-middle radio-box right-gap-sm bottom-gap-sm">
        <v-radio
          value="generative"
          :label="$t('dataPoints.generative.title')"
        />
      </div>
    </v-radio-group>
    <div v-if="extractionType === 'model'">
      <div class="label">
        {{ $t('dataPoints.aggregation_method') }}
      </div>
      <v-select 
        v-model="group.aggregation_method" 
        class="inline-middle"
        style="margin-top: 5px; width: 300px;"
        variant="outlined"
        color="primary"
        density="compact"
        :items="localMethods"
        @update:model-value="handleMethodChange"
      />
      <div v-if="type === 'extraction_group' && group.aggregation_method === 'block'">
        <div class="top-gap-sm">
          <div
            class="inline-middle radio-box right-gap-sm"
            style="padding-right: 20px"
          >
            <v-checkbox
              v-model="group.multi_page"
              class="inline-middle right-gap-sm"
              style="margin-top: -7px"
              color="primary"
              :label="$t('dataPoints.multi_page')"
              @update:model-value="handleMultiPage"
            />
          </div>
        </div>
      </div>
      <div v-if="type === 'extraction_group' && group.aggregation_method === 'cluster'">
        <div class="label">
          {{ $t('dataPoints.number_of_clusters') }}
        </div>
        <div class="top-gap-sm">
          <div
            class="inline-middle radio-box right-gap-sm"
            style="padding-right: 20px"
          >
            <v-checkbox
              v-model="autoDetectClusters"
              class="inline-middle right-gap-sm"
              style="margin-top: -7px"
              color="primary"
              :label="$t('dataPoints.auto_detect')"
            />
          </div>
          <div
            class="inline-middle radio-box"
            style="padding-right: 10px"
            :style="{ opacity: autoDetectClusters ? 0.5 : 1 }"
          >
            <input
              ref="PageField"
              v-model="group.nb_clusters"
              class="config-input-field right-gap-sm accent--text"
              style="margin-top: 5px; margin-left: 10px;"
              placeholder="0"
              type="number"
              :min="0"
              @input="handleClusterNumbers"
            >
          </div>
        </div>
      </div>
      <div
        v-if="type == 'extraction_group'"
        class="top-gap-sm"
      >
        <div
          class="radio-box inline-middle"
          style="padding-right: 20px"
        >
          <v-checkbox
            v-model="keepBestOn"
            class="inline-middle right-gap-sm"
            style="margin-top: -7px"
            color="primary"
            :label="$t('dataPoints.keep_values_from')"
          />
          <input
            ref="PageField"
            v-model="group.keep_best"
            class="config-input-field right-gap-sm primary--text"
            style="position: relative; top: -14px"
            placeholder="1"
            type="number"
            :min="1"
            @input="handleKeepBestInput"
          >
          <div
            class="inline-middle clickable"
            style="font-size: 0.9rem; margin-top: -30px;"
            @click="keepBestOn = !keepBestOn"
          >
            {{ $t('dataPoints.best_pages') }}
          </div>
        </div>
        <div
          class="left-gap-lg top-gap-sm"
          style="width: fit-content"
        >
          <div
            class="radio-box inline-top right-gap-sm"
            style="padding-right: 10px"
          >
            <v-checkbox
              v-model="hasForbiddenValues"
              class="inline-middle right-gap-sm"
              style="margin-top: -7px"
              color="primary"
              :label="$t('dataPoints.forbidden_values')"
              :class="{ 'disabled-text': !keepBestOn }"
              :disabled="!keepBestOn"
            />
          </div>
          <div
            style="text-align: right"
            class="top-gap"
          >
            <div class="inline-top">
              <v-text-field
                v-for="(text, index) in group.forbidden_values"
                :key="index"
                class="word-input"
                style="width: 250px"
                append-icon="fas fa-times"
                variant="outlined"
                color="primary"
                density="compact"
                :value="text"
                :disabled="!hasForbiddenValues"
                @input="(t) => group.forbidden_values[index] = t"
                @click:append="removeExcluded(text)"
              />
              <v-text-field
                ref="sameLineField"
                v-model="newExcluded"
                class="word-input"
                style="width: 250px"
                variant="outlined"
                color="primary"
                density="compact"
                :placeholder="$t('dataPoints.type_new_text')"
                :disabled="!hasForbiddenValues"
                @keyup.enter="handleNewExcluded"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="type == 'extraction_group'">
        <div
          v-for="(option, index) in switchOptions"
          :key="index"
          class="inline-middle radio-box top-gap-sm right-gap-sm"
          style="padding-right: 9px !important;"
        >
          <v-checkbox
            v-model="group[option.value]"
            class="inline-middle right-gap-sm"
            style="margin-top: -7px"
            color="primary"
            :class="{
              'disabled-text': option.value === 'single_item' && group.aggregation_method === 'cluster'
            }"
            :label="option.label"
            :disabled="option.value === 'single_item' && group.aggregation_method === 'cluster'"
            @update:model-value="option.onChange"
          />
          <v-tooltip
            v-if="option.value === 'show_empty_primary'"
            right
          >
            <template #activator="{ props }">
              <v-icon
                class="clickable"
                color="primary"
                style="margin-top: -30px"
                size="16"
                v-bind="props"
              >
                fas fa-info-circle
              </v-icon>
            </template>
            {{ option.info }}
          </v-tooltip>
        </div>
      </div>
      <div class="top-gap">
        <div class="label">
          {{ $t('models.labels') }}
          <span>
            ({{ `${selectedName} ${
              group.custom_model_version ? `v. ${group.custom_model_version}` : `– ${$t('models.no_version_selected')}`
            }` }})
          </span>
          <v-chip
            class="ml-2"
            style="background-color: #CCC2FF !important"
            variant="outlined"
          >
            {{ group.labels.length }}
            {{ $tc('models.added_labels', group.labels.length == 1 ? 1 : 2) }}
          </v-chip>
        </div>
        <div
          v-if="group.labels.length > 0 && !group.primary_labels.some(item => item)"
          class="info-box top-gap"
        >
          <small>
            <div
              class="inline-middle"
              style="width: 30px"
            >
              <v-icon
                class="info-icon"
                size="16"
              >
                fas fa-info-circle
              </v-icon>
            </div>
            <div
              class="inline-middle"
              style="width: calc(100% - 30px)"
            >
              {{ $t('dataPoints.primary_info') }}
            </div>
          </small>
        </div>
        <small class="inline-middle top-gap right-gap-sm">
          {{ $t('models.select_version') }}
        </small>
        <v-select
          v-if="group.custom_model_id !== 0"
          v-model="group.custom_model_version"
          class="clickable inline-middle top-gap"
          variant="outlined"
          color="primary"
          density="compact"
          item-title="version"
          item-value="version"
          style="width: 120px"
          :disabled="group.custom_model_id === 0"
          :items="modelVersions"
          :placeholder="$t('models.no_version_selected')"
          @update:model-value="handleVersionChange"
        />
        <v-card
          v-if="group.labels.length > 0"
          class="top-gap label-card"
        >
          <v-row class="label-row font-weight-bold">
            <v-col class="d-flex align-center py-0">
              {{ $t('models.labels') }}
            </v-col>
            <v-col class="d-flex align-center text-center py-0 pr-2 pl-0">
              {{ $t('datatable.header.value_type') }}
            </v-col>
            <v-col
              cols="3"
              class="d-flex justify-center align-center pa-0"
            >
              <div class="right-gap-sm">
                {{ $t('dataPoints.primary') }}
              </div>
              <v-switch
                v-model="allPrimary"
                class="primary-label-switch mt-0"
                color="primary"
                density="compact"
                :disabled="group.primary_labels
                  && group.primary_labels.length === 1
                  && group.primary_labels[0]
                  && group.labels.length === 1
                "
                hide-details
              />
            </v-col>
          </v-row>
          <v-row
            v-for="(label, index) in group.labels"
            :key="index"
            class="label-row"
            :class="{ 'pb-0': group.value_types[index] === 'regex' }"
          >
            <v-col style="overflow-wrap: anywhere">
              <v-tooltip
                v-if="editName !== index"
                right
              >
                <template #activator="{ props }">
                  <span
                    v-bind="props"
                    style="position: relative; top: 3px"
                  >
                    {{ group.display_names[index] || label }}
                  </span>
                </template>
                {{ label }}
              </v-tooltip>
              <v-text-field
                v-else
                :ref="`displayName-${index}`"
                v-model="group.display_names[index]"
                variant="outlined"
                style="margin-top: -5px"
                color="primary"
                density="compact"
                :rules="[(name) => noDuplicates(name, index) || $t('docTypes.duplicated_names')]"
                @keyup.enter="saveDisplayName(index)"
                @keyup.esc="editName = -1"
              />
              <v-tooltip
                v-if="type === 'extraction_group' && editName !== index"
                right
              >
                <template #activator="{ props }">
                  <v-icon
                    class="fas fa-pen clickable ml-3"
                    color="primary"
                    size="16"
                    v-bind="props"
                    @click="editDisplayName(index)"
                  />
                </template>
                {{ $t('docTypes.display_name_info') }}
              </v-tooltip>
              <v-icon
                class="close-button clickable"
                color="black"
                size="14"
                @click="deleteLabel(index)"
              >
                fas fa-times
              </v-icon>
            </v-col>
            <v-col>
              <v-select
                v-model="group.value_types[index]"
                class="inline-middle"
                style="width: 150px; margin-top: -5px;"
                variant="outlined"
                color="primary"
                density="compact"
                :items="valueTypes"
                :disabled="type === 'data_point'"
              />
            </v-col>
            <v-col
              cols="3"
              class="text-center pa-0"
            >
              <v-switch
                v-model="group.primary_labels[index]"
                color="primary"
                class="primary-label-switch inline-middle mt-2"
                density="compact"
                :disabled="group.primary_labels.length === 1 && group.primary_labels[0] && group.labels.length === 1"
              />
            </v-col>
            <v-col
              v-if="group.value_types[index] === 'regex'"
              cols="12"
              class="regex-config px-7 py-2"
              :class="{ 'rounded-card rounded-t-0': index + 1 === group.labels.length }"
            >
              Regex:
              <input
                ref="regexField"
                v-model="group.regex[index].pattern"
                class="regex-field right-gap-sm primary--text"
                @keydown.enter="blurRegexField"
              >
              <div
                class="inline-middle mt-2"
                style="padding-right: 20px"
              >
                {{ $t('dataPoints.replace_match_with') }}
                <input
                  ref="substituteField"
                  v-model="group.regex[index].substitute"
                  class="regex-field primary--text"
                  @keydown.enter="blurSubstituteField"
                >
              </div>
            </v-col>
          </v-row>
        </v-card>
        <div
          v-else-if="group.labels.length === 0"
          class="no-label-sign mt-5"
        >
          {{ $t('dataPoints.no_labels') }}
        </div>
      </div>
      <div class="d-flex justify-space-between align-center top-gap">
        <CustomSelect
          class="flex-grow-0"
          width="250px"
          :max-height="120"
          :items="availableLabels"
          :selected="selectedEntity"
          :disabled="availableLabels.length === 0"
          :placeholder="$t('models.add_label')"
          :server-filter="false"
          @selected-changed="addLabel"
          clickable
        />
        <v-btn
          color="primary"
          style="box-shadow: none"
          variant="outlined"
          :disabled="availableLabels.length === 0"
          @click="addAllLabels"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-plus
          </v-icon>
          {{ $t('dataPoints.add_all_labels') }}
        </v-btn>
      </div>
    </div>
    <GenerativeExtractorLabels
      v-if="extractionType === 'generative'"
      :labels="group.labels"
      @update-labels="(newLabels) => $emit('updateLabels', newLabels)"
    />
  </div>
</template>

<script>
import model_mixin from '@/mixins/model.js';
import feedback_mixin from '@/mixins/feedback.js';

import TextEditor from '@/components/common/elements/General/TextEditor';
import CustomSelect from '@/components/common/elements/Forms/CustomSelect.vue';
import GenerativeExtractorLabels from '@/components/extract/elements/LabelGroups/GenerativeExtractorLabels';

export default {
  name: 'ExtractionGroupConfig',

  mixins: [
    model_mixin,
    feedback_mixin,
  ],

  components: {
    TextEditor,
    GenerativeExtractorLabels,
    CustomSelect,
  },

  data() {
    return ({
      selectedEntity: '',
      errorTimeOut: null,
      editName: -1,
      methods: ['line', 'divided_line', 'column', 'block', 'cluster', 'table_row', 'table_cell'],
      autoDetectClusters: true,
      multi_page: false,
      keepBestOn: false,
      addingExcluded: false,
      newExcluded: '',
      selectedName: '',
      labels: [],
      hasForbiddenValues: false,
      modelVersions: [],
      switchOptions: [
        {
          info: this.$t('dataPoints.empty_primary_info'),
          label: this.$t('dataPoints.create_empty_primary_group'),
          onChange: this.handleShowPrimary,
          value: 'create_empty_primary_group',
        },
        {
          info: this.$t('dataPoints.full_primary_info'),
          label: this.$t('dataPoints.show_empty_primary'),
          onChange: this.handleFullPrimary,
          value: 'show_empty_primary',
        },
        {
          info: this.$t('dataPoints.single_item_group_info'),
          label: this.$t('dataPoints.single_item_group'),
          onChange: (value) => this.group.single_item = value,
          value: 'single_item',
        }
      ],
      allPrimary: false,
      extractionType: '',
      newLabel: '',
      valueTypes: [
        {
          title: this.$t('datatable.value_type.unspecified'),
          value: 'unspecified',
        },
        {
          title: this.$t('datatable.value_type.date'),
          value: 'date',
        },
        {
          title: this.$t('datatable.value_type.integer'),
          value: 'integer',
        },
        {
          title: this.$t('datatable.value_type.float'),
          value: 'float',
        },
        {
          title: this.$t('datatable.value_type.regex'),
          value: 'regex',
        },
      ],
      generativeLabels: [],
      modelLabels: [],
    });
  },

  computed: {
    extractorDescription: {
      get() {
        return this.group.description || '';
      },
    },

    availableLabels() {
      return this.labels.filter(label => !this.group.labels.includes(label.id));
    },

    localMethods() {
      return this.methods.map(method => ({ 
        title: this.$t(`dataPoints.${method}`), 
        value: method 
      }))
    },
  },

  watch: {
    allPrimary(newAllPrimary) {
      this.group.primary_labels = new Array(this.group.labels.length).fill(newAllPrimary);
    },

    addingExcluded(newAddingExcluded) {
      if (newAddingExcluded) {
        setTimeout(() => this.$refs.NewExcludedInput.focus(), 10);
      }
    },

    keepBestOn(keepBestOn) {
      if (keepBestOn && !this.group.keep_best) {
        this.group.keep_best = 1;
      } else if (!keepBestOn) {
        this.group.keep_best = null;
      }
      if (!keepBestOn) {
        this.hasForbiddenValues = false;
      }
    },

    autoDetectClusters(auto) {
      const clusters = this.group.nb_clusters || 1;
      this.group.nb_clusters = auto ? 0 : clusters;
    },

    extractionType(newType, oldType) {
      if (!oldType) {
        return;
      }
      switch (newType) {
        case 'model':
          this.generativeLabels = this.group.labels;
          this.group.labels = this.modelLabels;
          this.modelLabels = [];
          break;
        case 'generative':
          this.modelLabels = this.group.labels;
          this.group.labels = this.generativeLabels;
          this.generativeLabels = [];
          break;
      }
      this.$emit('updateExtractionType', newType);
      this.$emit('updateLabels', this.group.labels);
    },
  },

  async mounted() {
    this.extractionType = this.group.category || 'model';
    if (this.extractionType === 'model') {
      this.handleClusterNumbers();
      this.handleMultiPage();
      this.keepBestOn = !!this.group.keep_best;
      const modelInfo = await this.getModelInfo(this.group.custom_model_id);
      if (modelInfo) {
        this.selectedName = modelInfo.name;
        const version = modelInfo.versions.find(v => v.version === this.group.custom_model_version);
        this.modelVersions = modelInfo.versions;
        this.labels = version.labels.map(entity => ({ id: entity, name: entity }));
      }
      if (this.group.forbidden_values && this.group.forbidden_values.length > 0) {
        this.hasForbiddenValues = true;
      }
      this.allPrimary = this.group.primary_labels && this.group.primary_labels.every(item => item);
    }
  },

  methods: {
    saveDisplayName(index) {
      const textField = this.$refs[`displayName-${index}`][0];
      if (textField.valid) {
        textField.validate(false);
        this.editName = -1;
      }
    },

    addGenerativeLabel() {
      if (!this.newLabel.trim()) {
        return;
      }
      this.$emit('updateLabels', [
        ...this.group.labels,
        {
          label: this.newLabel.trim(),
          value_type: 'unspecified',
          description: '',
        },
      ]);
      this.newLabel = '';
    },
    
    addLabel(label) {
      if (!label) {
        return;
      }
      this.group.labels.push(label);
      this.group.value_types.push('unspecified');
      this.group.primary_labels.push(this.group.labels.length === 1);
      this.group.display_names.push('');
      this.group.regex.push({
        pattern: '',
        substitute: '',
      });
      if (this.group.required_precisions) {
        this.group.required_precisions.push(null);
      }
      this.$nextTick(() => {
        this.selectedEntity = '';
      });
    },

    addAllLabels() {
      const length = this.availableLabels.length
      const remainingLabels = this.availableLabels.map((item) => item.id);
      const remainingTypes = Array(length).fill('unspecified');
      const remainingPrimary = Array(length).fill(false);
      const remainingNames = Array(length).fill('');
      const remainingRegex = Array(length).fill({
        pattern: '',
        substitute: '',
      });

      this.group.labels.push(...remainingLabels);
      this.group.value_types.push(...remainingTypes);
      this.group.primary_labels.push(...remainingPrimary);
      this.group.display_names.push(...remainingNames);
      this.group.regex.push(...remainingRegex);
    },

    handleVersionChange(newVersion) {
      const selectedVersion = this.modelVersions.find(v => v.version === newVersion);
      this.labels = selectedVersion.labels.map(entity => ({ id: entity, name: entity }));
      const newLabels = [];
      const newTypes = [];
      const newPrimary = [];
      const newNames = [];
      const newRegex = [];
      const newPrecisions = [];
      this.group.labels.forEach((label, index) => {
        if (selectedVersion.labels.includes(label)) {
          newLabels.push(label);
          newTypes.push(this.group.value_types[index]);
          newPrimary.push(this.group.primary_labels[index]);
          newNames.push(this.group.display_names[index]);
          newRegex.push(this.group.regex[index]);
          if (this.group.required_precisions) {
            newPrecisions.push(this.group.required_precisions[index]);
          }
        }
      });
      this.group.labels = newLabels;
      this.group.primary_labels = newPrimary;
      this.group.value_types = newTypes;
      this.group.regex = newRegex;
      this.group.display_names = newNames;
      if (this.group.required_precisions) {
        this.group.required_precisions = newPrecisions;
      }
    },

    blurRegexField() {
      setTimeout(() => {
          this.$refs.regexField.blur();
        }, 10);
    },

    blurSubstituteField() {
      setTimeout(() => {
          this.$refs.substituteField.blur();
        }, 10);
    },

    checkPrimary(index) {
      if (this.errorTimeOut) {
        clearTimeout(this.errorTimeOut);
      }
      if (this.group.primary_labels.every(item => !item)) {
        this.primaryLabelError();
        setTimeout(() => this.$emit('updatePrimary', index), 500);
      } else if (
        this.group.show_empty_primary && !this.group.primary_labels[index]
      ) {
        this.$store.commit('setErrorMessage', this.$t('dataPoints.need_all_primary'));
        this.$store.commit('setSnackbar', true);
        this.errorTimeOut = setTimeout(
          () => this.$store.commit('setErrorMessage', this.$t('default_error')),
          5000
        );
        setTimeout(() => this.$emit('updatePrimary', index), 500);
      }
    },

    deleteLabel(index) {
      if (this.extractionType === 'model') {
        this.group.labels.splice(index, 1);
        this.group.value_types.splice(index, 1);
        this.group.primary_labels.splice(index, 1);
        this.group.display_names.splice(index, 1);
        if (this.group.required_precisions) {
          this.group.required_precisions.splice(index, 1);
        }
      } else {
        this.$emit('updateLabels', this.group.labels.filter((_, i) => i !== index));
      }
    },

    editDisplayName(index) {
      this.editName = index;
      setTimeout(() => {
        this.$refs[`displayName-${index}`][0].focus();
      }, 10);
    },

    noDuplicates(name, index) {
      return this.group.display_names.reduce(
        (res, item, i) => res && (index === i || !item || item !== name),
        true
      );
    },

    handleFullPrimary(fullPrimary) {
      if (fullPrimary) {
        this.group.create_empty_primary_group = false;
        this.group.primary_labels = new Array(this.group.primary_labels.length).fill(true);
      }
    },

    handleMultiPage(multiPage) {
      if (multiPage) {
        this.group.multi_page = true;
      }
    },

    handleShowPrimary(showPrimary) {
      if (showPrimary) {
        this.group.show_empty_primary = false;
      }
    },

    removeExcluded(value) {
      this.group.forbidden_values = this.group.forbidden_values.filter(v => v !== value);
    },

    handleNewExcluded() {
      this.group.forbidden_values.push(this.newExcluded.trim());
      this.newExcluded = '';
    },

    handleKeepBestInput() {
      this.keepBestOn = !!this.group.keep_best;
    },

    handleClusterNumbers() {
      this.autoDetectClusters = this.group.nb_clusters == 0;
    },

    handleMethodChange(method) {
      if (method === 'cluster' && this.type === 'data_point') {
        this.group.nb_clusters = 0;
      }
      if (method !== 'blocks') {
        this.group.multi_page = false;
      }
    },
  },

  props: {
    type: {
      type: String,
      required: true,
    },

    group: {
      type: Object,
      required: true,
    },
  },

  emits: ['updatePrimary', 'updateLabels', 'updateExtractionType', 'updateDescription'],
}
</script>

<style lang="scss" scoped>
.extraction-group-config {
  .config-input-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 50px;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }

  .disabled-text {
    opacity: 0.5;
  }

  .label-card {
    padding: 0;
  }

  .label-row {
    margin: 0 !important;
    position: relative;
    height: 55px;
    border-bottom: 1px solid #eee;
  }

  .regex-config {
    background-color: #e1dbff;
    font-size: 0.8rem;
  }

  .rounded-card {
    border-radius: 0.3125rem;
  }

  .regex-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;
    border-bottom: 1px solid rgb(var(--v-theme-primary));
    width: 200px;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }
  .no-label-sign {
    font-style: italic;
    text-align: center;
  }

  .add-all-labels {
    font-size: 12px;

    &__enabled {
      cursor: pointer;
      color: rgb(var(--v-theme-primary));
      text-decoration: underline;
      font-style: normal;
    }

    &__disabled {
      cursor: default;
      color: #999;
      text-decoration: none;
      font-style: italic;
    }
  }

  .word-input {
    width: 200px;
    position: relative;
    top: -4px;
    left: -10px;
    margin-top: -11px !important;
  }

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: fit-content;

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }

  .close-button {
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 3;
  }
}
</style>