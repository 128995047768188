<template>
  <div class="datapoint-view">
    <div class="divided-config-page-left">
      <div class="d-flex">
        <BackLink :target="backTarget" />
        <DocSelect
          v-if="$store.getters.loggedInUser.role === 'orgadmin'"
          style="margin-left: auto"
          :docs="files"
          :selected="selectedFile"
          @selected-changed="selectFileById"
          @get-more="getFiles(numberOfFiles)"
        />
      </div>
      <div style="padding: 7px 10px 0px 30px">
        <ConfigureDP
          v-if="edited.id > -1"
          :id="edited.id"
          ref="ConfigureDP"
          class="fade-in"
          :name="edited.name"
          :paths="edited.extraction_paths"
          :doc-loading="docLoading"
          :is-valid="isValid"
          :data-point="edited"
          @save="saveDataPoint"
          @change-description="(description) => saveDataPoint({ description })"
          @change-type="handleTypeChange"
          @change-value-type="changedValueType = true"
          @change-custom-entity="(entity) => selectedCustomEntity = entity"
          @activate="activate"
          @validity-change="validity => allValid = validity"
          @remove-path="deletePaths"
          @name-save="savedChanges = true"
          @update-primary="updatePrimary"
          @update-generative-labels="(labels) => edited.labels = labels"
        />
        <CreateDP
          v-else-if="!$route.params.itemId"
          @create="saveDataPoint"
        />
      </div>
      <ExtractionResult
        v-if="edited.id > -1"
        ref="extractionResult"
        :is-valid="isValid"
        :value="viewerValuesAll.length > 0 ? viewerValuesAll[0] : {}"
        :fail-message="$t('dataPoints.no_values_extracted')"
        :file-id="selectedFile"
        @hover="handleHighlight"
        @hoverout="deHighlightDP"
        @save="$refs.ConfigureDP.saveDataPoint()"
        @reload-value="handleReloadValue"
      />
    </div>
    <DocViewer
      v-if="$store.getters.loggedInUser.role === 'orgadmin'"
      ref="filesDocViewer"
      style="width: 59%"
      type="configureDP"
      :document="viewFile"
      :loading="docLoading"
      :viewer-values="viewerValuesAll"
      :has-tables="!!viewFile.hasTables"
      :has-text="!!viewFile.hasText"
      :has-layout="!!viewFile.hasLayout"
      :has-entities="!!viewFile.hasEntities"
      :has-forms="!!viewFile.hasForms"
      :has-values="viewFileHasValues"
      :active-datapoint="activeDP"
      :active-tab="activeTab"
      :raw-values="viewFile.raw_values || []"
      tabs
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import DocViewer from '@/components/extract/elements/DocViewer/DocViewer.vue';
import ConfigureDP from '@/components/extract/elements/DataPoints/ConfigureDP.vue';
import CreateDP from '@/components/extract/elements/DataPoints/CreateDP.vue';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import DocSelect from '@/components/extract/elements/Documents/DocSelect';
import ExtractionResult from '@/components/extract/elements/Extractors/ExtractionResult';
import file_mixin from '@/mixins/file.js';
import extractor_mixin from '@/mixins/extractor.js';
import { DataPointAPI } from '@/API/extract/DataPointAPI';

export default {
  name: 'DatapointView',

  mixins: [
    file_mixin,
    extractor_mixin,
  ],

  components: {
    DocViewer,
    ConfigureDP,
    CreateDP,
    BackLink,
    DocSelect,
    ExtractionResult,
  },

  data() {
    return ({
      allValid: false,
      activeTab: '',
      extractionType: 'model',
      changedValueType: false,
      selectedCustomEntity: '',
      deletedPaths: new Set(),
      type: 'data_point',
    })
  },

  computed: {
    viewFileHasValues() {
      this.baseKey;
      return (
        !!this.viewFile && !!this.viewFile.values && !!this.viewFile.values[this.edited.id] && !!this.viewFile.values[this.edited.id].value
      );
    },

    viewerValuesAll() {
      this.baseKey;
      if (this.edited.id > -1 && this.selectedFile > 0 && this.viewFile.values) {
        const value = this.viewFile.values[this.edited.id];
        if (value) {
          return [{...value, data_point_name: this.edited.name, data_point_id: this.edited.id}];
        }
      }
      return [];
    },

    isValid() {
      return (
        (this.extractionType === 'rules' && this.allValid)
        || (this.extractionType === 'model' && this.selectedCustomEntity !== '')
        || this.extractionType === 'qr_code'
        || (this.extractionType === 'group_based'
          && !!this.edited.extraction_group
          && this.edited.extraction_group.custom_model_id !== 0
          && this.edited.extraction_group.labels.length > 0
          && this.edited.extraction_group.primary_labels.length > 0
          && this.edited.extraction_group.primary_labels.some(p => p === true)
        )
        || this.extractionType === 'generative'
      );
    },

    items: {
      cache: false,
      get: function () {
        if (this.$store.getters.dataPoints[this.$route.params.id]) {
          let dataPoints =
            this.$store.getters.dataPoints[this.$route.params.id];
          return dataPoints;
        }
        return [];
      },
      set: function (newDataPoints) {
        this.$store.commit("setDataPoints", {
          typeId: this.$route.params.id,
          dataPoints: newDataPoints,
        });
        this.dataPointKey = Math.random();
      },
    },
  },

  mounted() {
    if (this.$route.params.itemId) {
      this.getDataPoint(this.$route.params.itemId);
    }
  },

  methods: {
    handleReloadValue() {
      this.$store.commit('setLoadingScreen', true);
      setTimeout(() => {
        this.getValues(true);
        this.$store.commit('setLoadingScreen', false);
      }, 500);
    },

    updatePrimary(index) {
      this.edited.extraction_group.primary_labels[index] = true;
      this.edited.extraction_group = { ...this.edited.extraction_group };
      this.edited = { ...this.edited };
    },

    handleTypeChange(type) {
      this.extractionType = type;
      if (type === 'group_based') {
        if (!this.edited.extraction_group) {
          this.edited.extraction_group = {
            custom_model_id: 0,
            custom_model_version: null,
            aggregation_method: 'line',
            labels: [],
            primary_labels: [],
            display_names: [],
            value_types: [],
            regex: [],
          }
        }
      } else {
        this.edited.extraction_group = null;
        if (['group_based', 'generative'].includes(this.extractionType)) {
          this.deletePaths(this.edited.extraction_paths);
        }
      }
    },

    deletePaths(paths) {      
      this.deletedPaths = new Set([
        ...this.deletedPaths,
        ...(paths.filter(p => p.id).map(p => p.id)),
      ]);
    },

    activate(tab) {
      this.activeTab = tab;
    },

    async getFileValues(id) {
      try {
        const response = await http.get(`files/${id}/values/`);
        return response.data;
      } catch (error) {
        console.log(error);
        return []
      }
    },

    async saveDataPoint(dataPoint) {
      if (this.edited.id === -1) {
        const dataPoints = await this.getDataPoints();
        const newDP = dataPoints[dataPoints.length - 1];
        newDP.id = dataPoint.id;
        this.edited = newDP;
        this.savedChanges = true;
      } else {
        this.$store.commit('setLoadingScreen', true);
        try {
          const deletedLength = this.deletedPaths.length;
          for (let i = 0; i < deletedLength; i++) {
            await http.delete(`system_2/extract_path/${this.deletedPaths[i]}`);
          }
          this.deletedPaths = [];

          if (!['group_based', 'generative'].includes(this.extractionType)) {
            const pathsLength = dataPoint.extraction_paths ? dataPoint.extraction_paths.length : 0;
            const toCreate = [];
            for (let i = 0; i < pathsLength; i++) {
              let path = dataPoint.extraction_paths[i];
              if (path.id) {
                await http.put(`system_2/extract_path/${path.id}/`, path);
              } else {
                toCreate.push(path);
              }
            }
            await http.post(`system_2/extract_path/${this.edited.id}/`, toCreate);
          }

          const update = {
            ...this.edited,
            ...dataPoint,
          };
          if (this.changedValueType || this.extractionType === 'group_based') {
            if (this.edited.value_type === 'regex') {
              update.regex_pattern = this.edited.regex_pattern.trim();
              if (this.edited.substitute && this.edited.substitute.trim() === '') {
                this.edited.substitute = null;
              }
              update.substitute = this.edited.substitute ? this.edited.substitute.trim() : null;
            } else if (this.extractionType === 'group_based') {
              update.extraction_group = this.edited.extraction_group;
              delete update.extraction_group.keep_best;
            }
            this.changedValueType = false;
          }
          await DataPointAPI.put(update);
          // we need to wait for the values to be extracted
          setTimeout(async () => {
            const response = await http.get(`system_2/data_point/${this.edited.id}`);
            this.docLoading = true;
            if (response.data.value_type === 'regex') {
              response.data.regex_pattern = response.data.regex.pattern;
              response.data.substitute = response.data.regex.substitute;
            }
            this.edited = response.data;
            this.savedChanges = true;
            this.$refs.extractionResult.saved = true;
            if (this.viewFile.id > 0) {
              this.getValues(true);
            } else {
              this.$store.commit('setLoadingScreen', false);
            }
          }, 500);
        } catch (error) {
          this.docLoading = false;
          this.$store.commit('setLoadingScreen', false);
          return
        }
      }
      this.activeTab = 'default';
    },

    async selectFile(item) {
      if (!item) {
        item = this.files.find(f => f.id === this.selectedFile);
      }
      this.viewFile = item;
      this.selectedFile = item.id;
      if (this.edited.id > -1 && !!this.viewFile) {
        await this.getValues();
      }
      this.saveInfo();
      this.savedChanges = false;
      this.baseKey += 10;
    },

    handleHighlight(value) {
      if (this.viewerValuesAll && this.viewerValuesAll.length > 0) {
        this.$refs.filesDocViewer.currentPage = value.page_nb;
        const activeDP = this.edited.id > -1 ? {highlighted: value} : value;
        const id = activeDP.data_point_id || activeDP.id;
        setTimeout(() => {
          this.handleZoomScroll(value.page_nb, id);
          this.activeDP = activeDP;
        }, 50);
      }
    },

    handleZoomScroll(page_nb, id) {
      const docViewer = this.$refs.filesDocViewer;
      if (docViewer) {
        docViewer.currentPage = page_nb;
        const highlights = docViewer.$refs.singleValueHighlights;
        if (highlights) {
          setTimeout(() => {
            let highlight = highlights.$refs[`value${id}`];
            if (highlight) {
              highlight.scrollIntoViewIfNeeded();
            }
          }, 100);
        }
      }
    },

    async getDataPoints() {
      try {
        const { data } = await http.get(`system_2/data_point/?document_type_id=${this.$route.params.id}`);
        return data;
      } catch (error) {
        return []
      }
    },

    aggregateValues(editedValue) {
      this.viewFile.values = {};
      if (editedValue.value) {
        editedValue.value.dp_name = editedValue.data_point_name;
        this.viewFile.values[this.edited.id] = editedValue.value;
      }
      this.edited = {...this.edited};
      this.baseKey += 10;
    },

    async getValues(wait = false) {
      this.$store.commit('setLoadingScreen', true);
      this.viewFile.pages = this.viewFile.pages.sort((a, b) => a.page_nb - b.page_nb);
      let values = [];
      values = await this.getFileValues(this.viewFile.id);
      let editedValue = values.find(dp => dp.data_point_id === this.edited.id);
      if (editedValue) {
        let retries = 4;
        while (wait && this.edited.id > -1 && retries > 0 && !editedValue.value) {
          await this.timeout(3000);
          values = await this.getFileValues(this.viewFile.id);
          editedValue = values.find(dp => dp.data_point_id === this.edited.id);
          retries--;
        }
        this.viewFile.raw_values = values;
        this.aggregateValues(editedValue);
        if (wait) {
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.updated'));
          this.$store.commit('setSuccessSnackbar', true);
        }
      }
      this.$store.commit('setLoadingScreen', false);
      this.docLoading = false;
      this.baseKey += 10;
    },
  }
}
</script>
