<template>
  <v-dialog
    v-model="showDialog" 
    max-width="750" 
    height="700"
  >
    <v-card class="dialog-card pb-2">
      <div class="d-flex justify-end">
        <v-icon
          class="close-icon"
          size="16px"
          @click="$emit('update:showDialog', false)"
        >
          fas fa-times
        </v-icon>
      </div>
      <v-card-title class="mb-4 text-h5 primary--text">
        {{ $t('workflows.documents.discard_title') }}
      </v-card-title>
      <v-radio-group
        v-model="localSelectedReason"
        @change="$emit('update:selectedReason', localSelectedReason)"
        inline
      >
        <div
          v-for="(reason, idx) in discardReasons"
          :key="idx"
          class="radio-box right-gap-sm"
        >
          <v-radio 
            :label="reason.title"
            :value="reason.value" 
          />
        </div>
      </v-radio-group>
      <v-text-field
        ref="customReasonField"
        v-model="localCustomReason"
        class="mt-4"
        variant="outlined"
        :placeholder="$t('workflows.documents.specify_other')"
        :disabled="!isOtherSelected"
        :rules="customReasonRules"
        @input="$emit('update:customReason', localCustomReason)"
      />
      <div class="d-flex justify-end">
        <v-btn
          class="clickable"
          color="primary"
          :disabled="isOtherSelected && !localCustomReason.trim()"
          @click="$emit('submitDiscardReview')"
          rounded
        >
          {{ $t('workflows.documents.discard') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
  
  <script>
  export default {
    data() {
      return {
        showDialog: this.show,
        localSelectedReason: this.selectedReason,
        localCustomReason: this.customReason
      };
    },

    computed: {
      isOtherSelected() {
        return this.localSelectedReason === this.$t('workflows.documents.other');
      },

      customReasonRules() {
        return [
          (input) => this.validateCustomReasonInput(input),
        ];
      },
    },

    watch: {
        selectedReason(newVal) {
            this.localSelectedReason = newVal;
            this.emitReason();
        },

        customReason(newVal) {
            this.localCustomReason = newVal;
        }
    },

    methods: {
      validateCustomReasonInput(customInput) {
        if (this.isOtherSelected) {
          return !!(customInput && customInput.trim()) || this.$t('workflows.documents.reason_required');
        }
        return true;
      },

      emitReason(){
        if (this.isOtherSelected){
          this.$emit('update:customReason', this.localCustomReason);
        } else {
          this.$emit('update:customReason', '');
          this.$emit('update:selectedReason', this.localSelectedReason);
        }
      }
    },

    props: {
      show: {
        type: Boolean,
        required: true
      },
      discardReasons: {
        type: Array,
        required: true
      },
      selectedReason: {
        type: String,
        required: true
      },
      customReason: {
        type: String,
        required: true
      }
    },

    emits: ['update:showDialog', 'update:selectedReason', 'update:customReason', 'submitDiscardReview'],
  };
  </script>
  