<template>
  <div class="configure-dp-group">
    <div class="top-input position-relative">
      <HeaderName
        style="max-width: calc(100% - 190px);"
        :item="{ name: newName, id: group.id }"
        @save="saveGroupName"
      />
      <v-tooltip>
        <template #activator="{ props }">
          <ButtonWithIcon
            v-bind="props"
            style="top: 3px; right: 0"
            icon="far fa-clone"
            span-class="text-no-wrap"
            class="position-absolute fade-in"
            :message="$t('dataPoints.generative.clone_generative')"
            @click="setGenerativeCopy(group)"
            rounded
          />
        </template>
        {{ $t('dataPoints.generative.clone_generative_message') }}
      </v-tooltip>
    </div>
    <ExtractionGroupConfig
      class="top-gap"
      type="extraction_group"
      :group="group"
      @update-extraction-type="type => $emit('updateExtractionType', type)"
      @update-description="description => $emit('updateDescription', description)"
      @update-labels="labels => $emit('updateLabels', labels)"
      @update-primary="index => $emit('updatePrimary', index)"
    />
    <DataPointNameDialog
      v-model="showNameDialog"
      :initial-name="generativeCopy?.name"
      @close="showNameDialog = false; generativeCopy = null"
      @save="name => handleCopyGenerative(generativeCopy, name)"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import model_mixin from '@/mixins/model.js';
import HeaderName from '@/components/common/elements/General/HeaderName';
import ExtractionGroupConfig from '@/components/extract/elements/LabelGroups/ExtractionGroupConfig';
import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon';
import DataPointNameDialog from '@/components/extract/elements/DataPoints/DataPointNameDialog';
import { copyGenerative } from '@/utils/ExtractorUtils';

export default {
  name: 'ConfigureDPGroup',

  mixins: [
    model_mixin,
  ],

  components: {
    HeaderName,
    ExtractionGroupConfig,
    ButtonWithIcon,
    DataPointNameDialog,
  },

  data() {
    return ({
      newName: '',
      showNameDialog: false,
      generativeCopy: null,
    });
  },

  async mounted() {
    this.newName = this.group.name;
  },

  unmounted() {
    this.$store.commit("setLabelGroups", {
      typeId: this.$route.params.id,
      labelGroups: [],
    });
  },

  methods: {
    setGenerativeCopy(group) {
      this.showNameDialog = true;
      this.generativeCopy = group;
    },

    async handleCopyGenerative(extractor, name) {
      try {
        this.$store.commit('setLoadingScreen', true);
        const agentId = extractor.document_type_id || this.$route.params.id;
        const newId = await copyGenerative(extractor, name, agentId);
        this.showNameDialog = false;
        this.generativeCopy = null;
        this.$store.commit('setSuccessMessage', this.$t('dataPoints.generative.cloned'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$nextTick(() => {
          this.$router.push(
            {
              name: 'DatapointConfig',
              params: {
                id: agentId,
                itemId: newId,
              },
            }
          );
        });
      } catch (error) {
        this.$store.commit('setSnackbar', true);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async saveGroupName(name) {
      try {
        await http.put(
          `system_2/extraction_groups/${this.group.id}`, { name }
        );
        this.newName = name;
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('nameSave');
      } catch (error) {
        // pass
      }
    },
  },

  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  emits: ['updatePrimary', 'nameSave', 'updateLabels', 'updateExtractionType', 'updateDescription'],
}
</script>

<style lang="scss" scoped>
.configure-dp-group {
  text-align: left;
  width: 100%;
  position: relative;
  padding-bottom: 60px;

  .section {
    margin-top: 15px;
  }

  .top-input {
    max-width: 100%;
  }

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: fit-content;
    max-width: calc(100% - 170px);

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }
}
</style>
