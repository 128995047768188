<template>
  <v-card
    class="resource-count-card right-gap bottom-gap text-center inline-middle"
    style="width: 300px; height: 185px;"
  >
    <div style="height: 90px; margin-top: -5px;">
      <div class="vertical-centered text-center">
        <h2
          v-if="count !== -1"
          class="text-h2"
        >
          {{ count }}
        </h2>
        <div v-else>
          <v-skeleton-loader
            class="inline-middle"
            style="margin-top: -10px; margin-bottom: -3px"
            type="heading"
            width="100px"
          />
        </div>
        {{ $tc(`home.${name}`, count) }}
      </div>
    </div>
    <v-skeleton-loader
      v-if="count === -1"
      class="inline-middle"
      style="margin-top: -10px; margin-bottom: -3px"
      type="heading"
      width="170px"
    />
    <v-btn
      v-else-if="count"
      color="primary"
      variant="outlined"
      @click="$router.push(configurePath)"
      rounded
    >
      <v-icon
        size="17"
        style="margin-top: -2px"
        start
      >
        {{ configureIcons[name] }}
      </v-icon>
      {{ configureTerms[name] }}
    </v-btn>
    <v-btn
      v-else
      color="primary"
      variant="outlined"
      @click="$router.push(createPath)"
      rounded
    >
      <v-icon
        size="17"
        style="margin-top: -2px"
        start
      >
        {{ configureIcons[name] }}
      </v-icon>
      {{ configureTerms[name] }}
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: 'ResourceCountCard',

  data() {
    return({
      configureTerms: {
        datasets: this.$t('models.annotate'),
        classification_models: this.$t('train'),
        extraction_models: this.$t('train'),
        extraction_agents: this.$t('validate'),
        extractions_to_review: this.$t('corrections.review_verb'),
        classifications_to_review: this.$t('corrections.review_verb'),
        workflows: this.$t('design'),
      },
      configureIcons: {
        datasets: 'fas fa-highlighter',
        classification_models: 'fas fa-flask',
        extraction_models: 'fas fa-flask',
        extraction_agents: 'fas fa-user-check',
        extractions_to_review: 'fas fa-eye',
        classifications_to_review: 'fas fa-eye',
        workflows: 'fas fa-sitemap',
      }
    });
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    count: {
      type: Number,
      required: true,
    },

    configurePath: {
      type: String,
      required: true,
    },

    createPath: {
      type: String,
      required: true,
    },
  }
}
</script>
