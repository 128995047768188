<template>
  <div class="search-enhancement-settings">
    <div
      v-for="(option, i) in ['detect_titles', 'use_google_ocr']"
      :key="i"
      class="d-flex align-center"
    >
      <v-switch
        v-model="config[option]"
        class="flex-grow-0"
        color="primary"
        :disabled="loading || !editingAllowed"
        @update:model-value="$emit('update')"
        hide-details
        inset
      />
      <div class="left-gap">
        <h4>
          {{ $t(`settings.search.${option}.title`) }}
        </h4>
        <div style="font-size: 0.8rem">
          {{ $t(`settings.search.${option}.message`) }}
        </div>
      </div>
    </div>
    <NumberStepper
      v-for="(option, i) in ['boost_content_chunk', 'boost_content_doc']"
      :key="i"
      v-model="config[option]"
      :label="$t(`settings.search.${option}.title`)"
      :max="$options.constants.RANGE_MAP[option].max"
      :min="$options.constants.RANGE_MAP[option].min"
      :disabled="loading || !editingAllowed"
      @slider-update="$emit('update')"
      @text-update="$emit('update')"
    />
    <div class="d-flex align-center">
      <v-switch
        v-model="config.use_synonyms"
        class="flex-grow-0"
        color="primary"
        :disabled="loading || !editingAllowed"
        @update:model-value="$emit('update')"
        hide-details
        inset
      />
      <div class="left-gap">
        <h4>
          {{ $t(`settings.search.use_synonyms.title`) }}
        </h4>
        <div style="font-size: 0.8rem">
          {{ $t(`settings.search.use_synonyms.message`) }}
        </div>
      </div>
    </div>
    <ButtonWithIcon
      v-if="!config.synonym_file"
      class="top-gap-sm"
      style="margin-left: 72px;"
      color="primary"
      icon="fas fa-plus"
      :disabled="loading || updatingSynFile || !config.use_synonyms || !editingAllowed"
      :message="$t('settings.search.use_synonyms.upload_file')"
      @click="selectFile"
      rounded
    />
    <div
      v-else
      style="margin-left: 72px;"
    >
      <div class="label text-field-label right-gap-sm">
        {{ $t(`settings.search.use_synonyms.current_file`) }}
      </div>
      <v-text-field
        v-model="synonymFileName"
        class="mt-0"
        density="compact"
        variant="outlined"
        :disabled="loading || updatingSynFile || !config.use_synonyms || !editingAllowed"
        hide-details
        readonly
      >
        <template #prepend-inner>
          <v-icon
            class="inline-middle"
            style="opacity: 1 !important;"
            color="primary"
            size="17"
          >
            mdi mdi-file-document
          </v-icon>
        </template>
        <template #append>
          <v-tooltip>
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                class="inline-middle clickable"
                style="opacity: 1 !important;"
                color="primary"
                @click="selectFile"
              >
                fas fa-upload
              </v-icon>
            </template>
            {{ $t('settings.search.use_synonyms.replace') }}
          </v-tooltip>
          <v-tooltip>
            <template #activator="{ props }">
              <v-icon
                v-bind="props"
                class="inline-middle clickable ml-3"
                style="opacity: 1 !important;"
                color="primary"
                @click="deleteDialog = true"
              >
                fas fa-trash
              </v-icon>
            </template>
            {{ $t('settings.search.use_synonyms.delete') }}
          </v-tooltip>
        </template>
      </v-text-field>
    </div>
  </div>
  <FileInput
    ref="uploader"
    type="searchSynonyms"
    @change="handleSynonymFile"
  />
  <DeleteDialog
    v-model="deleteDialog"
    :title="$t('settings.search.use_synonyms.delete_title')"
    :message="$t('settings.search.use_synonyms.delete_confirmation')"
    @confirm="deleteFile"
    @close="deleteDialog = false"
  />
</template>


<script>
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';

import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon';
import DeleteDialog from  '@/components/common/elements/Tables/DeleteDialog';
import FileInput from '@/components/common/elements/Forms/FileInput';
import NumberStepper from '@/components/common/elements/Forms/NumberStepper';

export default {
  name: 'SearchEnhancementSettings',

  components: {
    ButtonWithIcon,
    DeleteDialog,
    FileInput,
    NumberStepper,
  },

  constants: {
    RANGE_MAP: {
      boost_content_chunk: { min: 0, max: 10 },
      boost_content_doc: { min: 0, max: 10 },
    },
  },

  data() {
    return {
      deleteDialog: false,
      updatingSynFile: false,
    };
  },

  computed: {
    synonymFileName: {
      get(){
        const splitPath = this.config.synonym_file.split('/');
        return splitPath[splitPath.length - 1];
      },
      set(value) {
        if (this.config.synonym_file) {
          let splitPath = this.config.synonym_file.split('/');
          splitPath = splitPath.slice(0, splitPath.length - 1);
          const newPath = value && [...splitPath, value.trim()].join('/') || null;
          this.config.synonym_file = newPath;
          return;
        }
        this.config.synonym_file = value && value.trim() || null;
      },
    },
  },
  methods: {
    setLoading(value) {
      this.$store.commit('setLoadingScreen', value);
      this.updatingSynFile = value;
    },

    selectFile() {
      this.$refs.uploader.click();
    },

    async handleSynonymFile([file]) {
      try {
        this.setLoading(true);
        await SearchConfigAPI.uploadSynonyms(file);
        this.synonymFileName = file.name;
        this.$store.commit('setSuccessMessage', this.$t('settings.search.use_synonyms.file_uploaded'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$refs.uploader.value = '';
      } catch (error) {
        // pass
      } finally {
        this.setLoading(false);
      }
    },

    async deleteFile() {
      try {
        this.deleteDialog = false;
        this.setLoading(true);
        await SearchConfigAPI.deleteSynonyms();
        this.config.use_synonyms = false;
        this.synonymFileName = null;
        this.$store.commit('setSuccessMessage', this.$t('settings.search.use_synonyms.file_deleted'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        // pass
      } finally {
        this.setLoading(false);
      }
    }
  },

  props: {
    config: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    editingAllowed: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['update'],
}
</script>
