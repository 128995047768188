<template>
  <div class="doc-select d-flex align-center">
    <v-icon
      v-if="showIcon"
      class="right-gap-sm"
      color="primary"
    >
      fas fa-file-alt
    </v-icon>
    <div
      ref="customSelect"
      class="custom-select noselect"
      :style="{ width: small ? '275px' : '300px' }"
      @click="toggleSelectOn"
    >
      <v-icon
        v-if="selectedDoc.status === 'error'"
        style="margin-right: 5px;"
        color="primary"
        size="16"
      >
        fas fa-exclamation-circle
      </v-icon>
      <span
        v-else-if="
          ingestedStatuses.includes(selectedDoc.status)
            || ['doctypes', 'models'].includes(type)"
      />
      <v-icon
        v-else
        style="margin-right: 5px;"
        color="primary"
        size="16"
      >
        fas fa-spinner fa-pulse
      </v-icon>
      <div v-if="loading" />
      <span
        v-else-if="numberOfDocs === 0"
        style="font-style: italic"
      >
        {{ selectedDoc.name }}
      </span>
      <ItemName
        v-else-if="selectedDoc.status === 'error' || !ingestedStatuses.includes(selectedDoc.status)"
        :width="`calc(${width} - 53px)`"
        :item="selectedDoc"
        :clickable="false"
        :fade-in-slow="false"
      />
      <ItemName
        v-else
        :width="`calc(${width} - 32px)`"
        :item="selectedDoc"
        :clickable="false"
        :fade-in-slow="false"
      />
      <v-icon
        class="open-icon"
        size="14"
      >
        fas fa-chevron-down
      </v-icon>
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
        tabindex="0"
        :style="{ width: small ? '275px' : '300px' }"
        @focusout="selectOn = false"
        @scroll="handleScroll"
      >
        <div
          v-for="doc in displayDocs"
          :ref="`Option${doc.ordinalPos - 1}`"
          :key="doc.ordinalPos"
          class="option"
          :value="doc.id"
        >
          <div v-if="doc.type && doc.type === 'placeholder'">
            ({{ doc.ordinalPos }})
            <v-skeleton-loader
              class="inline-middle"
              style="margin-top: -10px; margin-left: -10px;"
              type="text"
              width="170px"
            />
          </div>
          <div
            v-else
            @click="selectedId = doc.id; $emit('selectedChanged', selectedId);"
          >
            <v-icon
              v-if="doc.status === 'error'"
              style="margin-right: 5px;"
              color="primary"
              size="16"
            >
              fas fa-exclamation-circle
            </v-icon>
            <span
              v-else-if="
                ingestedStatuses.includes(doc.status)
                  || ['doctypes', 'models'].includes(type)"
            />
            <v-icon
              v-else
              style="margin-right: 5px;"
              color="primary"
              size="16"
            >
              fas fa-spinner fa-pulse
            </v-icon>
            <ItemName
              v-if="doc.status === 'error' || !ingestedStatuses.includes(doc.status)"
              :width="`calc(${width} - 53px)`"
              :item="showNumber ? {...doc, name: doc.ordinalName} : doc"
              :clickable="false"
              :fade-in-slow="false"
            />
            <ItemName
              v-else
              :width="`calc(${width} - 32px)`"
              :item="showNumber ? {...doc, name: doc.ordinalName} : doc"
              :clickable="false"
              :fade-in-slow="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="canCopyFilename && selectedId !== -1">
      <v-tooltip
        color="#423F4F"
        right
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              class="left-gap-sm small-button"
              color="primary"
              @click="copyFilename"
            >
              <v-icon size="16">
                fas fa-clipboard
              </v-icon>
            </v-btn>
          </div>
        </template>
        <span style="color: white">
          {{ $t('documents.copy_filename') }}
        </span>
      </v-tooltip>
    </div>
    <div v-if="deleteable">
      <v-tooltip
        color="#423F4F"
        right
      >
        <template #activator="{ props }">
          <div v-bind="props">
            <v-btn
              v-if="!selectedDoc.user_id || selectedDoc.user_id === user.id"
              class="left-gap-sm small-button"
              color="primary"
              @click="$emit('deleteClick')"
            >
              <v-icon size="16">
                fas fa-trash
              </v-icon>
            </v-btn>
          </div>
        </template>
        <span style="color: white">
          {{ $t('delete') }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

import ItemName from '@/components/common/elements/General/ItemName';

export default {
  name: 'DocSelect',

  components: {
    ItemName,
  },

  data() {
    return ({
      selectedId: -1,
      selectOn: false,
      showStart: 0,
      showEnd: 60,
      firstVisibleOptionPrevPos: 100,
    })
  },

  computed: {
    ingestedStatuses() {
      let statuses = ['', 'ingested']
      if (this.type === 'file') {
        statuses = [
          ...statuses,
          'to_verify',
          'in_verification',
          'partially_verified',
          'verified'
        ];
      }
      return statuses;
    },
    user() {
      return this.$store.getters.loggedInUser;
    },

    lastDocPos() {
      return this.numberOfDocs - 1;
    },

    numberOfDocs() {
      return this.docs.length;
    },

    selectedDoc() {
      const doc = this.docs.find(d => d.id === this.selectedId);
      if (doc) {
        return doc;
      }
      let name = '';
      if (this.numberOfDocs === 0) {
        name = this.type === 'doctypes' && this.$t('docTypes.no_doctypes') || this.$t('docViewer.no_docs');
      } else {
        name = this.type === 'doctypes' && this.$t('docTypes.select_doctype') || this.$t('dataPoints.select_doc');
      }
      return {
        name,
        status: '', id: -1
      };
    },

    displayDocs() {
      return this.docs.map(
        (doc, i) => ({
          ...doc,
          ordinalName: `(${i+1}) ${doc.name}`,
          ordinalPos: i + 1,
      })
      ).slice(this.showStart, this.showEnd);
    },

    width() {
      return this.small ? '275px' : '300px';
    },
  },

  watch: {
    selected() {
      this.selectedId = this.selected;
    },

    selectOn(on) {
      if (!on) {
        this.showStart = 0;
        this.showEnd = 60;
      }
    },
  },

  mounted() {
    this.selectedId = this.selected;
  },

  methods: {
    copyFilename() {
      if (this.selectedId !== -1) {
        navigator.clipboard.writeText(this.selectedDoc.name);
        this.$store.commit(
          'setSuccessMessage', this.$t('documents.filename_copied')
        );
        this.$store.commit('setSuccessSnackbar', true);
      }
    },

    handleScroll() {
      if (!this.$store.getters.loadingScreen
        && !this.loading
        && this.docs.some(d => d.type && d.type === 'placeholder')
      ) {
        this.$emit('getMissing');
      }
      const panelHeight = 280;
      const selectPanel = this.$refs.selectPanel;
      let panelTop = null;
      if (selectPanel) {
        panelTop = selectPanel.getBoundingClientRect().top;
      }

      // we only ever show max 60 options at once to optimize render
      const firstVisibleOptionArr = this.$refs[`Option${this.showStart}`];
      if (firstVisibleOptionArr && firstVisibleOptionArr.length > 0) {
        const firstVisibleOption = firstVisibleOptionArr[0];
        const firstVisibleOptionCurrentPos = firstVisibleOption.getBoundingClientRect().top;
        const directionDown = firstVisibleOptionCurrentPos < this.firstVisibleOptionPrevPos;
        this.firstVisibleOptionPrevPos = firstVisibleOptionCurrentPos;

        if (!directionDown && this.showStart > 0) {
          if (panelTop) {
            if (firstVisibleOptionCurrentPos - panelTop > -panelHeight) {
              this.showStart -= 20;
              this.showEnd -= 20;
            }
          }
        }
        if (directionDown && this.showEnd - 1 < this.lastDocPos) {
          const lastVisibleOptionArr = this.$refs[`Option${this.showEnd - 1}`];
          if (lastVisibleOptionArr && lastVisibleOptionArr.length > 0) {
            const lastVisibleOption = lastVisibleOptionArr[0];
            if (panelTop) {
              const lastVisibleTop = lastVisibleOption.getBoundingClientRect().top;
              if (lastVisibleTop - panelTop < panelHeight) {
                this.showStart += 20;
                this.showEnd += 20;
              }
            }
          }
        }
      }
      const lastOptionArr = this.$refs[`Option${this.lastDocPos}`];
      if (panelTop
        && lastOptionArr
        && lastOptionArr.length > 0
        && !this.$store.getters.loadingScreen && !this.loading
      ) {
        const lastOption = lastOptionArr[0];
        const lastTop = lastOption.getBoundingClientRect().top;
        if (lastTop - panelTop < panelHeight) {
          this.$emit('getMore');
        }
      }
    },

    toggleSelectOn() {
      this.selectOn = !this.selectOn;
      if (this.selectOn) {
        nextTick(() => this.$refs.selectPanel.focus());
      }
    }
  },

  props: {
    docs: {
      type: Array,
      required: true,
    },

    selected: {
      type: Number,
      required: true,
    },

    deleteable: {
      type: Boolean,
      default: false,
    },

    canCopyFilename: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'file',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    showNumber: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: true,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['selectedChanged', 'deleteClick', 'getMore', 'getMissing'],
}
</script>

<style lang="scss" scoped>
.doc-select {
  .doc-selector {
    max-width: 400px;
    margin-top: -3px;
  }

  .custom-select {
    height: 40px;
    border-radius: 4px;
    border: solid 1px #C8C8C8;
    background-color: white;
    padding: 8px;
    padding-right: 19px;
    font-size: 0.9rem;
    position: relative;

    .open-icon {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      color: rgb(var(--v-theme-dark));
      opacity: var(--v-medium-emphasis-opacity);
    }

    .select-panel {
      max-height: 280px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: solid 1px #C8C8C8;
      cursor: pointer;
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .select-panel .option {
      height: 40px;
      padding: 8px;
      background-color: white !important;
      box-shadow: 0 0 10px 100px white inset;
      color: black !important;
    }
  }
}
</style>
