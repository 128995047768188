<template>
  <div class="group-table-display">
    <div
      v-if="hasScroll && !loading"
      ref="topScroll"
      class="top-scroll"
    >
      <div
        v-if="tableWidth"
        class="top-scroll-inner"
        :style="{ width: `${tableWidth + 5}px` }"
      />
    </div>
    <div
      ref="tableContainer"
      class="table-container"
      :style="{ 'margin-top': hasScroll ? '13px' : '10px' }"
    >
      <table ref="groupTable">
        <tr v-if="tableWidth && !loading">
          <th
            v-for="(label, i) in labelHeaders"
            :key="i"
            style="overflow: hidden; max-width: 30px;"
            :style="{
              'border-left': i === 0 ? 'none' : '1px inset #aaa',
              'border-right': i < group.labelDisplayNames.length ? '1px inset #aaa' : 'none',
              'border-top': i < group.labelDisplayNames.length ? '1px inset #aaa' : 'none',
              'border-bottom': i < group.labelDisplayNames.length ? '1px inset #aaa' : 'none',
              'background-color': i < group.labelDisplayNames.length ? 'white' : '#f4f5f9',
            }"
          >
            <ItemName
              v-if="i < group.labelDisplayNames.length"
              :item="{name: label}"
              :clickable="false"
              :show-id="false"
              :fade-in-slow="false"
            />
          </th>
        </tr>
        <tr
          v-for="(subgroup, j) in group.subgroups"
          :ref="`subgroup-${j}`"
          :key="j"
        >
          <td
            v-for="(_, i) in labelHeaders"
            :id="`td-${subgroup.id}-${i}`"
            :key="i"
            :ref="`td-${subgroup.id}-${i}`"
            :class="{ 'td-outer': i < group.labelDisplayNames.length }"
            :style="{
              'border-left': i === 0 ? 'none' : '1px inset #aaa',
              'border-right': i < group.labelDisplayNames.length ? '1px inset #aaa' : 'none',
              'border-bottom': i < group.labelDisplayNames.length ? '1px inset #aaa' : 'none',
              'background-color': i < group.labelDisplayNames.length ? 'white' : '#f4f5f9',
              width: getWidth(subgroup, group.labelDisplayNames.length, i),
            }"
            @click="startEditing(subgroup, group.labelDisplayNames.length, i)"
          >
            <div
              :id="`td-inner-${subgroup.id}-${i}`"
              :ref="`td-inner-${subgroup.id}-${i}`"
              style="min-height: 20px"
              :class="{
                'td-active': (isHighlightedInViewer(subgroup, i) && !editing) || isEditing(subgroup, i)
              }"
            >
              <v-btn
                v-if="i === group.labelDisplayNames.length && subgroup.origin === 'manual'"
                class="small-button inline-middle"
                color="primary"
                @click="e => handleDeleteGroup(e, j)"
              >
                <v-icon size="16">
                  fas fa-trash
                </v-icon>
              </v-btn>
              <div class="td-border-left" />
              <div class="td-border-top" />
              <div class="td-border-right" />
              <div class="td-border-bottom" />
              <div v-if="loading">
                <v-skeleton-loader
                  style="top: -7px"
                  type="text"
                  :width="`${Math.floor(Math.random() * (100 - 50 + 1) + 50)}%`"
                />
              </div>
              <div
                v-else
                :ref="getRef(subgroup, i)"
                @mouseenter="handleHover(subgroup, group.labelDisplayNames.length, i)"
                @mouseleave="$emit('valueHover', null)"
              >
                <GroupTableInput
                  v-if="isEditing(subgroup, i)"
                  :width="valueInputWidth"
                  :height="valueInputHeight"
                  :starting-value="getDisplayValue(subgroup, i)"
                  @finish="$emit('review', null)"
                  @save="value => saveEdit(subgroup, i, value)"
                  @blur="$emit('blur')"
                />
                <div
                  v-else
                  :id="`td-value-${subgroup.id}-${i}`"
                  style="padding-bottom: 3px"
                >
                  {{ getDisplayValue(subgroup, i) }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import _ from 'lodash';

import ItemName from '@/components/common/elements/General/ItemName';
import GroupTableInput from "@/components/extract/elements/Corrections/GroupTableInput";

export default {
  name: 'GroupTableDisplay',

  components: {
    ItemName,
    GroupTableInput,
  },

  data() {
    return {
      savingValue: null,
      renderKey: 10,
      valueInputWidth: '0px',
      valueInputHeight: '0px',
      inputValue: '',
      deleteDialog: false,
      waitForScrollRender: 200,
      waitForRender: 100,
      editFinished: true,
    };
  },

  computed: {
    editing() {
      if (this.reviewingValue.id) {
        return this.reviewingValue.id;
      }
      return 0;
    },

    labelHeaders() {
      if (this.group.subgroups.some(subgroup => subgroup.origin === 'manual')) {
        return [...this.group.labelDisplayNames, ''];
      }
      return [...this.group.labelDisplayNames];
    },

    menuOpen() {
      return this.$store.state.menuOpen;
    },

    tableWidth() {
      this.renderKey;
      this.loading;
      this.editing;
      this.fileId;
      const table = this.$refs.groupTable;
      return table ? table.offsetWidth : 0;
    },

    hasScroll() {
      this.renderKey;
      this.loading;
      this.editing;
      this.fileId;
      this.tableWidth;
      this.editFinished;
      const tableContainer = this.$refs.tableContainer;
      // temporary solution to avoid unnecessary top scrollbar
      return tableContainer ? tableContainer.scrollWidth - 7 > tableContainer.clientWidth : false;
    }
  },

  watch: {
    hasScroll() {
      nextTick(() => {
        this.synchronizeHorizontalScroll();
      });
    },

    viewerHovered(newHovered, oldHovered) {
      if (!_.isEqual(newHovered, oldHovered)
      ) {
        let valueComponentArr = null;
        if (newHovered.id) {
          valueComponentArr = this.$refs[`group_${newHovered.id}`];
        }
        if (valueComponentArr && valueComponentArr.length > 0) {
          const valueComponent = valueComponentArr[0];
          valueComponent.scrollIntoViewIfNeeded();
        }
      }
    },

    menuOpen() {
      this.forceUpdate(350);
    },

    editing(editing) {
      if (!editing) {
        // we wait for the new value to render to recalculate scroll
        setTimeout(() => {
          this.editFinished = true;
        }, this.waitForRender);
      } else {
        this.editFinished = false;
      }
    },
  },

  mounted() {
    nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.forceUpdate(this.waitForRender);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    getRef(subgroup, i) {
      const value = this.getMatchingValue(subgroup, i);
      if (value) {
        return `group_${value.id}`;
      }
      return '';
    },

    getWidth(subgroup, labelsLength, i) {
      if (i < labelsLength) {
        const value = this.getMatchingValue(subgroup, i);
        if (value) {
          return this.editing === value.id ? this.valueInputWidth : 'auto';
        }
        return 'auto';
      }
      return '40px';
    },

    handleDeleteGroup(event, j) {
      event.stopPropagation();
      this.$emit('removeSubgroup', this.group, this.group.subgroups[j]);
    },

    forceUpdate(time) {
      setTimeout(() => {
        this.renderKey++;
      }, time);
    },

    onResize() {
      this.renderKey++;
    },

    async saveEdit(subgroup, i, value) {
      // we display the value to be saved while saving
      this.savingValue = { subgroup, i, value };
      const valueObj = this.getMatchingValue(subgroup, i);
      valueObj.reviewValue = value;
      this.$emit('save', valueObj);
      this.savingValue = null;
    },

    startEditing(subgroup, labelsLength, i) {
      if (i === labelsLength) {
        return;
      }
      const value = this.getMatchingValue(subgroup, i);
      if (!value) {
        this.$emit(
          'addLabel',
          this.group,
          this.group.labelDisplayNames[i],
          subgroup,
        );
      }
      const valueCell = this.$refs[`td-inner-${subgroup.id}-${i}`][0];
      const td = this.$refs[`td-${subgroup.id}-${i}`][0];
      this.valueInputWidth = `${td.offsetWidth}px !important`;
      this.valueInputHeight = value ? `${valueCell.offsetHeight - 5}px` : '18px';
      this.$emit('review', value);
    },

    isEditing(subgroup, i) {
      const value = this.getMatchingValue(subgroup, i);
      if (value) {
        return this.editing === value.id;
      }
      return false;
    },

    isHighlightedInViewer(subgroup, i) {
      const value = this.getMatchingValue(subgroup, i);
      if (!value) {
        return false;
      }
      return value.id === this.viewerHovered.id;
    },

    handleHover(subgroup, labelsLength, i) {
      if (i < labelsLength) {
        const value = this.getMatchingValue(subgroup, i);
        if (value) {
          this.$emit("valueHover", value, subgroup);
        }
      }
    },

    getDisplayValue(subgroup, i) {
      // when saving the given value, display the user input
      if (this.savingValue && this.savingValue.subgroup.id === subgroup.id && this.savingValue.i === i) {
        return this.savingValue.value;
      }
      const value = this.getMatchingValue(subgroup, i);
      if (!value) {
        return '';
      }
      return value.reviewValue;
    },

    getMatchingValue(subgroup, i) {
      return subgroup.values.find(
        value => value.reviewName === this.group.labelDisplayNames[i]
      );
    },

    synchronizeHorizontalScroll() {
      const topScroll = this.$refs.topScroll;
      const tableContainer = this.$refs.tableContainer;
      if (tableContainer && topScroll) {
        tableContainer.addEventListener('scroll', () => {
          topScroll.scrollLeft = tableContainer.scrollLeft;
        });
        topScroll.addEventListener('scroll', () => {
          tableContainer.scrollLeft = topScroll.scrollLeft;
        });
      }
    },
  },

  props: {
    group: {
      type: Object,
      required: true,
    },

    fileId: {
      type: Number,
      default: -1,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    getGroupValues: {
      type: Function,
      required: true,
    },

    viewerHovered: {
      type: Object,
      required: true,
    },

    reviewingValue: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'save',
    'addLabel',
    'removeSubgroup',
    'valueHover',
    'review',
    'blur',
  ],
}
</script>

<style lang="scss" scoped>
.group-table-display {
  margin-top: -10px;
  position: relative;
  .top-scroll {
    position: relative;
    top: 11px;
    height: 16px;
    overflow-y: hidden;
    border: 1px inset #aaa;
    border-bottom: none;

    .top-scroll-inner {
      height: 10px;
      opacity: 0;
    }
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
    border-left: 1px inset #aaa;
  }

  table, th, td {
    border: none;
    border-collapse: collapse;
    padding: 5px;
    font-size: 0.9rem;
  }

  table {
    min-width: 100%;
    border-left: none;
    border-right: none;
  }

  .td-outer {
    position: relative;
    cursor: text;
    min-width: 30px;
    word-wrap: break-word;
  }

  .td-border-left, .td-border-top, .td-border-right, .td-border-bottom {
    position: absolute;
  }
  .td-border-left, .td-border-top, .td-border-bottom {
    left: 0px;
  }
  .td-border-left, .td-border-top, .td-border-right {
    top: 0px;
  }
  .td-border-left, .td-border-right, .td-border-bottom {
    bottom: 0px;
  }
  .td-border-top, .td-border-right, .td-border-bottom {
    right: 0px;
  }
  .td-border-left, .td-border-right {
    width: 0px;
  }
  .td-border-top, .td-border-bottom {
    height: 0px;
  }
  .td-active .td-border-left, .td-outer:hover .td-border-left {
    border-left: 2px solid rgb(var(--v-theme-primary));
  }
  .td-active .td-border-top, .td-outer:hover .td-border-top {
    border-top: 2px solid rgb(var(--v-theme-primary));
  }
  .td-active .td-border-right, .td-outer:hover .td-border-right {
    border-right: 2px solid rgb(var(--v-theme-primary));
  }
  .td-active .td-border-bottom, .td-outer:hover .td-border-bottom {
    border-bottom: 2px solid rgb(var(--v-theme-primary));
  }
}
</style>
