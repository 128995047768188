<template>
  <div class="create-dp align-center top-gap">
    <v-text-field
      ref="nameField"
      v-model="DPName"
      class="right-gap filter-items inline-middle"
      style="width: 280px !important"
      variant="outlined"
      color="primary"
      density="compact"
      :placeholder="$t('dataPoints.type_name')"
      @keydown.enter="addNew"
    />
    <v-btn
      class="inline-middle"
      color="primary"
      style="margin-top: -20px"
      :disabled="DPName.trim().length < 1"
      @click="addNew"
      rounded
    >
      {{ $t('save') }}
    </v-btn>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';

export default {
  name: 'CreateDP',

  data() {
    return ({
      DPName: '',
    });
  },

  mounted() {
    setTimeout(() => {
      const nameField = this.$refs[`nameField`];
      if (nameField) {
        nameField.focus();
      }
    }, 10);
  },

  methods: {
    async addNew() {
      try {
        this.$store.commit('setLoadingScreen', true);
        const {data: id} = await http.post(
          `system_2/data_point/${this.$route.params.id}`,
          { name: this.DPName.trim() }
        );
        const responseGet = await http.get(`system_2/data_point/${id}`);
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.created'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('create', responseGet.data);
      } catch (error) {
        // pass
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },

  emits: ['create'],
}
</script>
