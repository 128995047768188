<template>
  <div class="configure-workflow default-padding py-7">
    <BreadcrumbComponent />
    <div
      v-if="id === 'new'"
      class="align-center"
      style="margin-top: 32px !important"
    >
      <v-text-field
        ref="nameField"
        v-model="newName"
        class="right-gap filter-items inline-middle"
        style="width: 280px !important"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('workflows.type_name')"
        @keydown.enter="addNew"
      />
      <v-btn
        class="inline-middle"
        color="primary"
        style="margin-top: -20px"
        :disabled="newName.trim().length < 1"
        @click="addNew"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>
    <div v-else-if="workflow.id !== -1">
      <HeaderName
        class="bottom-gap-sm right-gap inline-middle"
        style="max-width: 500px"
        :item="workflow"
        :editable="workflow.status === 'draft'"
        @save="saveName"
      />
      <MaxWidthChip
        v-if="workflow.status === 'draft'"
        class="inline-middle"
        :text-array="[$t('workflows.draft')]"
      />
      <div
        v-else
        class="info-box inline-top"
      >
        <small>
          <div
            class="inline-middle"
            style="width: 30px"
          >
            <v-icon
              class="info-icon"
              size="16"
            >
              fas fa-info-circle
            </v-icon>
          </div>
          <div
            v-if="workflow.status === 'published'"
            class="inline-middle"
            style="width: calc(100% - 30px)"
          >
            {{ $t('workflows.published_message') }}
          </div>
          <div
            v-if="workflow.status === 'active'"
            class="inline-middle"
            style="width: calc(100% - 30px)"
          >
            {{ $t('workflows.active_message') }}
          </div>
        </small>
      </div>
      <WorkflowEditor :workflow="workflow" />
    </div>
  </div>
</template>

<script>
import { WorkflowAPI } from '@/API/workflows/WorkflowAPI';

import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import HeaderName from '@/components/common/elements/General/HeaderName';
import WorkflowEditor from '@/components/extract/views/Workflows/WorkflowEditor';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";

export default {
  name: 'ConfigureWorkflow',

  components: {
    BreadcrumbComponent,
    HeaderName,
    WorkflowEditor,
    MaxWidthChip,
  },

  data() {
    return ({
      newName: '',
      workflow: {
        id: -1,
      },
      id: -1,
      inputComplete: false,
      processingComplete: false,
    });
  },

  computed: {
    isComplete() {
      return this.inputComplete && this.processingComplete;
    }
  },

  watch: {
    id(id) {
      const breadcrumbPath = [
        { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
        { title: this.$t('menu.studio') },
        { title: this.$t('menu.workflows.title'), href: {name: 'Workflows'} },
      ];
      let current = { title: this.$t('breadcrumb.configuration') }
      if (id === 'new') {
        current = { title: this.$t('workflows.create') };
        setTimeout(() => {
          const nameField = this.$refs[`nameField`];
          if (nameField) {
            nameField.focus();
          }
        }, 10);
      }
      breadcrumbPath.push(current);
      this.$store.commit('setBreadcrumb', breadcrumbPath);
    }
  },

  mounted() {
    this.id = this.$route.params.id;
    if (this.id !== 'new') {
      this.getWorkflow(this.id);
    }
  },

  methods: {
    async getWorkflow(id) {
      this.$store.commit('setLoadingScreen', true);
      this.workflow = await WorkflowAPI.getWorkflow(id);
      this.$store.commit('setLoadingScreen', false);
    },

    async saveName(newName) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          await WorkflowAPI.updateWorkflow(this.workflow.id, { name: newName });
          this.workflow.name = newName;
          await this.$store.commit(
            'setSuccessMessage', this.$t('workflows.renamed')
          );
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          // pass
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },

    async addNew() {
      try {
        this.$store.commit('setLoadingScreen', true);
        const workflow = await WorkflowAPI.createWorkflow({
          name: this.newName.trim(),
          org_id: this.$store.getters.loggedInUser.org_id,
        });
        this.workflow = workflow;
        this.id = this.workflow.id;
        this.$store.commit('setSuccessMessage', this.$t('workflows.created'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        // pass
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.configure-workflow {
  height: fit-content;
}

.default-padding {
  padding: 0 60px 25px 60px;
}

.info-box {
  background-color: rgb(var(--v-theme-primary-lighten2));
  border-radius: 6px;
  padding: 6px 17px;
  padding-bottom: 10px;
  max-width: 450px;

  .info-icon {
    margin-right: 2px;
    top: -1px;
  }
}
</style>
