import { i18n } from '@/plugins/i18n/i18n';

const locale = i18n.global;

export const formatElapsedTime = (seconds) => {
    if (typeof seconds !== 'number') {
      return '—';
    }
    const elapsedTime = {
      years: Math.floor(seconds / 217728000),
      months: Math.floor(seconds / 18144000),
      weeks: Math.floor(seconds / 604800),
      days: Math.floor(seconds / 86400),
      hours: Math.floor(seconds / 3600),
      minutes: Math.floor(seconds / 60),
      seconds,
    };
  
    // Get the longest period of time
    const [unit, time] = Object.entries(elapsedTime)
      .filter(([, value], index, self) => value !== 0 || index === self.length - 1) // Always keep seconds 
      .shift();
    
    return `
      ${time} ${locale.tc(`search.tasks.${unit}`, time)}
    `;
  };

export const formatDate = (dateInfo) => {
  if (!dateInfo) {
    return '';
  }
  const date = new Date(dateInfo);
  date.setTime(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return `${date.toLocaleDateString('en-GB', options)} ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`;
};
