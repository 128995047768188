<template>
  <div class="page-container">
    <div class="mt-2 page-number">
      p. {{ page.page_number }}
    </div>
    <div
      v-if="image"
      class="page background-spread clickable"
      :style="{
        width: `${pageWidth}px`,
        height: `${pageHeight}px`,
        'background-image': `url(${image})`,
      }"
      :class="{ selected }"
      @click="$emit('select', page)"
    />
    <div
      v-else
      class="page"
      :style="{
        'width': `${baseWidth}px`,
        'padding-top': `${baseWidth * 1.33}px`,
        'background-color': '#f5f5f5',
        'color': '#bdbdbd'
      }"
    >
      <v-icon
        v-if="loaded"
        class="error-icon"
      >
        fas fa-file-image
      </v-icon>
      <div
        v-if="loaded"
        class="error-message"
      >
        {{ $t('workflows.page_correction.image_not_found') }}
      </div>
    </div>
    <div v-if="!hideCategories">
      <v-tooltip
        ref="popover"
        :open-on-focus="false"
        close-on-content-click
      >
        <template #activator="{ props }">
          <v-select
            :id="`page${page.id}`"
            ref="select"
            v-bind="props"
            v-model="page.category_id"
            class="page-type-chip inline-middle"
            item-title="name"
            item-value="id"
            density="compact"
            :width="baseWidth"
            :items="categories"
            :menu-props="{
              modelValue: openMenu
            }"
            @click="openMenu = !openMenu"
            @update:model-value="updatePageCategory"
          />
        </template>
        {{ categoryMap[page.category_id] }}
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { ClassifyDocumentsAPI } from '@/API/classify/ClassifyDocumentsAPI';

export default {
  name: 'FilePage',

  data() {
    return {
      baseWidth: 150,
      image: '',
      pageKey: 0,
      loaded: false,
      openMenu: false,
    }
  },

  computed: {
    ratio() {
      if (this.page && this.page.thumbnail_width && this.page.thumbnail_height) {
        return this.page.thumbnail_height / this.page.thumbnail_width;
      }
      return 1;
    },

    isPortrait() {
      return this.ratio > 1;
    },

    pageHeight() {
      const ratio = this.ratio;
      return this.baseWidth * ratio;
    },

    pageWidth() {
      const ratio = this.ratio;
      if (this.isPortrait) {
        return this.pageHeight / ratio;
      }
      return this.baseWidth;
    },
  },

  async mounted() {
    if (this.page) {
      await this.getImage();
    }
    this.loaded = true;
  },

  methods: {
    async getImage() {
      try {
        const data = await ClassifyDocumentsAPI.getPageThumbnail(
          this.$route.params.id,
          this.page.id,
        );
        this.image = URL.createObjectURL(new Blob([data]));
      } catch (error) {
        // pass
      }
    },

    async updatePageCategory(category_id) {
      this.page.category_id = category_id;
      this.$refs.popover.isActive = false;
      this.openMenu = false;
      this.$emit('update:pageCategory', {
        page_id: this.page.id,
        category_id,
      });
    },
  },

props: {
  page: {
    type: Object,
    required: true,
  },
  selected: {
    type: Boolean,
    default: true,
  },
  hideCategories: {
    type: Boolean,
    default: false,
  },
  categories: {
    type: Array,
    required: false,
    default: () => [],
  },
  categoryMap: {
    type: Object,
    required: false,
    default: () => {},
  },
},

  emits: ['update:pageCategory', 'select'],
}
</script>
<style lang="scss" scoped>
  .error-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .error-message {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 10px;
    line-height: 0.75rem;
  }

  .page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;
  }

  .page {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    position: relative;
  }

  .page-number {
    text-align: left !important;
    width: 100%;
    margin-bottom: 2px;
    color: #747474;
    font-size: 12px;
  }

  .page-type-chip {
    margin-top: 15px;
    cursor: pointer !important;
  }

  .background-spread {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .selected {
    outline: solid 3px var(--v-primary-base);
    outline-offset: -3px;
  }
</style>
