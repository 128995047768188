<template>
  <div class="profile-settings">
    <div
      v-if="profile"
      class="top-gap d-flex settings-body"
    >
      <div class="settings-left">
        <h4>{{ $t('settings.profile') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.profile_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <div class="label text-field-label">
          {{ $t('forms.email') }}
        </div>
        <div
          class="bottom-gap-sm"
          style="margin-top: -7px"
        >
          {{ profile.email }}
        </div>

        <div class="label radio-group-label">
          {{ $t('settings.language') }}
        </div>
        <v-radio-group
          v-model="profile.language"
          @change="updateProfile('language')"
          inline
        >
          <div
            v-for="(language, i) in languages"
            :key="i"
            class="inline-middle radio-box right-gap-sm"
          >
            <v-radio
              :label="$t(`settings.${language.name}`)"
              :value="language.code"
            />
          </div>
        </v-radio-group>

        <div class="label text-field-label">
          {{ $t('forms.firstName') }}
        </div>
        <v-text-field
          v-model="profile.first_name"
          class="inline-middle mt-0"
          style="width: 500px"
          variant="outlined"
          color="primary"
          density="compact"
          @keyup.enter="updateProfile('first_name')"
        />

        <div class="label text-field-label">
          {{ $t('forms.lastName') }}
        </div>
        <v-text-field
          v-model="profile.last_name"
          class="inline-middle mt-0"
          style="width: 500px"
          variant="outlined"
          color="primary"
          density="compact"
          @keyup.enter="updateProfile('last_name')"
        />

        <v-btn
          v-if="$store.getters.product !== ''"
          style="box-shadow: none;"
          target="_blank"
          color="primary"
          variant="outlined"
          @click="$router.push(`/${$store.getters.product}/change-password`)"
          rounded
        >
          <v-icon
            size="16"
            start
          >
            fas fa-key
          </v-icon>
          {{ $t('account.changePassword.name') }}
        </v-btn>
      </div>
    </div>
    <div
      class="top-gap settings-body"
      style="text-align: right"
    >
      <v-btn
        color="primary"
        style="margin-left: -10px"
        @click="updateProfile()"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { UserAPI } from '@/API/authenticator/UserAPI';
import { MenuClass } from '@/utils/MenuClass';

export default {
  name: 'ProfileSettings',

  data() {
    return ({
      profile: null,
      languages: [
        {name: 'english', code: 'en'},
        {name: 'french', code: 'fr'},
      ],
      languageRadio: 'en',
    });
  },

  computed: {
    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  created() {
    this.getProfile();
  },

  methods: {
    getProfile() {
      this.profile = {...this.user};
    },

    async updateProfile(attribute = null) {
      // We need this so that the success message renders in the updated language
      const languageUpdateDelay = attribute === 'language' ? 10 : 0;
      try {
        let update = {};
        if (attribute) {
          // Only update the attribute that was changed
          const updateValue = this.profile[attribute];
          this.getProfile();
          this.profile[attribute] = updateValue;
          update[attribute] = updateValue;
        } else {
          // On save click we update everything
          update = this.profile;
        }
        await UserAPI.editProfile(update);
        this.$i18n.locale = this.profile.language;
        this.$store.commit('login', {...this.profile});
        this.$store.commit('setBreadcrumb', [
          { title: this.$t('breadcrumb.home'), href: {name: 'Home'} },
          { title: this.$t('breadcrumb.settings') },
          { title: this.$t('menu.general') },
        ]);
        this.$store.commit('setMenuGroups', MenuClass.buildMenuSidebarItems());
        setTimeout(() => {
          this.$store.commit('setSuccessMessage', this.$t('settings.update_success'));
          this.$store.commit('setSuccessSnackbar', true);
        }, languageUpdateDelay);
      } catch (err) {
        // pass
      }
    },
  },
}
</script>
