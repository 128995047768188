<template>
  <v-dialog
    v-model="openDialog"
    max-width="600"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @keydown.enter="validate()"
  >
    <v-card class="dialog-card">
      <v-icon
        class="close-icon"
        size="17"
        @click="$emit('close')"
      >
        fas fa-times
      </v-icon>
      <v-form ref="form">
        <h2 class="dialog-title mb-3">
          {{ $t('organizations.edit.title') }}
        </h2>
        <div class="label">
          {{ $t('forms.name') }}
        </div>
        <v-text-field
          v-model="form.name"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="commonFieldRules"
          :error-messages="errorsMessages.name"
        />
        <div class="top-gap label">
          {{ $t('organizations.products') }}
        </div>
        <div class="d-flex">
          <v-checkbox
            v-for="product in products"
            :key="product.value"
            v-model="form.products"
            style="margin-top: -9px"
            color="primary"
            :value="product.value"
            :label="product.label"
            :disabled="product.disabled"
          />
        </div>
      </v-form>
      <div class="mt-8 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="$emit('close')"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            @click="validate()"
            block
            rounded
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { http } from '@/plugins/axios';

import FormRules from '@/utils/classes/FormRules/FormRules';

export default {
  name: 'EditOrgDialog',

  data() {
    return ({
      commonFieldRules: [FormRules.required],
      emailFieldRules: [FormRules.emailLocal],
      valid: true,
      errorsMessages: {},
      extractDisabled: false,
      searchDisabled: false,
      openDialog: this.modelValue,
      form: {
        name: '',
        products: [],
      },
    });
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },

    editedOrg(org) {
      if (!!org.name && !!org.products) {
        this.form.name = org.name;
        this.form.products = org.products;
      }
    },
  },

  created() {
    this.products = [
      { value: 'extract', label: 'Extract', disabled: this.extractDisabled },
      { value: 'search', label: 'Search', disabled: this.searchDisabled },
      { value: 'classify', label: 'Classify', disabled: this.classifyDisabled },
      { value: 'workflows', label: 'Workflows', disabled: false }
    ];
  },

  mounted() {
    this.setProduct();
  },

  methods: {
    setProduct() {
      if (this.$store.getters.product === 'suite') {
        this.extractDisabled = true;
      } else if (this.$store.getters.product === 'search') {
        this.searchDisabled = true;
      } else if (this.$store.getters.product === 'classify') {
        this.classifyDisabled = true;
      }
    },

    resetForm() {
      this.form = {
        name: '',
        products: [],
      };
      this.setProduct();
    },

    validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.editOrg();
      }
    },

    async editOrg() {
      try {
        this.$store.commit('setLoadingScreen', true);
        const update = {...this.form};
        await http.put(
          `auth/api/v1/organizations/${this.editedOrg.id}`,
          update,
        );
        this.$store.commit(
          'setSuccessMessage',
          this.$t('organizations.edit.success')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('refresh', {
          name: this.form.name,
          products: this.form.products,
        });
        this.$emit('close');
      } catch (error) {
        // pass
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    editedOrg: {
      type: Object,
      required: true,
    },
  },

  emits: ['close', 'refresh', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}

.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
