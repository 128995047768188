<template>
  <div class="correction-docs page-padding py-7">
    <div
      v-if="type.name === ''"
      style="height: 42px"
    />
    <BreadcrumbComponent v-else />
    <v-skeleton-loader
      v-if="type.name === ''"
      style="margin-top: 54px"
      type="heading"
      width="300px"
    />
    <h2
      v-else
      class="text-h2 primary--text my-8"
    >
      {{ type.name }}
    </h2>
    <div class="files-table">
      <TableActions
        type="correctionDocs"
        :number-of-selected="selected.length"
        :edit-condition="selected.every(f => !['uploaded', 'ingested'].includes(f.status))"
        @delete-click="deleteDialog = true"
        @filter-change="(filter) => trimmedFilter = filter"
        @reprocess-click="handleRerun"
      />
      <TableWithFooter
        :loading="loading"
        :paginated-items-length="paginatedFiles.length"
        :total="totalFiles"
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
        @change-page="(page) => currentPage = page"
      >
        <template #header>
          <v-col cols="auto">
            <SortButton v-model="sortDesc" />
            <v-checkbox
              v-model="allSelected"
              class="mt-0"
              hide-details
            />
          </v-col>
          <v-col cols="5">
            {{ $t('datatable.header.docName') }}
          </v-col>
          <v-col cols="2">
            {{ $t('datatable.header.size') }}
          </v-col>
          <v-col cols="2">
            {{ $t('datatable.header.addDate') }}
          </v-col>
          <v-col cols="2">
            {{ $t('datatable.header.ProcessDate') }}
          </v-col>
        </template>
        <template #body>
          <v-container
            class="pa-0"
            fluid
          >
            <v-row
              v-for="item in paginatedFiles"
              :key="item.id"
              class="table-row fade-in table-row-height"
            >
              <v-col cols="auto">
                <v-checkbox
                  v-model="item.selected"
                  class="left-gap"
                  @change="handleSelect"
                  hide-details
                />
              </v-col>
              <v-col
                v-if="!['uploaded', 'ingested', 'error'].includes(item.status)"
                cols="5"
                @click="validate(item.id)"
              >
                <ItemName
                  :key="item.id"
                  style="z-index: 202"
                  type="file"
                  :item="item"
                />
              </v-col>
              <v-col
                v-else-if="item.status === 'error'"
                cols="5"
              >
                <v-tooltip
                  :key="renderKey"
                  bottom
                >
                  <template #activator="{ props }">
                    <v-icon
                      style="margin-right: 5px;"
                      color="primary"
                      size="16"
                      v-bind="props"
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </template>
                  {{ $t('docTypes.doc_processing_failed') }}
                </v-tooltip>
                <ItemName
                  :key="item.id"
                  style="max-width: calc(100% - 21px);"
                  type="file"
                  :item="item"
                  :editing-allowed="false"
                  :clickable="false"
                />
              </v-col>
              <v-col
                v-else
                cols="5"
              >
                <v-tooltip right>
                  <template #activator="{ props }">
                    <v-icon
                      style="margin-right: 5px;"
                      color="primary"
                      size="16"
                      v-bind="props"
                    >
                      fas fa-spinner fa-pulse
                    </v-icon>
                    <ItemName
                      :key="item.id"
                      style="max-width: calc(100% - 21px);"
                      type="file"
                      :item="item"
                      :editing-allowed="false"
                      :clickable="false"
                    />
                  </template>
                  {{ $t('docTypes.being_processed') }}
                </v-tooltip>
              </v-col>
              <v-col cols="2">
                {{ formatSize(item.size) }}
              </v-col>
              <v-col cols="2">
                <small class="gray--text">
                  {{ formatDate(item.uploaded_at) }}
                </small>
              </v-col>
              <v-col cols="2">
                <small class="gray--text">
                  {{ formatDate(item.dt_processed_at) }}
                </small>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </TableWithFooter>
      <DeleteDialog
        v-model="deleteDialog"
        :title="$t('datatable.delete_file')"
        :message="$t('datatable.deleteConfirmation')"
        @confirm="deleteFiles"
        @close="deleteDialog = false"
      />
      <ProgressDialog
        v-model="progressDialog"
        :all-delete="allDelete"
        :current-delete="currentDelete"
      />
    </div>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import _ from 'lodash';

import BreadcrumbComponent from '@/components/common/elements/Navigation/BreadcrumbComponent';
import DeleteDialog from '@/components/common/elements/Tables/DeleteDialog';
import ItemName from '@/components/common/elements/General/ItemName';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import ProgressDialog from '@/components/common/elements/Tables/ProgressDialog';

import format_mixin from '@/mixins/format.js';

export default {

  name: 'CorrectionDocs',

  mixins: [format_mixin],

  components: {
    BreadcrumbComponent,
    DeleteDialog,
    ItemName,
    TableActions,
    TableWithFooter,
    SortButton,
    ProgressDialog,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      allDelete: 0,
      currentDelete: 0,
      deleteDialog: false,
      forceCompute: Math.random(),
      progressDialog: false,
      paginatedFiles: [],
      totalFiles: 0,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    loading: {
      get: function() {
        return this.$store.getters.loadingScreen;
      },
      set(value) {
        this.$store.commit('setLoadingScreen', value);
      }
    },

    allSelected: {
      get() {
        if (this.paginatedFiles.length > 0) {
          return this.paginatedFiles.every(file => file.selected);
        }
        return false;
      },
      set(allSelected) {
        const selected = this.paginatedFiles.map(file => {
          file.selected = allSelected;
          return file;
        });
        this.paginatedFiles = selected;
      }
    },

    selected: {
      get() {
        if (this.paginatedFiles.length > 0) {
          return this.paginatedFiles.filter(f => f.selected);
        }
        return [];
      },
      set() {
        return this.selected;
      }
    },

    trimmedFilter: {
      get() {
        return this.$store.getters.nameFilter['correctionDocs'];
      },
      set(nameFilter) {
        this.$store.commit('setNameFilter', {
          key: 'correctionDocs',
          nameFilter,
        });
      },
    },

    type: {
      get() {
        this.forceCompute;
        const type = this.$store.getters.correctionTypes.find(item => item.id === parseInt(this.$route.params.id));
        if (type) {
          return type;
        }
        return { id: -1, name: '', nb_prod_files_to_validate: 0 };
      },
      set(newType) {
        if (!this.$store.getters.correctionTypes.includes(newType)) {
          this.$store.commit('setCorrectionTypes', [
            ...this.$store.getters.correctionTypes,
            newType,
          ]);
        }
      },
    },
  },

  watch: {
    sortDesc() {
      this.getCorrectionFiles();
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getCorrectionFiles()
    },

    currentPage(page) {
      this.getCorrectionFiles((page - 1) * this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      async function() {
        this.currentPage = 1;
        await this.getCorrectionFiles()
      }, 300
    ),
  },

  async mounted() {
    this.trimmedFilter = '';
    if (this.type.id < 0) {
      await this.getCorrectionType();
    }
    this.$store.commit('setBreadcrumb',
      [
        { title: this.$t('breadcrumb.home'), href: {name: 'Suite'} },
        { title: this.$t('menu.production') },
        {
          title: this.$t('menu.corrections.title'),
          href: {name: 'Corrections'}
        },
        { title: this.type.name },
      ]
    );
    this.currentPage = 1;
    await this.getCorrectionFiles();
    this.forceCompute = Math.random();
  },

  methods: {

    handleSelect() {
      this.paginatedFiles = [...this.paginatedFiles];
    },

    async handleRerun() {
      const total = this.selected.length;
      for (let i = 0; i < total; i++) {
        await http.post(`production/files/${this.selected[i].id}/rerun/system1/`);
      }
      this.$store.commit('setSuccessMessage', this.$t('docTypes.reprocessing_started'));
      this.$store.commit('setSuccessSnackbar', true);
      this.paginatedFiles.forEach(item => {
        item.selected = false;
      });
      this.allSelected = false;
    },

    async deleteFile(id) {
      try {
        await http.delete(`production/files/${id}`);
      } catch (error) {
        return
      }
    },

    async deleteFiles() {
      const total = this.selected.length;
      this.deleteDialog = false;
      this.allDelete = total;
      this.progressDialog = true;
      for (let i = 0; i < total; i++) {
        this.currentDelete = i + 1;
        await this.deleteFile(this.selected[i].id);
      }
      this.totalFiles -= total;
      this.forceCompute = Math.random();
      this.progressDialog = false;
      await this.getCorrectionFiles();
      const lastPage = Math.max(1, Math.ceil(this.totalFiles / this.itemsPerPage));
      this.currentPage = Math.min(this.currentPage, lastPage);
      this.allSelected = false;
      this.$store.commit('setSuccessMessage', `${this.$t('docTypes.files.deleted')} (${total}).`);
      this.$store.commit('setSuccessSnackbar', true);
    },

    async getCorrectionFiles(offset = (this.currentPage - 1) * this.itemsPerPage,) {
      try {
        this.loading = true;
        const response = await http.get(
          `production/files/`,
          {
            params: {
              doctype_id: this.$route.params.id,
              only_unprocessed: true,
              name_filter: this.trimmedFilter,
              offset,
              limit: this.itemsPerPage,
              sort_desc: this.sortDesc,
            },
          },
        );
        this.paginatedFiles = response.data;
        this.totalFiles = parseInt(response.headers['x-total-count']);
        this.forceCompute = Math.random();
      } catch (error) {
        // pass
      } finally {
        this.loading = false;
      }
    },

    async getCorrectionType() {
      try {
        this.loading = true;
        const response = await http.get(`system_2/verify/document_type/${this.$route.params.id}/`);
        this.type = response.data;
        this.forceCompute = Math.random();
      } catch (error) {
        // pass
      } finally {
        this.loading = false;
      }
    },

    refreshFiles(fileList) {
      this.paginatedFiles = fileList;
      this.forceCompute = Math.random();
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
    },

    async validate(fileId) {
      try {
        await http.post(`system_2/verify/start_verification/${fileId}/`);
        this.$router.push({
          name: 'DocCorrections',
          params: {
            id: this.$route.params.id,
            fileId,
          },
        });
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
  .correction-docs {
    height: fit-content;
  }
</style>
