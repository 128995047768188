<template>
  <div class="welcome-view page-padding py-15">
    <div class="welcome-box">
      <h2 class="text-h2 bottom-gap-lg">
        {{ $t('hello') }}
        <span style="text-transform: capitalize">
          {{ $store.getters.loggedInUser.first_name }},
        </span>
      </h2>
      <h3
        class="text-h3 bottom-gap-lg"
        style="color: black !important"
      >
        {{ $t('home.you_currently_have') }}
      </h3>
      <div v-if="$store.getters.loggedInUser.role != 'basic'">
        <ResourceCountCard
          v-for="(resource, resourceIndex) in availableResources"
          :key="resourceIndex"
          :name="resource.name"
          :count="counts[resourceIndex]"
          :configure-path="resource.configurePath"
          :create-path="resource.createPath"
        />
      </div>
      <div v-else>
        <div class="bottom-gap-lg">
          <h2
            v-if="productionCount !== -1"
            class="text-h2 inline-middle right-gap-sm"
          >
            {{ productionCount }}
          </h2>
          <v-skeleton-loader
            v-else
            class="inline-middle mt-n3 ms-n3"
            type="heading"
            width="90px"
          />
          <h3
            class="text-h3 inline-middle"
            style="color: black !important"
          >
            {{ $tc('home.docs_to_review', productionCount) }}
          </h3>
        </div>
        <v-btn
          v-if="productionCount > 0"
          color="primary"
          @click="$router.push('/suite/production/review')"
          rounded
        >
          {{ $t('home.get_started') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/plugins/axios';

import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { ModelAPI } from '@/API/extract/ModelAPI';
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';
import { ClassifyDocumentsAPI } from '@/API/classify/ClassifyDocumentsAPI';

import ResourceCountCard from '@/components/extract/elements/Stats/ResourceCountCard';

export default {
  name: 'WelcomeView',

  components: {
    ResourceCountCard,
  },

  data() {
    return ({
      resources: [
        {
          name: 'datasets',
          service: 'extract',
          roles: ['orgadmin'],
          configurePath: '/suite/studio/datasets',
          createPath: '/suite/studio/datasets/create',
          getCount: this.getDatasetCount,
        },
        {
          name: 'classification_models',
          service: 'classify',
          roles: ['orgadmin'],
          configurePath: '/suite/studio/models/classification',
          createPath: '/suite/studio/models/classification/training',
          getCount: this.getClassifierCount,
        },
        {
          name: 'extraction_models',
          service: 'extract',
          roles: ['orgadmin'],
          configurePath: '/suite/studio/models/extraction',
          createPath: '/suite/studio/models/extraction/training',
          getCount: this.getExtractionModelCount,
        },
        {
          name: 'extraction_agents',
          service: 'extract',
          roles: ['orgadmin', 'sysadmin'],
          configurePath: '/suite/studio/agents/extraction',
          createPath: '/suite/studio/agents/extraction/create',
          getCount: this.getExtractionAgentCount,
        },
        {
          name: 'extractions_to_review',
          service: 'extract',
          roles: ['orgadmin'],
          configurePath: '/suite/production/review',
          createPath: '/suite/production/review',
          getCount: this.getProductionCount,
        },
        {
          name: 'classifications_to_review',
          service: 'classify',
          roles: ['orgadmin'],
          configurePath: '/suite/production/review',
          createPath: '/suite/production/review',
          getCount: this.getDocumentsCount,
        },
        {
          name: 'workflows',
          service: 'workflows',
          roles: ['orgadmin'],
          configurePath: '/suite/studio/workflows',
          createPath: '/suite/studio/workflows/configure/new',
          getCount: this.getWorkflowCount,
        },
      ],
      counts: [],
      productionCount: -1,
    });
  },

  computed: {
    availableResources() {
      return (
        this.resources.filter(
          r => r.roles.includes(this.$store.getters.loggedInUser.role) && this.$store.getters.serviceStatus[r.service].running
        )
      );
    }
  },

  mounted() {
    this.counts = new Array(this.availableResources.length).fill(-1);
    if (this.$store.getters.loggedInUser.role === 'basic') {
      this.getProductionCount();
    } else {
      this.availableResources.forEach((resource, i) => {
        resource.getCount(i);
      });
    }
  },

  methods: {
    async getDocumentsCount(resourceIndex) {
      try {
        const response = await ClassifyDocumentsAPI.get();
        this.counts[resourceIndex] = parseInt(response.headers['x-total-count'], 10);
      } catch (err) {
        this.counts[resourceIndex] = 0;
      }
    },

    async getProductionCount(resourceIndex = 0) {
      try {
        const response = await http.get(
          `production/files/`,
          {
            params: {
              offset: 0,
              limit: 1,
              only_unprocessed: true,
            },
          },
        );
        this.productionCount = parseInt(response.headers['x-total-count'], 10);
        this.counts[resourceIndex] = this.productionCount;
      } catch (err) {
        console.log(err);
        this.productionCount = 0;
        this.counts[resourceIndex] = 0;
      }
    },

    async getDatasetCount(resourceIndex) {
      try {
        const response = await DatasetAPI.get(0, 1);
        this.counts[resourceIndex] = parseInt(response.headers['x-total-count'], 10);
      } catch (err) {
        console.log(err);
        this.counts[resourceIndex] = 0;
      }
    },

    async getClassifierCount(resourceIndex) {
      try {
        /* Here we are only interested in the total, that's why we set the limit to 1. */
        const response = await ClassifyModelAPI.getClassificationModels({
          limit: 1,
          hasTrained: true
        });
        const totalClassifiers = parseInt(response.headers['x-total-count'], 10);

        this.counts[resourceIndex] = totalClassifiers;
      } catch (error) {
        console.log(error);
        this.counts[resourceIndex] = 0;
      }
    },

    async getExtractionModelCount(resourceIndex) {
      try {
        const response = await ModelAPI.get(0, 1);
        this.counts[resourceIndex] = parseInt(response.headers['x-total-count'], 10);
      } catch (err) {
        console.log(err);
        this.counts[resourceIndex] = 0;
      }
    },

    async getExtractionAgentCount(resourceIndex) {
      try {
        const response = await DocTypeAPI.get(1, 0);
        this.counts[resourceIndex] = parseInt(response.headers['x-total-count'], 10);
      } catch (err) {
        console.log(err);
        this.counts[resourceIndex] = 0;
      }
    },

    async getWorkflowCount(resourceIndex) {
      try {
        const response = await http.get(
          `workflows/api/v1/workflows/by-uuid`,
          {
            params: {
              limit: 1,
              offset: 0,
            },
          }
        );
        this.counts[resourceIndex] = parseInt(response.headers['x-total-count'], 10);
      } catch (err) {
        console.log(err);
        this.counts[resourceIndex] = 0;
      }
    },
  }
}
</script>
