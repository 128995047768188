<template>
  <div class="label-group-view">
    <div class="divided-config-page-left">
      <div class="d-flex">
        <BackLink :target="backTarget" />
        <DocSelect
          v-if="$store.getters.loggedInUser.role === 'orgadmin'"
          style="margin-left: auto"
          :docs="files"
          :selected="selectedFile"
          @selected-changed="selectFileById"
          @get-more="getFiles(numberOfFiles)"
        />
      </div>
      <div style="padding: 7px 10px 0px 30px">
        <ConfigureDPGroup
          v-if="edited.id > -1"
          class="fade-in"
          :group="edited"
          @update-extraction-type="(category) => edited.category = category"
          @update-labels="(labels) => edited.labels = labels" 
          @update-primary="updatePrimary"
          @update-description="(description) => edited.description = description"
          @name-save="savedChanges = true"
        />
        <CreateDPGroup
          v-else-if="!$route.params.itemId"
          @create="createdGroup"
        />
      </div>
      <div v-if="edited.id > -1">
        <div
          v-if="$store.getters.loggedInUser.role === 'sysadmin'"
          style="padding-left: 30px"
        >
          <v-btn
            style="box-shadow: none"
            color="primary"
            @click="saveGroup"
            rounded
          >
            {{ $t('save') }}
          </v-btn>
        </div>
        <ExtractionResult
          v-if="edited.id > -1"
          ref="extractionResult"
          :values="viewFileHasValues ? viewFile.group_values[edited.id] : []"
          :is-valid="edited && edited.primary_labels && edited.primary_labels.some(item => item)"
          :fail-message="$t('dataPoints.no_values_extracted')"
          @hover="handleHighlight"
          @hoverout="deHighlightDP"
          @save="saveGroup"
        />
      </div>
    </div>
    <DocViewer
      v-if="$store.getters.loggedInUser.role === 'orgadmin'"
      ref="filesDocViewer"
      style="width: 59%"
      active-tab="default"
      :type="'configureGroup'"
      :document="viewFile"
      :loading="docLoading"
      :viewer-values="viewerValuesAll"
      :has-tables="!!viewFile.hasTables"
      :has-text="!!viewFile.hasText"
      :has-layout="!!viewFile.hasLayout"
      :has-entities="!!viewFile.hasEntities"
      :has-forms="!!viewFile.hasForms"
      :has-values="viewFileHasValues"
      :active-datapoint="activeDP"
      :raw-group-values="viewFile.raw_group_values || []"
      tabs
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import extractor_mixin from "@/mixins/extractor.js";
import feedback_mixin from "@/mixins/feedback.js";
import file_mixin from "@/mixins/file.js";
import model_mixin from '@/mixins/model.js';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import CreateDPGroup from '@/components/extract/views/Extractors/CreateDPGroup.vue';
import ConfigureDPGroup from '@/components/extract/views/Extractors/ConfigureDPGroup.vue';
import DocSelect from "@/components/extract/elements/Documents/DocSelect";
import DocViewer from "@/components/extract/elements/DocViewer/DocViewer.vue";
import ExtractionResult from "@/components/extract/elements/Extractors/ExtractionResult";

import { DataPointAPI } from '@/API/extract/DataPointAPI';

export default {
  name: 'LabelGroupView',

  mixins: [
    extractor_mixin,
    feedback_mixin,
    file_mixin,
    model_mixin,
  ],

  components: {
    BackLink,
    CreateDPGroup,
    ConfigureDPGroup,
    DocSelect,
    DocViewer,
    ExtractionResult,
  },

  data() {
    return {
      newGenExtractorId: -1,
    };
  },

  computed: {
    viewFileHasValues() {
      this.baseKey;
      return (
        !!this.viewFile && !!this.viewFile.group_values && !!this.viewFile.group_values[this.edited.id] && this.viewFile.group_values[this.edited.id].length > 0
      );
    },

    viewerValuesAll() {
      this.baseKey;
      if (!!this.viewFile && !!this.viewFile.group_values && !!this.viewFile.group_values[this.edited.id] && this.viewFile.group_values[this.edited.id].length > 0) {
        return this.viewFile.group_values[this.edited.id];
      }
      return [];
    },

    items: {
      cache: false,
      get: function() {
        if (this.$store.getters.labelGroups[this.$route.params.id]) {
          return this.$store.getters.labelGroups[this.$route.params.id]
        }
        return [];
      },
      set: function(newGroups) {
        this.$store.commit('setLabelGroups', {
          typeId: this.$route.params.id,
          labelGroups: newGroups
        });
      },
    },
  },

  async mounted() {
    if (this.$route.params.itemId) {
      await this.getLabelGroup(this.$route.params.itemId);
      if (this.edited.category === 'generative') {
        this.newGenExtractorId = this.edited.id;
      }
    }
  },

  methods: {
    handleHighlight(value) {
      if (this.viewerValuesAll && this.viewerValuesAll.length > 0) {
        this.$refs.filesDocViewer.currentPage = value.page_nb;
        const activeDP = value;
        const id = activeDP.data_point_id || activeDP.id;
        setTimeout(() => {
          this.handleZoomScroll(value.page_nb, id);
          this.activeDP = activeDP;
        }, 50);
      }
    },

    handleZoomScroll(page_nb, id) {
      const docViewer = this.$refs.filesDocViewer;
      if (docViewer) {
        docViewer.currentPage = page_nb;
        const highlights = docViewer.$refs.groupValueHighlights;
        if (highlights) {
          setTimeout(() => {
            let highlight = highlights.$refs[`value${id}`];
            if (!highlight) {
              return;
            }
            if (highlight) {
              highlight.scrollIntoViewIfNeeded();
            }
          }, 100);
        }
      }
    },

    async getFileGroupValues(id) {
      try {
        const response = await http.get(`files/${id}/groups/`);
        const groupValues = response.data.map((g, index) => {
          for (let i = 0; i < g.subgroups.length; i++) {
            for (let j = 0; j < g.subgroups[i].values.length; j++) {
              g.subgroups[i].values[j].group_index = index;
              g.subgroups[i].values[j].subgroup_index = i;
              g.subgroups[i].values[j].index = j;
              g.subgroups[i].values[j].group_id = g.group_id;
            }
          }
          g.panel = [0];
          return g;
        })
        return groupValues;
      } catch (error) {
        console.log(error);
        return []
      }
    },

    checkGroupValues(values) {
      const editedGroupValues = values.find(group => group.group_id === this.edited.id);
      if (editedGroupValues && editedGroupValues.subgroups.length > 0) {
        return editedGroupValues.subgroups;
      }
      return null;
    },

    async getGroupValues() {
      let values = [];
      let editedGroupValues = [];
      values = await this.getFileGroupValues(this.viewFile.id);
      editedGroupValues = this.checkGroupValues(values);
      let retries = 4;
      while (retries > 0 && this.edited.id > -1 && !editedGroupValues) {
        await this.timeout(4000);
        values = await this.getFileGroupValues(this.viewFile.id);
        editedGroupValues = this.checkGroupValues(values);
        retries--;
      }
      this.viewFile.raw_group_values = values;
      if (editedGroupValues) {
        this.viewFile.group_values = {};
        this.viewFile.group_values[this.edited.id] = editedGroupValues;
        this.viewFile = {...this.viewFile};
      }
      this.$store.commit('setLoadingScreen', false);
      this.docLoading = false;
      this.baseKey += 10;
    },

    createdGroup(group) {
      this.edited = group;
      this.newGenExtractorId = group.id;
    },

    async saveGroup() {
      try {
        if (this.edited.category === 'generative') {
          let id = this.edited.id;
          this.$store.commit('setLoadingScreen', true);
          if (this.newGenExtractorId === -1) {
            this.newGenExtractorId = await DataPointAPI.post(
              this.edited.document_type_id,
              this.edited.name,
            );
            id = this.newGenExtractorId;
          }          
          await DataPointAPI.put({
            id,
            description: this.edited.description,
            category: this.edited.category,
            labels: this.edited.labels,
          });
        } else {
          if (this.edited.primary_labels.every(item => !item)) {
            this.primaryLabelError();
          } else {
            this.$store.commit('setLoadingScreen', true);
            delete this.edited.required_precisions;
            await http.put(
              `system_2/extraction_groups/${this.edited.id}/`,
              this.edited
            );
          }
        }
        // we need to wait for the values to be extracted
        setTimeout(() => {
          this.getGroupValues();
          this.$store.commit('setLoadingScreen', false);
          this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
          this.$store.commit('setSuccessSnackbar', true);
          this.$refs.extractionResult.saved = true;
        }, 300);
      } catch (error) {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async selectFile(file) {
      this.viewFile = file;
      this.selectedFile = file.id;
      if (this.edited.id > -1) {
        await this.getGroupValues();
      }
      await this.saveInfo();
      this.viewFile = {...this.viewFile};
    },

    updatePrimary(index) {
      this.edited.primary_labels[index] = true;
      this.edited = { ...this.edited };
    },
  }
}
</script>
