<template>
  <div class="doc-navigator d-flex align-center justify-end">
    <DocStepper
      v-if="!showLoadingSkeleton"
      ref="docStepper"
      class="d-flex flex-shrink-0 mr-1"
      style="margin-top: 0px"
      :number-of-files="totalDocs"
      :ordinal-selected="ordinalSelected"
      @step="(step) => $emit('step', step)"
    />
    <v-skeleton-loader
      v-else
      type="heading"
      width="140px"
      class="d-flex flex-shrink-0 right-gap-sm"
      style="left: 50px"
    />
    <DocSelect
      v-if="!showLoadingSkeleton"
      ref="docSelect"
      class="d-flex-shrink-0"
      :type="type"
      :docs="docs"
      :selected="modelValue"
      :loading="loading"
      :deleteable="deleteable"
      :can-copy-filename="canCopyFilename"
      :show-number="showNumber"
      :show-icon="false"
      @selected-changed="id => $emit('update:modelValue', id)"
      @delete-click="$emit('deleteClick')"
      @get-more="$emit('getMore')"
      @get-missing="$emit('getMissing')"
      small
    />
    <v-skeleton-loader
      v-else
      type="heading"
      width="310px"
      class="d-flex flex-shrink-0 right-gap-sm"
      style="left: 20px"
    />
  </div>
</template>

<script>
import DocSelect from "@/components/extract/elements/Documents/DocSelect";
import DocStepper from "@/components/extract/elements/Documents/DocStepper";

export default {
  name: 'DocNavigator',

  components: {
    DocSelect,
    DocStepper,
  },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },

    docs: {
      type: Array,
      required: true,
    },

    totalDocs: {
      type: Number,
      required: true,
    },

    ordinalSelected: {
      type: Number,
      required: true,
    },

    type: {
      type: String,
      default: 'file',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    deleteable: {
      type: Boolean,
      default: false,
    },

    canCopyFilename: {
      type: Boolean,
      default: false,
    },

    showNumber: {
      type: Boolean,
      default: true,
    },

    showLoadingSkeleton: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'step',
    'deleteClick',
    'getMore',
    'getMissing',
    'update:modelValue',
  ],
}
</script>
