<template>
  <v-dialog
    v-model="show"
    max-width="800"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
  >
    <v-card class="dialog-card">
      <v-card-title class="d-flex justify-space-between align-center">
        <h2 class="dialog-title">
          {{ $t(`search.tasks.${task.type}`) }}
        </h2>
        <v-chip :color="$options.constants.STATUS_COLOR_MAP[task.state]">
          {{ $t(`search.tasks.${task.state}`) }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <TwoColumnDetails
          class="bottom-gap"
          :left="{
            title: $t('search.tasks.created_on'),
            value: formatDate(task.creation_time),
          }"
          :right="{
            title: $t('search.tasks.ended_on'),
            value: formatDate(task.end_time),
          }"
        />
        <TwoColumnDetails
          class="bottom-gap"
          :left="{
            title: $t('search.tasks.creation_time'),
            value: formatElapsedTime(task.seconds_elapsed_since_creation),
          }"
          :right="{
            title: $t('search.tasks.execution_time'),
            value: formatElapsedTime(task.execution_time_in_seconds),
          }"
        />
        <hr
          v-if="task.type === 'sync'"
          class="bottom-gap-sm"
        >
        <div
          v-if="task.type === 'sync'"
          class="w-100 mb-3"
        >
          <small>
            {{ $t('search.tasks.changed_files') }}
          </small>
          <p
            v-if="task.added && task.added > 0"
            class="text-success-darken"
          >
            {{ $t('search.tasks.files_added', { number: task.added }) }}
          </p>
          <p
            v-else
            class="text-grey font-italic"
          >
            {{ $t('search.tasks.no_files_updated') }}
          </p>
        </div>
        <div
          v-if="task.type === 'sync'"
          class="w-100"
        >
          <small>
            {{ $t('search.tasks.failed_files') }}
          </small>
          <p
            v-if="!task.errors"
            class="text-grey font-italic mb-1"
          >
            {{ $t('search.tasks.no_failed_files') }}
          </p>
          <div
            v-if="task.error_files.length > 0"
            class="failed-file-container ellipsis text-body-2"
          >
            <div
              v-for="file in task.error_files"
              :key="file"
              style="margin-bottom: 5px;"
            >
              <v-icon
                class="inline-middle mb-2 mr-1"
                size="17"
              >
                mdi mdi-file-document-alert-outline
              </v-icon>
              <ItemName
                class="noselect inline-middle"
                style="max-width: 97%"
                :max-width="650"
                :item="{ name: file }"
                :clickable="false"
                :show-id="false"
                :fade-in-slow="false"
                @click="handleClick"
              />
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-end">
        <v-btn
          color="primary"
          variant="flat"
          @click="$emit('close')"
          rounded
        >
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ItemName from '@/components/common/elements/General/ItemName';
import TwoColumnDetails from '@/components/search/elements/Tasks/TwoColumnDetails';
import { formatElapsedTime, formatDate } from '@/utils/classes/Formatting';

export default {
  name: 'SearchTaskDetailsDialog',

  components: {
    ItemName,
    TwoColumnDetails,
  },

  data() {
    return {
      show: this.modelValue,
    };
  },

  watch: {
    modelValue(value) {
      this.show = value;
    },

    show(newShow) {
      this.$emit('update:modelValue', newShow);
    },
  },

  methods: {
    formatDate,
    formatElapsedTime,
  },  

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    task: {
      type: Object,
      required: true,
    },
  },

  constants: {
    STATUS_COLOR_MAP: {
      PENDING: 'grey',
      SUCCESS: 'success-darken',
      FAILURE: 'error',
    },
  },

  emits: ['close', 'update:modelValue'],
}
</script>
<style lang="scss" scoped>
.failed-file-container {
  overflow-y: auto;
  padding: 5px 10px 0 10px;
  background-color: #efefef;
  border: 1px solid #999;
  border-radius: 4px;

  max-height: 135px;
}
</style>
