import { DataPointAPI } from '@/API/extract/DataPointAPI';

export async function copyGenerative(extractor, name, agentId) {
  let labels = null;
  if (extractor.labels) {
    // Compatibility with Extraction Group labels
    labels = extractor.labels.map((label, index) => {
      if (extractor.value_types) {
        return {
          label,
          value_type: extractor.value_types[index],
          description: '',
        };
      }
      return label;
    });
  }
  const generative = {
    name,
    document_type_id: agentId,
    category: 'generative',
    description: extractor.description,
    labels,
  };
  const newId = await DataPointAPI.post(agentId, generative.name);
  await DataPointAPI.put({ ...generative, id: newId });
  return newId;
}
