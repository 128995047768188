import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1/emails_datasets';

export class EmailsDatasetsAPI {
  static fetchAll(
    name=null,
    offset=0,
    limit=20,
    status=null,
    descendingSort=true) {
    return http
      .get(`${BASE_PATH}`, {
        params: {
          name,
          offset,
          limit,
          status,
          descending_sort: descendingSort,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static get(id) {
    return http
      .get(`${BASE_PATH}/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static create(dataset) {
    return http
      .post(`${BASE_PATH}`, {
        ...dataset,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static update(datasetId, dataset) {
    return http
      .put(`${BASE_PATH}/${datasetId}/`, {
        ...dataset,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static uploadEmailFile(datasetId, files) {
    const formData = new FormData();
    files.forEach(file => { formData.append("files", file); });
    const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
    return http
      .post(`${BASE_PATH}/${datasetId}/emails-files`, formData, headerConfig)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static fetchEmails(datasetId, offset=0, limit=20, descendingSort=true, subject=undefined) {
    return http
      .get(`${BASE_PATH}/${datasetId}/emails-files`, {
        params: {
          offset,
          limit,
          descending_sort: descendingSort,
          subject,
        }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
