<template>
  <div>
    <div 
      v-for="(token, i) in tokens"
      :key="i"
      :isRendered="isRendered(i)" 
    >
      <div
        v-if="!isAnnotated(token)"
        class="highlight-frame"
        :style="{
          'border-color': 'gray',
          left: `${getHighlightX(token.coordinates.x_min)}vh`,
          top: `${getHighlightY(token.coordinates.y_min)}vh`,
          width: `${getHighlightX(
            token.coordinates.x_max - token.coordinates.x_min
          )}vh`,
          height: `${getHighlightY(
            token.coordinates.y_max - token.coordinates.y_min
          )}vh`,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ReadingOrder",

  watch: {
    pageWidth() {
      this.handleZoom();
    },

    tokens() {
      this.$nextTick(() => {
        this.handleZoom();
      });
    }
  },

  methods: {
    isAnnotated(token) {
      return this.annotationCoordinates.some(
        (annotation) =>
          annotation.x_min <= token.coordinates.x_min &&
          annotation.x_max >= token.coordinates.x_max &&
          annotation.y_min <= token.coordinates.y_min &&
          annotation.y_max >= token.coordinates.y_max
      );
    },

    getHighlightX(x) {
      return x * this.pageWidth;
    },

    getHighlightY(y) {
      return y * this.pageHeight;
    },

    drawRelations() {
      let svg = this.createPageCanvas();      
      this.tokens.reduce((pairs, v, i, arr) => {
        if (i < arr.length - 1) {
          pairs.push([arr[i], arr[i + 1]]);
        }
        return pairs;
      }, []).forEach(pair => this.drawRelationArrow(svg, pair[0], pair[1]));
      this.$emit('drawSvg', svg);
    },

    createPageCanvas(){
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg'); 

      let defs = svg.querySelector("defs");
      if(defs == null){
          defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
          svg.appendChild(defs);
      }
          
      const marker_left_right = document.createElementNS('http://www.w3.org/2000/svg', 'marker');
      marker_left_right.setAttribute("id", "arrowheadLR");
      marker_left_right.setAttribute("markerWidth", "5");
      marker_left_right.setAttribute("markerHeight", "3.5");
      marker_left_right.setAttribute("refX", "2.5");
      marker_left_right.setAttribute("refY", "1.75");
      marker_left_right.setAttribute("orient", "auto");
      defs.appendChild(marker_left_right);
      
      const polygon_left_right = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
      polygon_left_right.setAttribute("points", "0 0, 5 1.75, 0 3.5");
      polygon_left_right.setAttribute("fill", "blue");
      marker_left_right.appendChild(polygon_left_right);

      const marker_right_left = document.createElementNS('http://www.w3.org/2000/svg', 'marker');
      marker_right_left.setAttribute("id", "arrowheadRL");
      marker_right_left.setAttribute("markerWidth", "5");
      marker_right_left.setAttribute("markerHeight", "3.5");
      marker_right_left.setAttribute("refX", "2.5");
      marker_right_left.setAttribute("refY", "1.75");
      marker_right_left.setAttribute("orient", "auto");
      defs.appendChild(marker_right_left);
      
      const polygon_right_left = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
      polygon_right_left.setAttribute("points", "0 0, 5 1.75, 0 3.5");
      polygon_right_left.setAttribute("fill", "red");
      marker_right_left.appendChild(polygon_right_left);

      svg.setAttribute("width", "100%");
      svg.setAttribute("height", "100%");
      svg.setAttribute("viewBox", "0 0 1 1");
      svg.setAttribute("preserveAspectRatio", "none");
      
      svg.style.position='absolute';
      svg.style.left=0;
      svg.style.top=0;
      
      // this.$emit('drawSvg', svg);
      return svg; 
    },

    drawRelationArrow(svg, token1, token2){

      const x_mid_r1 = (token1.coordinates.x_min + token1.coordinates.x_max)/2;
      const y_3_over_4_r1 = token1.coordinates.y_min + (token1.coordinates.y_max - token1.coordinates.y_min)*3/4;
  
      const x_mid_r2 = (token2.coordinates.x_min + token2.coordinates.x_max)/2;
      const y_1_over_4_r2 = token2.coordinates.y_min + (token2.coordinates.y_max - token2.coordinates.y_min)*1/4;
  
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');

      line.setAttribute("x1", x_mid_r1);
      line.setAttribute("y1", y_3_over_4_r1);
      line.setAttribute("x2", x_mid_r2);
      line.setAttribute("y2", y_1_over_4_r2);

      if (x_mid_r1 < x_mid_r2){
        line.setAttribute("stroke", "blue");
        line.setAttribute("stroke-width", "0.002");
        line.setAttribute("marker-end", "url(#arrowheadLR)");
      }
      else{
        line.setAttribute("stroke", "red");
        line.setAttribute("stroke-width", "0.002");
        line.setAttribute("marker-end", "url(#arrowheadRL)");
      }

      svg.appendChild(line); 
    },

    handleZoom(){
      this.$emit('eraseSvg');
      this.drawRelations();
    },

    isRendered(key) {
      let total_items = this.tokens.length - 1;
      if (key === total_items) {
        this.drawRelations();
      }
    }
  },

  props: {
    tokens: {
      type: Array,
      required: true,
    },
    annotationCoordinates: {
      type: Array,
      required: true,
    },
    pageWidth: {
      type: Number,
      required: true,
    },
    pageHeight: {
      type: Number,
      required: true,
    },
  },

  emits: ['drawSvg', 'eraseSvg'],
};

</script>

<style lang="scss" scoped>
.highlight-frame {
  position: absolute;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  opacity: 0.4;
}
</style>