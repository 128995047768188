import _ from 'lodash';

function getDataPointInfo(id, values) {
  const dp = values.find(dp => dp.data_point_id === id);
  return dp && [dp.value.normalized_value, dp.data_point_name] || null;
}

function getExtractionGroupValueSum(groupId, label, groups) {
  const group = groups.find(g => g.group_id === groupId);
  if (!group) {
    return 0;
  }
  const res = group.subgroups.reduce((acc, sg) => {
    return acc + getExtractionGroupValue(parseInt(groupId), label, sg.id) || 0;
  }, 0);
  return res;
}

function getExtractionGroupValue(groupId, label, subgroupId, groups) {
  const group = groups.find(g => g.group_id === groupId);
      if (!group) {
        return null;
      }
      const subgroup = group.subgroups.find(sg => sg.id === subgroupId)
      if (!subgroup) {
        return null;
      }
      const value = subgroup.values.find(v => v.label === label);
      return value && value.normalized_value || null;
}

function getValueBusinessRules(rules, id, extractorType, ruleResults, values, subgroupId = null) {
  if (_.isEmpty(ruleResults)) {
    return [];
  }
  return rules.map(br => {
    const extractorRegex = new RegExp(`${extractorType}_${id}`);
    const relevantCodes = br.extractor_codes.filter((c) => extractorRegex.test(c));
    if (relevantCodes.length > 0) {
      let res = br.string;

      br.extractor_codes.forEach(code => {
        let [extType, extractorId, ...label] = code.split('_');
        label = label.join('_');
        if (extType === 'dp') {
          const [value, name] = getDataPointInfo(parseInt(extractorId, 10), values);
          res = res.replaceAll(`${code}_name`, name);
          res = res.replaceAll(code, value || 'N/A');
        } else {
          if (br.type === 'internal') {
            const subgroupValueSum = getExtractionGroupValueSum(parseInt(extractorId, 10), label, values);
            res = res.replaceAll(code, subgroupValueSum);
            res = res.replaceAll(`${label}`, `SUM(${label})`);
          } else if (br.type === 'subgroup'){
            if (!subgroupId) {
              throw new Error('Subgroup ID is required for subgroup business rules');
            }
            const value = getExtractionGroupValue(parseInt(extractorId, 10), label, subgroupId, values);
            res = res.replaceAll(code, value || 'N/A');
          }
        }
      });
      return {
        string: res,
        isValid: br.type === 'internal' && ruleResults[br.name] || ruleResults[br.name][subgroupId],
      };
    }
    return null;
  })
}

export function getDataPointBRStrings(businessRules, values, ruleResults) {
  if (businessRules.length === 0) {
    return {};
  }
  const res = values.reduce((acc, dp) => {
    if (
      dp.value.prevalidated
      && businessRules.some(br => br.extractor_codes.includes(`dp_${dp.data_point_id}`))
    ) {
      const businessRulesStrings = getValueBusinessRules(
        businessRules,
        dp.data_point_id,
        'dp',
        ruleResults,
        values,
      );
      return {
        ...acc,
        [dp.data_point_id]: businessRulesStrings,
      };
    }
    return acc;
  }, {});
  return res;
}

export function getGroupValueBRStrings(businessRules, groups, ruleResults) {
  let res = {};
  groups.forEach((group) => {
    group.subgroups.forEach(subgroup => {
      subgroup.values.forEach(value => {
        const extractionGroupCode = `eg_${group.group_id}_${value.label}`;
        if (
          value.prevalidated
          && businessRules.some(br => br.extractor_codes.includes(extractionGroupCode))
        ) {
          const businessRulesStrings = getValueBusinessRules(
            businessRules,
            group.group_id,
            'eg',
            ruleResults,
            groups,
            subgroup.id
          );
          res = {
            ...res,
            [`${extractionGroupCode}_${subgroup.id}`]: businessRulesStrings,
          };
        }
      });
    });
  });
  return res;
}
