<template>
  <div class="location-labels">
    <div
      v-if="locationLabels.length === 0"
      style="height: 10px"
    />
    <v-row
      v-for="(label, i) in locationLabels"
      :key="i"
      class="label-container bottom-gap-sm mt-0"
      @mouseover="hoveredLabel = label.id"
    >
      <v-icon
        style="position: absolute; right: 7px; top: 7px;"
        color="black"
        size="14"
        @click="$emit('deleteLocationLabel', label.id)"
      >
        fas fa-times
      </v-icon>
      <v-col
        v-if="editedLabel !== label.id"
        style="padding-top: 5px"
      >
        <div
          class="ellipsis inline-middle"
          style="max-width: 210px"
        >
          {{ label.name }}
        </div>
        <v-tooltip
          v-if="hoveredLabel === label.id"
          bottom
        >
          <template #activator="{ props }">
            <v-icon
              class="clickable left-gap-sm inline-middle"
              size="12"
              v-bind="props"
              @mouseover="hoveredLabel = label.id"
              @click="startNameEdit(label.id, label.name)"
            >
              fas fa-pen
            </v-icon>
          </template>
          {{ $t("models.edit_name") }}
        </v-tooltip>
      </v-col>
      <v-col v-else>
        <div style="position: relative; width: fit-content; top: 4px;">
          <input
            ref="labelNameField"
            v-model="newNameForLabel"
            type="text"
            class="label-name-field"
            @keydown.enter="saveLabelName"
            @keydown.esc="editedLabel = -1"
          >
          <v-icon
            class="clickable edit-name-icon"
            size="12"
            @click="saveLabelName"
          >
            fas fa-check
          </v-icon>
        </div>
      </v-col>
      <v-col class="ellipsis d-flex align-center" />
    </v-row>
    <div class="mt-8 d-flex">
      <v-text-field
        v-model="newLabel"
        class="inline-middle right-gap label-field"
        variant="outlined"
        color="primary"
        density="compact"
        :placeholder="$t('models.type_label_name')"
        @keydown.enter="addLabel"
      />
      <v-btn
        class="add-button"
        style="height: 37px"
        color="primary"
        :disabled="trimmedLabel === ''"
        @click="addLabel"
        rounded
      >
        <v-icon
          size="16"
          start
        >
          fas fa-plus
        </v-icon>
        {{ $t("docTypes.add") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { http } from "@/plugins/axios";

import annotation_mixin from '@/mixins/annotation';

export default {
  name: 'LocationLabelsConfig',

  mixins: [
    annotation_mixin,
  ],

  data() {
    return {
      editedLabel: -1,
      editOldName: '',
      hoveredLabel: -1,
      locationLabels: [],
      newLabel: '',
      newNameForLabel: '',
    };
  },

  computed: {
    files: {
      get() {
        return this.$store.getters.entries[this.datasetId] || [];
      },
      set(files) {
        this.$store.commit(
          'setEntries',
          {
            modelId: this.datasetId,
            entries: files,
          }
        );
      }
    },

    trimmedLabel() {
      return this.newLabel.trim();
    },

    trimmedNewNameForLabel() {
      return this.newNameForLabel.trim();
    },
  },

  async mounted() {
    await this.getLocationLabels();
  },

  methods: {
    startNameEdit(id, name) {
      this.editedLabel = id;
      this.editOldName = name;
      this.newNameForLabel = name;
      setTimeout(() => {
        this.$refs.labelNameField[0].focus();
      }, 100);
    },

    async saveLabelName() {
      if (this.trimmedNewNameForLabel !== '') {
        try {
          const oldName = this.editOldName;
          const newName = this.trimmedNewNameForLabel.toUpperCase();
          await http.put(
            `dataset/label/${this.editedLabel}/`,
            { name: newName },
            {
              params: {
                type: 'location',
              },
            },
          );
          this.editedLabel = -1;
          this.newNameForLabel = '';
          this.files.forEach((file) => {
            if (!file.annotations) {
              file.annotations = {};
            }
            if (file.annotations[oldName]) {
              file.annotations[newName] = file.annotations[oldName];
              if (newName !== oldName) {
                delete file.annotations[oldName];
              }
            }
          });
        } catch (error) {
          // pass
        }
        await this.getLocationLabels();
      }
    },

    async addLabel() {
      if (this.trimmedLabel !== "") {
        try {
          await http.post(`dataset/label/${this.datasetId}/`,
          {
            name: this.trimmedLabel,
          },
          {
            params: {
              type: 'location'
            }
          });
          this.newLabel = "";
        } catch (error) {
          // pass
        }
        await this.getLocationLabels();
      }
    },
  },

  props: {
    datasetId: {
      type: Number,
      required: true,
    },
  },

  emits: ['deleteLocationLabel'],
}
</script>

<style lang="scss" scoped>
.label-row {
  margin: 0 !important;
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  &:last-of-type {
    border-bottom: 0px solid #eee;
  }
}

.label-field {
  width: 230px;
}

.add-button {
  top: 3px;
  box-shadow: none !important;
}

.location-labels {

  .label-container {
    background-color: white;
    width: 740px;
    font-size: 0.7rem;
    padding: 10px;
    position: relative;
    height: 50px;
    margin-left: 0px !important;
    color: #4c4c4c;
    border: #c8c8c8 2px solid;
    overflow: hidden;

    .icon-bg {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background-color: white;
      width: 55px;
    }

    .label-buttons {
      position: absolute;
      right: 20px;
      top: 0px;
      bottom: 0px;
    }

    .close-icon {
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .edit-name-icon {
      position: absolute;
      right: 0px;
      top: 1px;
    }

    .edit-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: rgb(var(--v-theme-primary)) 1px solid;
      text-align: center;

      .edit-icon-icon {
        margin-top: -3px;
      }
    }

    .label-name-field {
      display: inline-block;
      text-transform: uppercase;
      background-color: #ffffff00;
      padding: 0 !important;
      border: none;
      width: 180px;
      border-bottom: 1px solid rgb(var(--v-theme-primary));

      &:focus {
        outline: none;
        border-bottom: 1px solid rgb(var(--v-theme-primary));
      }

      &:focus-visible {
        outline: none;
        border-bottom: 1px solid rgb(var(--v-theme-primary));
      }
    }
  }
}
</style>