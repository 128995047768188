<template>
  <div class="training-models-table">
    <TableWithFooter
      no-results-message="models.no_training"
      :loading="loading"
      :paginated-items-length="paginatedVersions.length"
      :total="totalTrainingModelsDisplay"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="6">
          {{ $t('models.in_training') }}
        </v-col>
        <v-col>
          {{ $t('models.version') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedVersions"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col>
              <div class="ellipsis">
                {{ item.name }}
              </div>
            </v-col>
            <v-col cols="3">
              {{ item.version }}
            </v-col>
            <v-col cols="3">
              <v-btn
                color="primary"
                class="small-button"
                style="margin-top: -6px"
                @click="handleDeleteButton(item.id, item.version)"
                rounded
              >
                <v-icon
                  size="16"
                  start
                >
                  fas fa-times
                </v-icon>
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('models.delete_title_training')"
      :message="$t('models.confirm_delete_training')"
      @confirm="deleteVersion"
      @close="deleteDialog = false; modelToDelete = -1; versionToDelete = -1"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import { ModelAPI } from '@/API/extract/ModelAPI';
import model_mixin from '@/mixins/model.js';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';

export default {
  name: 'TrainingModelsTable',

  mixins: [
    model_mixin,
  ],

  components: {
    TableWithFooter,
    DeleteDialog,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      deleteDialog: false,
      modelToDelete: -1,
      versionToDelete: -1,
      loading: false,
      statusCheck: null,
      paginatedVersions: [],
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalTrainingModelsDisplay / this.itemsPerPage);
    },
  },

  watch: {
    deleteDialog(on) {
      if (on) {
        clearInterval(this.statusCheck);
      } else {
        this.modelsCountCheck();
      }
    },

    itemsPerPage() {
      this.currentPage = 1;
      this.modelsCountCheck();
    },

    currentPage() {
      this.modelsCountCheck();
    },
  },

  mounted() {
    this.modelsCountCheck();
  },

  unmounted() {
    clearInterval(this.statusCheck);
  },

  methods: {
    refreshModels() {
      // Changing this setup might result in models not loading after training
      setTimeout(() => {
        this.modelsCountCheck();
      }, 10);
      this.modelsCountCheck();
    },

    modelsCountCheck() {
      this.getModelsCount('active');
      this.getPendingModels();
      clearInterval(this.statusCheck);
      this.statusCheck = setInterval(() => {
        if (this.totalTrainingModelsDisplay === 0) {
          clearInterval(this.statusCheck);
        } else {
          this.getModelsCount('active');
          this.getPendingModels(
            (this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage, false
          );
        }
      }, 10000);
    },

    async getModelsCount(status) {
      try {
        let includeError = true;
        if (status === 'pending') {
          includeError = false;
        }
        const response = await ModelAPI.get(
          0, 1, null, status, null, includeError,
        );
        this[`total${this.statusMapping[status]}ModelsDisplay`] = parseInt(
          response.headers['x-total-count'], 10
        );
      } catch (error) {
        clearInterval(this.statusCheck);
      } finally {
        this.loading = false;
      }
    },

    async getPendingModels(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage,
      loading = true,
    ) {
      if (loading) {
        this.loading = true;
      }
      try {
        const response = await ModelAPI.get(
          offset, limit, this.trimmedFilter || '', 'pending', true, false,
        );
        const modelVersions = [];
        response.data.forEach((model) => {
          model.versions.forEach((version) => {
            modelVersions.push({
              id: model.id,
              name: model.name,
              version: version.version,
            });
          });
        });
        this.paginatedVersions = modelVersions;
        this.totalTrainingModelsDisplay = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        clearInterval(this.statusCheck);
      } finally {
        this.loading = false;
      }
    },

    async deleteVersion() {
      try {
        this.$store.commit('setLoadingScreen', true);
        await http.delete(`model/${this.modelToDelete}/version/${this.versionToDelete}`);
        this.finishDeletion();
      } catch (error) {
        // pass
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    async finishDeletion() {
      this.deleteDialog = false;
      this.totalTrainingModelsDisplay--;
      await this.getPendingModels();
      this.currentPage = 1;
      this.modelToDelete = -1;
      this.versionToDelete = -1;
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleDeleteButton(id, version) {
      this.deleteDialog = true;
      this.modelToDelete = id;
      this.versionToDelete = version;
    },
  }
}
</script>
<style lang="scss" scoped>

.top-gap-0 {
  margin-top: 0;
}

</style>
